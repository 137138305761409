import { brandTheme } from "../../../theme";
import { Button, Icon, Input, Spinner, Text } from "../../atoms";
import { Link, useSearchParams } from "react-router-dom";
import { AuthTemplate } from "../../templates/AuthTemplate";
import { useState } from "react";
import { OTPScreen } from "./OTPScreen";
import axios from "../../../api/axios";
import { toSentenceCase } from "../../../utils";
import { africredColors } from "../../../theme/colors";

export const Signup = () => {
  const [error, setError] = useState("");
  let [searchParams] = useSearchParams();
  let accountType =
    searchParams.get("accountType")?.toLowerCase() === "agent"
      ? "agent"
      : "student";

  const [otpSent, setOTPSent] = useState(false);
  const [email, setEmail] = useState("");
  const [id, setID] = useState("");
  const [loading, setLoading] = useState(false);

  async function sendOtp() {
    if (!email) {
      alert("You can't send without an email");
      return false;
    }
    if (loading) return;

    try {
      setLoading(true)
      const response = await axios.post(`/account/send-otp/${email}`);

      if (response.data) {
        setOTPSent((prev) => true);
        setID((prev) => response.data.id);
      }
    } catch (err) {
      if (err?.response?.status === 400) {
        setError(
          err?.response?.data?.message
            ? err.response.data.message
            : "Invalid email or email already exists."
        );
      }
    }
    setLoading(false)
  }

  return (
    <>
      {!otpSent && !id ? (
        <AuthTemplate
          title={`${
            accountType !== "agent"
              ? "Sign up"
              : `${toSentenceCase(accountType)} sign up`
          }`}
          // subtitle="Quickly sign in with your Gmail or Apple ID"
          subtitle="Sign in with your email"
        >
          {error ? (
            <Text type="p" color={africredColors.secondary.red[600]}>
              {error}
            </Text>
          ) : null}
          <Input
            icon={<Icon type="email" />}
            type="text"
            size="md"
            placeholder="email"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            onClick={sendOtp}
            width="100%"
            size="md"
            bgcolor={brandTheme.brand.secondary}
            style={{ color: "white" }}
          >
            {loading? <Spinner />: 'Sign up'}
          </Button>

          <div className="flex justify-center w-full">
            <Link to="/login">
              <Text type="small">Already have an account? Login</Text>
            </Link>
          </div>
        </AuthTemplate>
      ) : (
        <OTPScreen
          id={id}
          email={email}
          accountType={toSentenceCase(accountType)}
        />
      )}
    </>
  );
};
