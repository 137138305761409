import React, { useState } from "react";
import { CreatePassword } from "./CreatePassword";
import { OTPComponent } from "../../organisms/OTPComponent";

export const OTPScreen = ({ id, email, accountType }) => {
  const [otpSuccess, setOTPSuccess] = useState(false);

  return (
    <>
      {!otpSuccess ? (
        <OTPComponent
          id={id}
          setOTPSuccess={setOTPSuccess}
          useCase="signup"
          email={email}
        />
      ) : (
        <CreatePassword email={email} accountType={accountType} />
      )}
    </>
  );
};
