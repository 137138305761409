import styled, { css } from 'styled-components'
import { africredColors } from '../../../theme/colors'
import { Icon } from '../../atoms/Icon'
import { Text } from '../../atoms/Text'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { motion } from 'framer-motion'
import { pageAnimationConfig } from '../../../utils'
import profileImage from '../../../assets/pngs/dp.png'
import { Table } from '../../molecules'
import { useState } from 'react'
import { screen } from '../../../theme'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useQuery } from '@tanstack/react-query'
import { SelectInput } from '../../atoms'
import { format } from 'date-fns'
import { NoRecordFound } from '../../molecules/NoRecordFound'
import { Loader } from '../../atoms/Loader'
import { Skeleton } from 'antd'
import LoaderContainer from '../../atoms/Loader/LoaderContainer';

const Wrapper = styled(motion.div)`
    display: flex;
    gap: 24px;
    flex-direction: column;
`

const TableWrapper = styled.div`
    width: 100%;
    overflow: scroll;
    max-height: calc(75vh - 94px);
    color: black;
`

const Flex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
`

function renderHeader(params) {
    return (
        <Text type="p" weight="medium">
            {params.colDef.headerName}
        </Text>
    )
}

const highlightStyles = css`
    display: flex;
    height: 112px;
    padding: 36px 52px;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: ${africredColors.secondary.purple[800]};
    color: white;
    overflow-x: auto;
    overflow-y: hidden;
    grid-row: 1/2;
    grid-column: 1/6;

    > div {
        padding: 0px 24px;
        display: flex;
        gap: 28px;
        align-items: center;
    }

    p {
        text-wrap: nowrap;
    }
`

const Highlight = styled.section`
    ${highlightStyles};
    display: none;

    @media only screen and (${screen.lg}) {
        height: 178px;
        border-radius: 32px;
        padding: 36px 35px;
        display: flex;
    }
`
const MobileHighlightWrapper = styled.div`
    ${highlightStyles};

    > div {
        padding: 0px 16px;
        display: flex;
        gap: 16px;
        align-items: center;
    }

    display: flex;
    justify-content: space-between;
    overflow: hidden;
    height: 112px;
    border-radius: 16px;
    padding: 36px 1rem;

    @media only screen and (${screen.lg}) {
        display: none;
    }
`

const RecentTransaction = styled.section`
    padding: 36px 35px 16px 35px;
    align-items: center;
    border-radius: 32px;
    color: black;
    background: ${africredColors.neutrals.white[50]};

    > div:first-child {
        border-bottom: 1px solid ${africredColors.neutrals.night[200]};
    }

    .MuiDataGrid-withBorderColor {
        border-bottom: none !important;
    }
`

const ApplicationStatusArea = styled.section`
    grid-area: quickAction;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    overflow-y: auto;
    width: 100%;

    > div:not(:last-child) {
        padding-bottom: 12px;
        border-bottom: 1px solid ${africredColors.neutrals.night[100]};
    }

    @media only screen and (${screen.md}) {
        width: 40%;
    }

    @media only screen and (${screen.lg}) {
        width: 20%;
    }
`

const ActivityChart = styled.section`
    width: 100%;
    display: flex;
    padding: 23px 20px;
    flex-direction: column;
    align-items: space-between;
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    height: 100%;

    @media only screen and (${screen.md}) {
        width: 60%;
    }

    @media only screen and (${screen.lg}) {
        width: 80%;
    }
`

const ChartWrapper = styled.div`
    height: 100%;
    width: 100%;
`

const Details = styled.div`
    display: flex;
    flex-grow: 2;
    gap: 16px;
    height: auto;
    flex-direction: column;

    @media only screen and (${screen.md}) {
        height: 45vh;
        display: flex;
        flex-direction: row;
    }
`

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
)

const HightlightNavButton = styled.button`
    cursor: pointer;
    border-radius: 1rem;

    &:last-child {
        border: 1px solid #af62ea;
        padding: 12px;
        background: #af62ea;
    }
`

const MobileHighlight = ({ highlightData }) => {
    const [currentDataIndex, setCurrentDataIndex] = useState(0)

    return (
        <MobileHighlightWrapper>
            <div>
                <div>{highlightData[currentDataIndex].icon}</div>
                <div>
                    <Text type="h3">
                        {highlightData[currentDataIndex].amount}
                    </Text>
                    <Text type="p">{highlightData[currentDataIndex].text}</Text>
                </div>
            </div>

            <div className="flex gap-2">
                <HightlightNavButton
                    onClick={() => {
                        setCurrentDataIndex(prev => {
                            if (highlightData[prev - 1]) {
                                return prev - 1
                            }
                            return prev
                        })
                    }}
                >
                    <Icon type="back" fill="white" />
                </HightlightNavButton>
                <HightlightNavButton
                    onClick={() => {
                        setCurrentDataIndex(prev => {
                            if (highlightData[prev + 1]) {
                                return prev + 1
                            }
                            return prev
                        })
                    }}
                >
                    <Icon type="next" fill="white" />
                </HightlightNavButton>
            </div>
        </MobileHighlightWrapper>
    )
}

export const AgentDashboard = () => {
    const [summaryFilterOption, setSummaryFilterOption] = useState('Remittance')
    const [statsFilter, setStatsFilter] = useState('Remittance')
    // const [chartFilterOption, setChartFilterOption] = useState("Remittance");
    const axiosPrivate = useAxiosPrivate()
    const {
        isPending,
        error,
        data: dashboardData,
    } = useQuery({
        queryKey: ['agent-dashboard-data'],
        queryFn: async () => {
            const response = await axiosPrivate('/transaction/agent-dashboard')
            return response.data
        },
    })

    const { isPending: statLoading, data: stats } = useQuery({
        queryKey: ['fetch-stats', statsFilter],
        queryFn: async () => {
            const response = await axiosPrivate(
                `/transaction/stats?transactionType=${
                    statsFilter === 'Short term loans' ? 'Pof' : statsFilter
                }&range=Last 6 months`,
            )
            return response.data
        },
    })

    const chartStep = dashboardData?.graphPoints?.totalAmounts?.reduce(
        (partialSum, a) => partialSum + a,
        0,
    )

    if (isPending || !dashboardData.transactionStats)
        return <LoaderContainer />

    if (error) return 'An error has occurred: ' + error.message

    const columns = [
        {
            field: 'referenceNumber',
            headerName: 'Transaction Id',
            minWidth: 150,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'issuedBy',
            headerName: 'Issued by',
            minWidth: 200,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Flex>
                        <img
                            width="35px"
                            src={profileImage}
                            alt={`profile${value}`}
                        />
                        <Text type="p">
                            {value?.substring(0, 25)}{' '}
                            {value.length > 25 ? '...' : ''}
                        </Text>
                    </Flex>
                )
            },
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return <Text type="p">{value}</Text>
            },
        },
        {
            field: 'currency',
            headerName: 'Currency',
            renderHeader,
        },
        {
            field: 'purpose',
            headerName: 'Purpose',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'date',
            headerName: 'Date',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'status',
            headerName: 'Status',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            minWidth: 130,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Text type="p" color={africredColors.primary.gold[700]}>
                        {value}
                    </Text>
                )
            },
        },
    ]

    const drafts =
        dashboardData?.drafts?.[
            summaryFilterOption === 'Remittance'
                ? 'remittancesDrafts'
                : summaryFilterOption === 'Loan'
                ? 'loanDrafts'
                : 'pofDrafts'
        ]

    const highlightData = [
        {
            icon: <Icon type="transaction" stroke="white" />,
            amount: dashboardData.transactionStats?.totalTransactions,
            text: 'Total transactions',
        },
        {
            icon: <Icon type="card" stroke="white" />,
            amount: dashboardData.transactionStats?.totalRemittances,
            text: 'Total Remitted',
        },
        {
            icon: <Icon type="wallet" stroke="white" />,
            amount: dashboardData.transactionStats?.totalLoans,
            text: 'Total Loans',
        },
        {
            icon: <Icon type="proof-of-funds" stroke="white" />,
            amount: dashboardData.transactionStats?.totalPofs,
            text: 'Total short term loans',
        },
    ]

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
        },
        scales: {
            y: {
                ticks: {
                    stepSize: Math.round(chartStep / 3),
                },
            },
        },
        tension: 0.4,
    }

    const data = {
        labels: stats?.graphPoints?.monthNames,
        datasets: [
            {
                label: 'Dataset 1',
                data: stats?.graphPoints?.totalAmounts,
                borderColor: africredColors.primary.gold[500],
                backgroundcolor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    }

    return (
        <Wrapper {...pageAnimationConfig}>
            <Highlight>
                {highlightData.map(item => (
                    <div>
                        <div>{item.icon}</div>
                        <div>
                            <Text type="h3">{item.amount}</Text>
                            <Text type="p">{item.text}</Text>
                        </div>
                    </div>
                ))}
            </Highlight>

            <MobileHighlight highlightData={highlightData} />

            <RecentTransaction>
                <div className="flex w-full items-center justify-between pb-4">
                    <div>
                        <Text type="h4">Draft</Text>
                    </div>
                    <div className="flex items-center gap-4">
                        <SelectInput
                            width="fit-content"
                            options={['Remittance', 'Loan', 'Short term loans']}
                            value={summaryFilterOption}
                            setValue={item => setSummaryFilterOption(item)}
                            size="sm"
                            bgcolor="transparent"
                            border="0"
                        />
                    </div>
                </div>
                {drafts?.length > 0 ? (
                    <div className="flex justify-between pt-2">
                        <TableWrapper>
                            <Table
                                hidePagination
                                removeBorder
                                columns={columns}
                                rows={drafts.map(item => ({
                                    id: item.id,
                                    referenceNumber: item.referenceNumber
                                        ? item.referenceNumber
                                        : '...',
                                    issuedBy: `${item.firstName} ${item.lastName}`,
                                    amount:
                                        summaryFilterOption === 'Remittance'
                                            ? item.amountToRemit
                                            : summaryFilterOption === 'Loan'
                                            ? item.loanAmountInDollars
                                            : item.pofAmountInDollars,
                                    purpose: item.purpose,
                                    date: format(
                                        new Date(item.createdAt),
                                        'dd MMM, yyyy',
                                    ),
                                    status: item.paymentStatus,
                                    currency: item.currency,
                                }))}
                                pageSize="6"
                            />
                        </TableWrapper>
                    </div>
                ) : (
                    <NoRecordFound className="mt-6" />
                )}
            </RecentTransaction>

            <Details>
                <ApplicationStatusArea>
                    <div className="flex justify-between gap-4">
                        <div className="flex flex-col gap-2">
                            <Text type="h4">
                                {dashboardData.applicationStats
                                    ?.noOfApprovedApplication || 0}
                            </Text>
                            <Text type="p">Approved</Text>
                        </div>
                        <div>
                            <Icon type="approved-stat" />
                        </div>
                    </div>
                    <div className="flex justify-between gap-4">
                        <div className="flex flex-col gap-2">
                            <Text type="h4">
                                {dashboardData.applicationStats
                                    ?.totalOngoingApplications || 0}
                            </Text>
                            <Text type="p">Ongoing</Text>
                        </div>
                        <div>
                            <Icon type="ongoing-stat" />
                        </div>
                    </div>
                    <div className="flex justify-between gap-4">
                        <div className="flex flex-col gap-2">
                            <Text type="h4">
                                {dashboardData.applicationStats
                                    ?.totalRequiredDocumentsApplications || 0}
                            </Text>
                            <Text type="p">Requiring documents</Text>
                        </div>
                        <div>
                            <Icon type="doc-required-stat" />
                        </div>
                    </div>
                    <div className="flex justify-between gap-4">
                        <div className="flex flex-col gap-2">
                            <Text type="h4">
                                {dashboardData.applicationStats
                                    ?.totalRejectedApplication || 0}
                            </Text>
                            <Text type="p">Rejected</Text>
                        </div>
                        <div>
                            <Icon type="rejected-stat" />
                        </div>
                    </div>
                    <div className="flex justify-between gap-4">
                        <div className="flex flex-col gap-2">
                            <Text type="h4">
                                {dashboardData.applicationStats
                                    ?.totalDisbursedApplications || 0}
                            </Text>
                            <Text type="p">Disbursed</Text>
                        </div>
                        <div>
                            <Icon type="disbursed-stat" />
                        </div>
                    </div>
                </ApplicationStatusArea>

                <ActivityChart>
                    <div className="flex w-full items-center justify-between">
                        <div>
                            <Text type="h4">Activity</Text>
                        </div>
                        <div className="flex items-center gap-4">
                            <Text type="p">Last 6 months</Text>
                            <SelectInput
                                width="fit-content"
                                options={[
                                    'Remittance',
                                    'Loan',
                                    'Short term loans',
                                    'Admission',
                                ]}
                                value={statsFilter}
                                setValue={item => setStatsFilter(item)}
                                size="sm"
                                bgcolor="transparent"
                                border="0"
                            />
                        </div>
                    </div>
                    <ChartWrapper>
                        {statLoading ? (
                            <Skeleton active />
                        ) : (
                            <Line options={options} data={data} />
                        )}
                    </ChartWrapper>
                </ActivityChart>
            </Details>
        </Wrapper>
    )
}
