import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button } from "../../atoms";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { AuthTemplate } from "../../templates";
import { brandTheme } from "../../../theme";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../atoms/Loader";

export const PaymentSuccessURL = () => {
  let [searchParams] = useSearchParams();
  const axiosPrivate = useAxiosPrivate();
  const {
    isPending,
    error,
    data: paymentStatus,
  } = useQuery({
    queryKey: ["dashboard-data"],
    queryFn: async () => {
      const response = await axiosPrivate.post("/budpay/verify-payment", {
        reference: searchParams.get("reference"),
      });
      return response.data;
    },
  });

  if (isPending)
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Loader type="circle" />
      </div>
    );

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      {paymentStatus ? (
        <div className="flex flex-col gap-4">
          <AuthTemplate
            title="Payment Confirmed Successfully"
            subtitle={`Transaction with reference ${searchParams.get(
              "reference"
            )} was successful`}
          >
            <Link to="/transactions">
              <Button width="100%" size="md" bgcolor={brandTheme.brand.secondary} style={{ color: "white" }}>
                See Transactions
              </Button>
            </Link>
          </AuthTemplate>
        </div>
      ) : (
        <AuthTemplate
          title="Payment Failed"
          subtitle={`Kindly check that you were not debited for this transaction and try again`}
        >
          <Link to="/transactions">
            <Button width="100%" size="md" bgcolor={brandTheme.brand.secondary} style={{ color: "white" }}>
              Go to Transactions
            </Button>
          </Link>
        </AuthTemplate>
      )}
    </>
  );
};
