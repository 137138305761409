import React from "react";
import { Text } from "../../atoms";

export const NoRecordFound = ({
  message = "No record found.",
  className = "h-[300px]",
}) => {
  return (
    <div className={`flex justify-center items-center ${className}`}>
      <Text type="p">{message}</Text>
    </div>
  );
};
