import React from "react";
import { AuthTemplate } from "../../templates/AuthTemplate";
import { Button } from "../../atoms";
import { brandTheme } from "../../../theme";

export const ResetPasswordLinkConfirmation = () => {
  return (
    <AuthTemplate
      title="Reset password link sent"
      subtitle="We sent a message to your email with a link to reset your password"
    >
      <Button width="100%" size="md" bgcolor={brandTheme.brand.secondary} style={{ color: "white" }}>
        Open email app
      </Button>
    </AuthTemplate>
  );
};
