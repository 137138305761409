import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import logo from '../../../assets/svgs/full_logo.svg'
import { Button, Icon, Text, Container } from '../../atoms'
import { africredColors } from '../../../theme/colors'
import { Link, useNavigate } from 'react-router-dom'
import { screen } from '../../../theme'
import { MobileSideNav } from '../Modal/SideNav'
import { MobileSideNavWrapper, SideNavItems } from '../../templates'
import useAuth from '../../../hooks/useAuth'

const TopNav = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
`

const MobileMenu = styled(Icon)`
    display: block;
    @media only screen and (${screen.md}) {
        display: none;
    }
`
const Logo = styled.img`
    width: 80px;

    @media only screen and (${screen.md}) {
        width: 130px;
    }
`

export const AppNav = () => {
    const [isSideNavOpen, setIsSideNavOpen] = useState(false)
    const auth = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        // if (auth?.id) {
        //     navigate('/dashboard')
        // }
    }, [auth?.id, navigate])

    return (
        <>
            <Container>
                <TopNav>
                    <Link to="/">
                        <Logo src={logo} alt={`logo`} />
                    </Link>

                    <MobileMenu
                        type="hamburger"
                        onClick={() => setIsSideNavOpen(true)}
                    />
                    <div className="hidden md:flex items-center gap-6">
                        <Link to="/remittance">
                            <Text type="p">Send money abroad</Text>
                        </Link>
                        <a href='https://www.banking.africred.org/login' target='_blank'>
                            <Text type="p">Become a partner</Text>
                        </a>
                        <Link to="/signup">
                            <Text type="p">Become an Agent</Text>
                        </Link>
                        <ExploreDropdown />
                        <Link to="/apply-for-admission">
                            <Button
                                width="fit-content"
                                size="sm"
                                border={`1px solid ${africredColors.primary.blue[600]}`}
                                bgcolor={africredColors.primary.blue[600]}
                            >
                                <Text
                                    type="p"
                                    weight="medium"
                                    textWrap="nowrap"
                                    color="#fff"
                                    hovercolor="#fff"
                                >
                                    Apply now
                                </Text>
                            </Button>
                        </Link>
                    </div>
                </TopNav>
            </Container>

            {isSideNavOpen && (
                <MobileSideNav
                    showLogo={false}
                    isOpen={isSideNavOpen}
                    setIsOpen={setIsSideNavOpen}
                >
                    <MobileSideNavWrapper>
                        <div>
                            <SideNavItems to="/apply-for-proof-of-funds">
                                <Text type="p">Get short term loans</Text>
                            </SideNavItems>
                            <SideNavItems to="/remittance">
                                <Text type="p">Send money abroad</Text>
                            </SideNavItems>
                            <SideNavItems to="/apply-for-loan">
                                <Text type="p">Student loan</Text>
                            </SideNavItems>
                            <SideNavItems to="/sme-loans">
                                <Text type="p">SME loans</Text>
                            </SideNavItems>
                            <SideNavItems to="/">
                                <Text type="p">Become a partner</Text>
                            </SideNavItems>
                            <SideNavItems to="/apply-for-admission">
                                <Button
                                    width="100%"
                                    size="sm"
                                    border={`1px solid ${africredColors.primary.blue[600]}`}
                                    bgcolor={africredColors.primary.blue[600]}
                                >
                                    <Text
                                        type="p"
                                        weight="medium"
                                        textWrap="nowrap"
                                        color="#fff"
                                        hovercolor="#fff"
                                    >
                                        Apply now
                                    </Text>
                                </Button>
                            </SideNavItems>
                        </div>
                    </MobileSideNavWrapper>
                </MobileSideNav>
            )}
        </>
    )
}

const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
`

const DropdownButton = styled.button`
    display: flex;
    gap: 8px;
    align-items: center;
    border: none;
    cursor: pointer;
    padding-inline: 8px;
`

const DropdownContent = styled.div`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    width: max-content;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
`

const DropdownItem = styled.div`
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    &:hover {
        background-color: #f1f1f1;
    }
`

const ExploreDropdown = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleDropdown = () => setIsOpen(!isOpen)

    return (
        <DropdownContainer>
            <DropdownButton onClick={toggleDropdown}>
                <Text type="p" weight="semibold">
                    Explore
                </Text>
                <Icon type="caret" />
            </DropdownButton>
            <DropdownContent isOpen={isOpen}>
                <DropdownItem>
                    <Link to="/apply-for-proof-of-funds">
                        <Text type="p">Get short term loans</Text>
                    </Link>
                </DropdownItem>
                <DropdownItem>
                    <Link to="/apply-for-loan">
                        <Text type="p">Student loan</Text>
                    </Link>
                </DropdownItem>
                <DropdownItem>
                    <Link to="/sme-loans">
                        <Text type="p">SME loans</Text>
                    </Link>
                </DropdownItem>
            </DropdownContent>
        </DropdownContainer>
    )
}
