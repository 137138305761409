// const fontFamilies = {
//   primary: "Creato Display",
//   secondary: "Open Sans",
// };

export const africredTypescale = {
  headline1: {
    fontSize: "4.20875rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-2.8%",
  },
  headline2: {
    fontSize: "3.1575rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "3.7875rem",
    letterSpacing: "-4%",
  },
  headline3: {
    fontSize: "2.36875rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-3%",
  },
  headline4: {
    fontSize: "1.77688rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "2.13225rem",
    letterSpacing: "-3%",
  },
  headline5: {
    fontSize: "1.33313rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.59975rem",
    letterSpacing: "-3%",
  },
  small: {
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.0625rem",
    letterSpacing: "3%",
  },
  body: {
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.1875rem",
    letterSpacing: "-3%",
  },
};

export const inputSizes = {
  lg: {
    height: "64px",
    padding: "16px 48px",
  },
  md: {
    height: "56px",
    padding: "16px 48px",
  },
  sm: {
    height: "48px",
    padding: "12px 40px",
  },
  xs: {
    height: "40px",
    padding: "8px 32px",
  },
};
