import React from "react";
import { Icon, Input, SelectInput } from "../../atoms";
import { africredColors } from "../../../theme/colors";

export const TableFilter = ({
  filterData,
  setFilterData,
  sortOptions = ["Asc", "Dsc"],
}) => {
  return (
    <div className="flex gap-x-4 gap-y-2 flex-wrap z-10 lg:flex-nowrap">
      <Input
        type="search"
        width="275px"
        size="sm"
        value={filterData?.searchTerm}
        icon={<Icon type="search" />}
        paddingleft="3rem"
        bgcolor="transparent"
        placeholder="search"
        onChange={(e) =>
          setFilterData((prev) => ({ ...prev, searchTerm: e.target.value }))
        }
        border={`1px solid ${africredColors.neutrals.night[300]}`}
      />
      <SelectInput
        options={sortOptions}
        value={filterData?.sort || "Sort"}
        setValue={(val) => setFilterData((prev) => ({ ...prev, sort: val }))}
        size="sm"
        bgcolor="transparent"
        border={`1px solid ${africredColors.neutrals.night[300]}`}
      />
    </div>
  );
};
