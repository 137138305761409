import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link, useLocation, useParams } from 'react-router-dom'
import { AppNav } from '../../molecules/AppNav'
import smallLogo from '../../../assets/svgs/small-logo.svg'
import { Icon, Text, Button } from '../../atoms'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { africredColors } from '../../../theme/colors'
import schoolLogo from '../../../assets/pngs/school-logo.png'
import axiosPublic from '../../../api/axios'
import { Modal } from '../../molecules'
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from 'react-share'
import styled from 'styled-components'

const RichText = styled.div`
    font-size: 1rem;
    color: #5d5d5d;
    :hover {
        color: #5d5d5d;
    }
    /* General reset for margins and paddings */

    /* Paragraphs */
    p {
    margin: 0;
    line-height: 1.6;
    }

    /* Headings */
    h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: bold;
    }

    h1 {
    font-size: 2em;
    }

    h2 {
    font-size: 1.75em;
    }

    h3 {
    font-size: 1.5em;
    }

    h4 {
    font-size: 1.25em;
    }

    h5 {
    font-size: 1em;
    }

    h6 {
    font-size: 0.875em;
    }

    /* Lists */
    ul, ol {
    margin: 0 0 1em 2em;
    padding: 0;
    list-style: unset;
    }

    li {
    margin: 0.5em 0;
    }

    /* Blockquotes */
    blockquote {
    margin: 0 0 1em;
    padding: 0.5em 1em;
    border-left: 0.25em solid #ccc;
    background-color: #f9f9f9;
    }

    /* Images */
    img {
    max-width: 100%;
    height: auto;
    }

    /* Code blocks */
    pre, code {
    font-family: 'Courier New', Courier, monospace;
    background-color: #f4f4f4;
    padding: 0.5em;
    border-radius: 4px;
    }

    pre {
    overflow: auto;
    }

    /* Horizontal rule */
    hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    }

    /* Links */
    a {
    color: #007bff;
    text-decoration: none;
    }

    a:hover {
    text-decoration: underline;
    }

    /* Tables */
    table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 0 1em;
    }

    th, td {
    padding: 0.75em;
    border: 1px solid #ccc;
    text-align: left;
    }

    th {
    background-color: #f2f2f2;
    font-weight: bold;
    }

`

export const UniversityDetails = () => {
    const location = useLocation()
    const params = useParams()
    const isMobile = useScreenSize()
    const [showShareModal, setShowShareModal] = useState(false)

    const {
        isPending,
        error,
        data: universities,
    } = useQuery({
        queryKey: [`university`],
        queryFn: async () => {
            const response = await axiosPublic(
                `/university/search?id[0]=${params.id}`,
            )

            if (response.data) {
                return response.data
            }
        },
        enabled: !!params.id,
    })

    const toggleShareModal = () => {
        setShowShareModal(prev => !prev)
    }

    const university = universities?.foundUniversities[0] || {}

    const queryData = useMemo(() => {
        const { id, schoolName, state, country } = university

        return { schoolName, state, country, id }
    }, [university])

    return (
        <div className="w-full flex">
            <div className="w-full flex flex-col gap-10">
                <AppNav />
                <ShareModal
                    open={showShareModal}
                    url={window.location.href}
                    handleClose={toggleShareModal}
                />
                <div className="w-full flex flex-col gap-8 px-4 md:px-24 lg:px-28">
                    <div className="w-full relative">
                        <img
                            src={university.background?.Location}
                            alt="hero"
                            className="w-full"
                        />
                        <button
                            className="w-[107px] h-10 flex items-center justify-center gap-2 bg-white absolute bottom-5 right-5 rounded-lg"
                            onClick={toggleShareModal}
                        >
                            <Icon type="link" />
                            <Text
                                type="small"
                                fontSize="16px"
                                fontWeight="medium"
                            >
                                Share
                            </Text>
                        </button>
                    </div>
                    <div className="flex flex-col-reverse md:flex-row gap-8">
                        <div className="flex flex-col gap-8 w-full">
                            <div className="flex gap-4">
                                <div className="w-10 min-w-10">
                                    <img
                                        src={
                                            university?.icon?.Location ||
                                            schoolLogo
                                        }
                                        alt="school logo"
                                        className="w-full"
                                    />
                                </div>
                                <div className="w-[calc(100%-40px)] flex flex-col gap-3">
                                    <Text
                                        type="small"
                                        fontSize="2.2rem"
                                        fontWeight="medium"
                                    >
                                        {university?.schoolName || ''}
                                    </Text>
                                    <div className="flex flex-wrap gap-4 items-center">
                                        <div className="flex gap-2 items-center">
                                            <Icon type="map-pin" />
                                            <Text
                                                type="small"
                                                fontSize="1rem"
                                                color="#5d5d5d"
                                                hovercolor="#5d5d5d"
                                            >
                                                {university?.state?.name ||
                                                    university?.state}
                                                , {university?.country?.name}
                                            </Text>
                                        </div>

                                        <button
                                            className="flex gap-2 items-center"
                                            onClick={toggleShareModal}
                                        >
                                            <Icon type="magnet-link" />
                                            <Text
                                                type="small"
                                                fontSize="1rem"
                                                color="#5d5d5d"
                                                hovercolor="#5d5d5d"
                                            >
                                                Share
                                            </Text>
                                        </button>

                                        <Link
                                            to={university?.website}
                                            target="_blank"
                                        >
                                            <div className="flex gap-2 items-center">
                                                <Icon type="share" />
                                                <Text
                                                    type="small"
                                                    fontSize="1rem"
                                                    color="#5d5d5d"
                                                    hovercolor="#5d5d5d"
                                                >
                                                    Go to website
                                                </Text>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <RichText
                                dangerouslySetInnerHTML={{
                                    __html: university.universityDescription,
                                }}
                            />
                            <div className="flex flex-wrap [border-bottom:_1px_solid_#d1d1d1] pb-8 gap-2">
                                <Tag
                                    icon="two-ticks"
                                    text="Program Fees"
                                    subtext={university.programFeesRange}
                                />
                                <Tag
                                    icon="two-ticks"
                                    text="Living Cost"
                                    subtext={university.livingCostRange}
                                />
                            </div>
                            <div className="w-full flex flex-col gap-4">
                                <Text
                                    type="small"
                                    fontSize="1.3rem"
                                    fontWeight="medium"
                                >
                                    Our Programs
                                </Text>
                                <div className="flex flex-wrap [border-bottom:_1px_solid_#d1d1d1] pb-8 gap-2">
                                    {university.programs?.map(
                                        (program, idx) => (
                                            <Tag
                                                key={idx}
                                                icon="circle-tick"
                                                text={program}
                                            />
                                        ),
                                    )}
                                </div>
                            </div>
                            <div className="w-full flex flex-col gap-4">
                                <Text
                                    type="small"
                                    fontSize="1.3rem"
                                    fontWeight="medium"
                                >
                                    Our Courses
                                </Text>
                                <div className="flex flex-wrap [border-bottom:_1px_solid_#d1d1d1] pb-8 gap-2">
                                    {university.courses?.map(
                                        (course, idx) => (
                                            <Tag
                                                key={idx}
                                                icon="book"
                                                text={course}
                                            />
                                        ),
                                    )}
                                </div>
                            </div>
                            <div className="w-full flex flex-col gap-4">
                                <Text
                                    type="small"
                                    fontSize="1.3rem"
                                    fontWeight="medium"
                                >
                                    About University
                                </Text>
                                <RichText
                                    dangerouslySetInnerHTML={{
                                        __html: university.about,
                                    }}
                                />
                            </div>
                            <div className="w-full flex flex-col gap-4">
                                <Text
                                    type="small"
                                    fontSize="1.3rem"
                                    fontWeight="medium"
                                >
                                    Location
                                </Text>
                                <RichText
                                    dangerouslySetInnerHTML={{
                                        __html: university.locationDescription,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="w-full md:w-[387px] md:min-w-[387px] h-fit flex flex-col gap-8 [border:_1px_solid_#d1d1d1] rounded-[32px] p-8 overflow-hidden">
                            <Text
                                type="small"
                                fontSize="1.7rem"
                                fontWeight="medium"
                            >
                                Great Study Opportunities Awaits you at{' '}
                                {university.schoolName}
                            </Text>
                            <div className="flex flex-wrap [border-bottom:_1px_solid_#d1d1d1] [border-top:_1px_solid_#d1d1d1] py-8 gap-2">
                                {university.opportunities?.map((text, idx) => (
                                    <Tag key={idx} text={text} />
                                ))}
                                <Tag
                                    text={`Employability: ${
                                        university?.employability || 0
                                    }`}
                                />
                            </div>
                            <Link
                                to={
                                    '/apply-for-admission?university=' +
                                    JSON.stringify(queryData)
                                }
                            >
                                <Button
                                    width="100%"
                                    size="sm"
                                    border={`1px solid ${africredColors.primary.blue[600]}`}
                                    bgcolor={africredColors.primary.blue[600]}
                                >
                                    <Text
                                        type="p"
                                        weight="medium"
                                        textWrap="nowrap"
                                        color="#fff"
                                        hovercolor="#fff"
                                    >
                                        Apply now
                                    </Text>
                                </Button>
                            </Link>
                            <div className="w-full flex justify-center">
                                <img src={smallLogo} alt="logo" width="100px" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col gap-4 justify-center items-center py-8">
                        <Text type="small" fontSize="1.8rem" fontWeight="bold">
                            There's more to explore
                        </Text>
                        <Text
                            type="small"
                            fontSize="1rem"
                            fontWeight="regular"
                            color="#656565"
                            hovercolor="#656565"
                        >
                            View Over {location.state?.universitiesCount || 0}{' '}
                            Amazing Universities Around The World.
                        </Text>
                        <Link to="/universities">
                            <Button
                                width="100%"
                                size="sm"
                                border="1px solid #151515"
                                bgcolor="transparent"
                            >
                                <Text
                                    type="p"
                                    weight="medium"
                                    textWrap="nowrap"
                                    color="#151515"
                                    hovercolor="#151515"
                                >
                                    View All Universities
                                </Text>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Tag = ({ icon = 'verify', text = '', subtext = '' }) => {
    return (
        <div className="flex gap-2 items-center h-10 px-2 [border:_1px_solid_#d1d1d1] rounded-lg overflow-hidden">
            <Icon type={icon} />
            <Text type="small" fontSize="16px" fontWeight="bold">
                {text}
            </Text>
            {subtext && (
                <Text
                    type="small"
                    fontSize="16px"
                    color="#656565"
                    hovercolor="#656565"
                >
                    {subtext}
                </Text>
            )}
        </div>
    )
}

const ShareModal = ({ open, url, handleClose }) => {
    return (
        <Modal isOpen={open} positionX="center">
            <div className="flex flex-col p-4 bg-white rounded-md gap-4">
                <div className="flex items-center justify-between">
                    <Text type="h5" fontWeight="medium">
                        Share
                    </Text>
                    <button
                        className="w-10 h-10 rounded-full flex items-center justify-center hover:bg-gray-200"
                        onClick={handleClose}
                    >
                        <Icon type="close" />
                    </button>
                </div>
                <div className="flex flex-wrap gap-4">
                    <EmailShareButton url={url}>
                        <EmailIcon size={32} round={true} />
                    </EmailShareButton>
                    <FacebookShareButton url={url}>
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <LinkedinShareButton url={url}>
                        <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                    <TelegramShareButton url={url}>
                        <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                    <TwitterShareButton url={url}>
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <WhatsappShareButton url={url}>
                        <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                    <PinterestShareButton url={url}>
                        <PinterestIcon size={32} round={true} />
                    </PinterestShareButton>
                </div>
            </div>
        </Modal>
    )
}
