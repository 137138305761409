import { format } from "date-fns";
import { africredColors } from "../../../theme/colors";
import { Text } from "../../atoms";

export const agentDashboardData = {
    "transactionStats": {
        "totalRemittances": "600.0K",
        "totalPofs": "15.6M",
        "totalLoans": "6.0M",
        "totalTransactions": "452.7K"
    },
    "graphPoints": {
        "monthNames": [
            "January",
            "February",
            "March",
            "April",
            "December"
        ],
        "totalAmounts": [
            20120,
            0,
            0,
            0,
            432535
        ]
    },
    "applicationStats": {
        "totalOngoingApplications": 0,
        "totalRequiredDocumentsApplications": 1,
        "totalRejectedApplication": 2,
        "totalDisbursedApplications": 0,
        "noOfApprovedApplication": 0
    },
    "drafts": {
        "loanDrafts": [],
        "pofDrafts": [],
        "remittancesDrafts": [
            {
                "account": "6587f6384714493fc9c1a849",
                "email": "aweolumidedavid@gmail.com",
                "firstName": "Olumide",
                "lastName": "Chuks",
                "phoneNumber": {
                    "code": "+234",
                    "number": "8161229635",
                    "local": "08161229635"
                },
                "cityOfResidence": "Lagos",
                "countryOfStudy": "UK",
                "payStackReferenceNumber": "nf2qti0oia",
                "amountToRemit": 5000,
                "currency": "USD",
                "amountToRemitInNaira": 5000000,
                "amount": "5000000",
                "processingFeeInNaira": 42000,
                "remittanceProcessingFee": 35,
                "schoolOfferLetter": "6584b36ab8a4fa7391730ea3",
                "proofOfIdentity": {
                    "typeOfProofOfIdentity": "Nin",
                    "file": "6584b36ab8a4fa7391730ea3"
                },
                "purpose": "Pay school fees",
                "paymentStatus": "pending",
                "createdAt": "2024-01-06T11:40:51.552Z",
                "updatedAt": "2024-01-06T11:40:51.552Z",
                "id": "65993c43e6099b9a1ba5502c",
                "transactionType": "remittance"
            },
            {
                "account": "6587f6384714493fc9c1a849",
                "email": "reechiedeclan@gmail.com",
                "firstName": "Richard",
                "lastName": "Enuenweisu",
                "phoneNumber": {
                    "code": "+234",
                    "number": "8060771255",
                    "local": "08060771255"
                },
                "cityOfResidence": "Kumantaka",
                "payStackReferenceNumber": "mqbfoywh0g",
                "amountToRemit": 5000,
                "currency": "USD",
                "amountToRemitInNaira": 6000000,
                "amountToRemitInDollar": 5000,
                "amount": "6000000",
                "processingFeeInNaira": 42000,
                "processingFeeInDollar": 35,
                "schoolOfferLetter": "658af429f6aec2d17285e32a",
                "purpose": "nil",
                "paymentStatus": "pending",
                "createdAt": "2023-12-26T15:42:46.643Z",
                "updatedAt": "2023-12-29T18:23:58.282Z",
                "countryOfStudy": "UK",
                "proofOfIdentity": {
                    "typeOfProofOfIdentity": "Nin",
                    "file": "658ab8c09be4a430109218e6"
                },
                "id": "658af476f6aec2d17285e32f",
                "transactionType": "remittance"
            },
            {
                "account": "6587f6384714493fc9c1a849",
                "email": "reechiedeclan@gmail.com",
                "firstName": "Richard",
                "lastName": "Enuenweisu",
                "phoneNumber": {
                    "code": "+234",
                    "number": "8060771255",
                    "local": "08060771255"
                },
                "cityOfResidence": "Kumantaka",
                "payStackReferenceNumber": "fv3kit3wu3",
                "amountToRemit": 7000,
                "currency": "USD",
                "amountToRemitInNaira": 8400000,
                "amountToRemitInDollar": 7000,
                "amount": "8400000",
                "processingFeeInNaira": 42000,
                "processingFeeInDollar": 35,
                "schoolOfferLetter": "658ac4e89be4a430109219e6",
                "purpose": "Nil",
                "paymentStatus": "pending",
                "createdAt": "2023-12-26T12:20:11.648Z",
                "updatedAt": "2023-12-29T18:23:58.282Z",
                "countryOfStudy": "UK",
                "proofOfIdentity": {
                    "typeOfProofOfIdentity": "Nin",
                    "file": "658ab8c09be4a430109218e6"
                },
                "id": "658ac4fb9be4a430109219eb",
                "transactionType": "remittance"
            }
        ]
    }
}

function renderHeader(params) {
    return (
        <Text type="p" weight="bold">
            {params.colDef.headerName}
        </Text>
    );
}

const renderCell = ({ value, row }) => {
    return <Text type="p" color={africredColors.neutrals.night[500]}>
        {value}
    </Text>
}

export const latestTransactionColumn = [
    {
        field: "transactionType",
        headerName: "Type",
        minWidth: 160,
        flex: 2,
        renderHeader,
        renderCell,
    },
    {
        field: "amount",
        headerName: "Amount",
        minWidth: 160,
        flex: 2,
        renderHeader,
        renderCell:({ value, row }) => {
            return <Text type="p" color={africredColors.neutrals.night[500]}>
                ${value}
            </Text>
        }
    },
    {
        field: "createdAt",
        headerName: "Date",
        minWidth: 160,
        flex: 2,
        renderHeader,
        renderCell:({ value, row }) => {
            return <Text type="p" color={africredColors.neutrals.night[500]}>
                {format(new Date(value), "do MMM, yyyy")}
            </Text>
        },
    },
    {
        field: "paymentStatus",
        headerName: "Status",
        minWidth: 70,
        flex: 2,
        renderCell: ({ value, row }) => {
            if(value === 'successful'){
                return  <Text type="p" color={africredColors.primary.green[600]} align-self="end">
                            {value}
                        </Text>
            }

            if(value === 'pending'){
                return  <Text type="p" color={africredColors.primary.gold[300]} align-self="end">
                            {value}
                        </Text>
            }

            if(value === 'failed'){
                return  <Text type="p" color={africredColors.secondary.red[300]} align-self="end">
                            {value}
                        </Text>
            }
        },
    },
]

export const latestTransactionRow = [
    {
        id: 1,
        type: "Remittance",
        amount: 34.99,
        date: "15th June 2015",
        status: "completed"
    },
    {
        id: 2,
        type: "Remittance",
        amount: 34.99,
        date: "15th June 2015",
        status: "completed"
    },
    {
        id: 3,
        type: "Remittance",
        amount: 34.99,
        date: "15th June 2015",
        status: "completed"
    },
    {
        id: 4,
        type: "Remittance",
        amount: 34.99,
        date: "15th June 2015",
        status: "completed"
    },
]


export const rewardHistoryColumn = [
    {
        field: "status",
        headerName: "",
        minWidth: 100,
        flex: 2,
        renderHeader,
    },

    {
        field: "type",
        headerName: "",
        minWidth: 160,
        flex: 2,
        renderHeader,
    },

    {
        field: "amount",
        headerName: "",
        minWidth: 160,
        flex: 2,
        renderHeader,
    },
]

export const rewardHistoryRow = [
    {
        id: 1,
        status: "success",
        type: "remittance",
        amount: 50,
        date: "17/03/2024"
    },
    {
        id: 2,
        status: "success",
        type: "Widthdrawal",
        amount: 50,
        date: "17/03/2024"
    },
    {
        id: 3,
        status: "success",
        type: "short term loan",
        amount: 50,
        date: "17/03/2024"
    },
    {
        id: 4,
        status: "success",
        type: "Widthdrawal",
        amount: 50,
        date: "17/03/2024"
    },
    {
        id: 5,
        status: "success",
        type: "remittance",
        amount: 50,
        date: "17/03/2024"
    }
]