import React, { useState } from "react";
import { NavBar } from "../../molecules/NavBar";
import styled from "styled-components";
import { brandTheme, screen } from "../../../theme";
import { Icon, ReadMore, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import { pageAnimationConfig } from "../../../utils";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { TableFilter } from "../../organisms";
import { format } from "date-fns";
import { NoRecordFound } from "../../molecules/NoRecordFound";
import { toast } from "react-toastify";
import { Loader } from "../../atoms/Loader";
import LoaderContainer from "../../atoms/Loader/LoaderContainer";

const Wrapper = styled(motion.div)`
  height: 100vh;
  background-color: ${brandTheme.brand.backgroundSkin};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 12px;

  @media only screen and (${screen.md}) {
    padding: 32px;
  }
`;
const NotificationsContainer = styled.div`
  padding: 25px 12px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 16px;
  background: ${africredColors.neutrals.white[10]};

  @media only screen and (${screen.md}) {
    padding: 25px 24px;
    border-radius: 32px;
  }
`;
const NotificationLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${africredColors.neutrals.night[50]};
`;
const NotificationItems = styled.div`
  display: flex;
  padding: 24px;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid ${africredColors.neutrals.night[300]};
  }
`;

export const Notifications = () => {
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    sort: "All",
  });
  const axiosPrivate = useAxiosPrivate();
  const {
    isPending,
    error,
    data: notifications,
    refetch,
  } = useQuery({
    queryKey: ["notifications"],
    queryFn: async () => {
      const response = await axiosPrivate("/notification/view-all");
      return response.data;
    },
  });

  async function hideNotification(id) {
    if (id) {
      try {
        await axiosPrivate.patch(`/notification/update/${id}`, {
          visible: false,
        });
      } catch (err) {
        toast.error("Couldn't hide notification. An Error occured!");
      }
    }
  }

  if (isPending) return <LoaderContainer />;

  if (error) return "An error has occurred: " + error.message;

  return (
    <Wrapper {...pageAnimationConfig}>
      <NavBar setSideNav={() => navigate("/dashboard")} />

      {notifications?.length > 0 ? (
        <Content className="w-full md:w-4/6">
          <Text type="h3" weight="medium">
            Notifications
          </Text>
          <NotificationsContainer>
            <div className="flex justify-between gap-y-2 items-center flex-wrap lg:flex-nowrap">
              <Text type="h5" weight="medium">
                Notifications
              </Text>
              <TableFilter
                filterData={filterData}
                setFilterData={setFilterData}
                sortOptions={["All", "Read", "Unread"]}
              />
            </div>

            <div>
              {notifications
                .filter((item) =>
                  item.message.toLowerCase().includes(filterData.searchTerm) &&
                  filterData.sort === "All"
                    ? true
                    : item.read === (filterData.sort === "Read" ? true : false)
                )
                .map((item) => (
                  <NotificationItems key={item}>
                    <div className="flex gap-6">
                      <NotificationLogoWrapper>
                        <Icon type="logo" />
                      </NotificationLogoWrapper>
                      <div className="flex flex-col gap-4 w-full">
                        <Text color="#222" type="h5" weight="medium">
                          {item.subject}
                        </Text>
                        <ReadMore
                          text={item.message}
                          id={item.id}
                          status={item.read === "true" ? false : true}
                        />
                        <div className="flex gap-4">
                          <Text type="small">
                            {format(new Date(item.createdAt), "dd MMM, yyyy")}
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <Icon
                        onClick={() => {
                          hideNotification(item.id);
                          refetch();
                        }}
                        type="close-sm"
                      />
                    </div>
                  </NotificationItems>
                ))}
            </div>
          </NotificationsContainer>
        </Content>
      ) : (
        <NoRecordFound message="No notifications yet..." />
      )}
    </Wrapper>
  );
};
