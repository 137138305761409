import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon, Text } from '../../atoms'
import { fetchFlag } from '../InputWithAddonSelect'
import { africredColors } from '../../../theme/colors'
import { screen } from '../../../theme'
import { formatNumber } from '../../../utils'
import { currencyFlag } from '../../../constants'

const PriceSummaryWrapper = styled.div`
    display: flex;
    padding: 18px 16px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #d1d1d1;
    flex-direction: column;
    gap: 32px;

    @media only screen and (${screen.sm}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: 80px;
    }
`

export const PricingConversionBreakdown = ({
    currencyDetails,
    processingFeeInChoosenCurrency,
    percentageProcessingFeeText = '2% processing fee',
    selectMenuData,
    currencyToPay,
    feeInCurrencyToPay,
    setData,
    currencyToPayIcon,
    isRemittance = false,
    totalAmount,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleDropDown = () => {
        setIsOpen(!isOpen)
    }
    return (
        <>
            <PriceSummaryWrapper>
                <div className="flex flex-col gap-2">
                    <Text
                        color={africredColors.neutrals.night[600]}
                        width="fit-content"
                        type="small"
                        textWrap="nowrap"
                    >
                        {percentageProcessingFeeText}
                    </Text>
                    <div className="flex gap-2 items-center">
                        <img src={currencyDetails?.icon} alt="flag" />
                        <Text
                            type="p"
                            weight="medium"
                            color={africredColors.neutrals.night[600]}
                        >
                            {currencyDetails?.value}{' '}
                            {processingFeeInChoosenCurrency}
                        </Text>
                    </div>
                </div>
                <div>
                    <Icon type="arrow-swap" />
                </div>
                <div className="flex flex-col gap-2 items-start sm:items-end relative">
                    <Text
                        type="small"
                        color={africredColors.neutrals.night[600]}
                        width="fit-content"
                        textWrap="nowrap"
                    >
                        Payment will be processed in
                    </Text>
                    <div
                        className="flex gap-2 items-center justify-start sm:justify-end"
                        onClick={() => toggleDropDown()}
                    >
                        <img src={currencyToPayIcon} alt="flag" />
                        <Text
                            type="p"
                            weight="medium"
                            color={africredColors.neutrals.night[600]}
                        >
                            {currencyToPay} {feeInCurrencyToPay}
                        </Text>
                        {isOpen ? (
                            <Icon type="up-caret-light" />
                        ) : (
                            <Icon type="down-caret-light" />
                        )}
                        {isOpen && (
                            <div
                                className="absolute w-full items-center top-full z-50 bg-white flex flex-col p-3 h-fit gap-3 rounded right-0"
                                style={{ bottom: '5px', right: '2px' }}
                            >
                                {currencyFlag.map((item, idx) => {
                                    return (
                                        <div
                                            className="flex gap-4 cursor-pointer"
                                            key={idx}
                                            onClick={e => {
                                                setData(prev => ({
                                                    ...prev,
                                                    currencyToPay: item.value,
                                                    currencyToPayIcon:
                                                        item.icon,
                                                }))
                                            }}
                                        >
                                            <img src={item.icon} alt="flag" />
                                            <Text
                                                type="p"
                                                weight="medium"
                                                color={
                                                    africredColors.neutrals
                                                        .night[600]
                                                }
                                            >
                                                {item.value}
                                            </Text>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </PriceSummaryWrapper>
            {/* {isRemittance && (
                <Text
                    type="p"
                    style={{ textAlign: 'right' }}
                >{`Total: ${currencyToPay} ${formatNumber(totalAmount)}`}</Text>
            )} */}
        </>
    )
}
