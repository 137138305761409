import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

const ModalWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: ${({ alignitems }) => alignitems || "center"};
  justify-content: ${({ justifycontent }) => justifycontent || "center"};
  z-index: 3;
`;

export const Modal = ({ children, isOpen, positionY, positionX }) => {
  function resolvePositionY(positionY) {
    if (positionY === "top") {
      return "flex-start";
    } else if (positionY === "bottom") {
      return "flex-end";
    } else {
      return "center";
    }
  }

  function resolvePositionX(positionX) {
    if (positionX === "start") {
      return "flex-start";
    } else if (positionX === "end") {
      return "flex-end";
    } else {
      return "center";
    }
  }

  return isOpen
    ? createPortal(
        <ModalWrapper
          justifycontent={resolvePositionX(positionX)}
          alignitems={resolvePositionY(positionY)}
        >
          {children}
        </ModalWrapper>,
        document.getElementById("portal")
      )
    : null;
};
