import { Icon } from "../../atoms/Icon";

export const sideNavLinks = {
  top: [
    {
      name: "Dashboard",
      link: "/dashboard",
      icon: <Icon type="3-cube" />,
    },
    {
      name: "Earnings",
      link: "/earnings",
      icon: <Icon type="dollar-square" />,
      isStudent:false
    },
    {
      name: "Payments",
      link: "/remittance",
      icon: <Icon type="card-sm" />,
    },
    {
      name: "Loans",
      link: "/loans",
      icon: <Icon type="wallet-sm" />,
    },
    {
      name: "Transactions",
      link: "/transactions",
      icon: <Icon type="mini-transaction" />,
    },
    // {
    //   name: "Short Term Loans",
    //   link: "/proof-of-funds",
    //   icon: <Icon type="proof-of-funds-sm" />,
    // },
    {
      name: "Admissions",
      link: "/universities",
      icon: <Icon type="school" className="svg-school-icon" />,
    },
  ],
  bottom: [
    {
      name: "Support",
      link: "/support",
      icon: <Icon type="support" />,
    },
  ],
  settingLinks: [
    {
      name: "Profile",
      link: "/settings/profile",
      icon: <Icon type="person" />,
    },
    {
      name: "KYC Details",
      link: "/settings/kyc",
      icon: <Icon type="kyc" />,
    },
    {
      name: "Login & Security",
      link: "/settings/login-security",
      icon: <Icon type="security" />,
    },
    {
      name: "Preference",
      link: "/settings/preference",
      icon: <Icon type="preference" />,
    },
  ],
};
