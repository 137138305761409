import styled from "styled-components";
import { screen } from "../../../theme";

export const Container = styled.div`
  width: 100%;
  margin: auto;
  padding: ${({ smPadding }) => smPadding || "0 10px"};

  @media only screen and (${screen.md}) {
    width: 85%;
  }
`;
