import styled from 'styled-components'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { CloseablePageTemplage } from '../../templates'
import { brandTheme, screen } from '../../../theme'
import { AntdSelect, Button, Icon, Input, Text } from '../../atoms'
import { africredColors } from '../../../theme/colors'
import { OnboardingFlow, StepProgressIndicator } from '../../molecules'
import { useState } from 'react'
import access from '../../../assets/pngs/partners/access.png'
import fcmb from '../../../assets/pngs/partners/fcmb.png'
import gtb from '../../../assets/pngs/partners/gtb.png'
import lendwise from '../../../assets/pngs/partners/lendwise.png'
import polaris from '../../../assets/pngs/partners/polaris.png'
import power from '../../../assets/pngs/partners/power.png'
import stilt from '../../../assets/pngs/partners/stilt.png'
import { toast } from 'react-toastify'
import {
    formatNumber,
    formatSelectOptionForAntd,
    isBVNValid,
    separatePhoneNumberString,
} from '../../../utils'
import { OTPComponent } from '../../organisms/OTPComponent'
import axiosPublic from '../../../api/axios'
import { dateDropdownEntries, stepTitles, loanTypes } from './data'

const FormSection = styled.section`
    background-color: ${brandTheme.brand.backgroundSkin4};
    border-radius: 16px;
    padding: 48px 24px;
    height: fit-content;

    @media only screen and (${screen.md}) {
        padding: 48px;
    }
`
const TitleWrapper = styled.div`
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 32px;
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 12px;

    @media (min-width: 840px) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;
    }
`
const GridItem = styled.div`
    display: flex;
    padding: 16px;
    gap: 10px;
    border-radius: 16px;
    background: ${africredColors.primary.green[50]};
`
const FormInputs = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;

    > div:first-child {
        padding-bottom: 18px;
        // border-bottom: 1px solid ${africredColors.neutrals.night[100]};
    }
`
const FormRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    > div {
        width: 100%;
    }

    @media only screen and (${screen.md}) {
        flex-direction: row;
    }
`

export const SmeLoanForm = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentIndex, setCurrentIndex] = useState(0)
    const [stepCount, setStepCount] = useState(null)
    const [id, setID] = useState('')
    const [otpSent, setOTPSent] = useState(false)
    const [loading, setLoading] = useState(false)

    const loanType = searchParams.get('type')
    const isComplete = searchParams.get('complete')
    const isVerify = searchParams.get('verify')

    const [onboardingData, setOnboardingData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        dob: {
            day: '',
            month: '',
            year: '',
        },
        gender: '',
        phoneNumber: {
            code: '',
            number: '',
        },
        loanAmount: '',
        loanType: loanTypes[loanType],
        reasonForLoanRequest: '',
        creditedTime: '',
        businessInfo: {
            businessName: '',
            businessUrl: '',
            applicantRole: '',
            businessSize: '',
            yearOfBusiness: '',
            businessSector: '',
        },
    })

    const onNext = (stepData, numberOfSteps) => {
        setOnboardingData({ ...onboardingData, ...stepData })
        if (currentIndex < numberOfSteps) setCurrentIndex(currentIndex + 1)
    }

    const updateSearchParams = update => {
        setSearchParams(params => {
            for (const [key, value] of Object.entries(update)) {
                params.append(key, value)
            }

            return params
        })
    }

    const removeSearchParam = key => {
        setSearchParams(params => {
            params.delete(key)
            return params
        })
    }

    async function sendOtp(handleNext) {
        if (!onboardingData.phoneNumber.number) {
            alert("You can't send without a phone number")
            return false
        }

        try {
            setLoading(true)

            const response = await axiosPublic.post(
                '/business-loan/send-phone-number-otp',
                { phoneNumber: onboardingData.phoneNumber.number },
            )

            setLoading(false)

            if (response.data) {
                setOTPSent(true)
                setID(response.data.otp.id)

                updateSearchParams({ verify: true })
                handleNext && handleNext()
            }
        } catch (err) {
            setLoading(false)

            if (err?.response?.status === 400) {
                toast.error(
                    err?.response?.data?.message
                        ? err.response.data.message
                        : 'Invalid email or email already exists.',
                )
            } else {
                toast.error(err.message)
            }
        }
    }

    async function verify(handleNext) {
        try {
            setLoading(true)

            const payload = {
                businessName: onboardingData.businessName,
                bvn: onboardingData.bvn,
                loanType: onboardingData.loanType,
                phoneNumber: separatePhoneNumberString(
                    onboardingData.phoneNumber.number,
                    onboardingData.phoneNumber.code,
                ),
            }

            const response = await axiosPublic.post('/verify', payload)

            setLoading(false)

            if (response.data) {
                // console.log('ressss', response.data)
                // handleNext && handleNext()
            }
        } catch (err) {
            setLoading(false)

            if (err?.response?.status === 400) {
                toast.error(err?.response?.data?.message)
            } else {
                toast.error(err.message)
            }
        }
    }

    const { isPending: genericsDataPending, data: genericsData } = useQuery({
        queryKey: [
            'applicantRoleEnum',
            'genderEnum',
            'reasonForLoanRequestEnum',
            'yearOfBusinessEnum',
            'businessSectorEnum',
            'businessSizeEnum',
            'creditTimeEnum',
        ],
        queryFn: async () => {
            const response = await axiosPublic(`/generic-apis/all-enum`)

            if (response.data) {
                return response.data
            }
        },
    })

    const onFinish = async data => {
        const { dob, phoneNumber, businessInfo, loanAmount, ...rest } = data
        const month =
            dateDropdownEntries.months.findIndex(
                mon =>
                    mon.toLocaleLowerCase() === dob.month.toLocaleLowerCase(),
            ) + 1
        const payload = {
            ...rest,
            loanAmount: Number(loanAmount),
            phoneNumber: separatePhoneNumberString(
                phoneNumber.number,
                phoneNumber.code,
            ),
            dob: `${dob.year}-${month}-${dob.day}`,
            loanType: 'Personal Loan',
        }

        if (loanType === 'business') {
            payload.loanType = 'Business Loan'
            payload.businessInfo = businessInfo
        }

        try {
            setLoading(true)

            const response = await axiosPublic.post(
                '/business-loan/create',
                payload,
            )

            setLoading(false)

            if (response.data) {
                toast.success('success')
                updateSearchParams({ complete: true })
            }
        } catch (e) {
            setLoading(false)

            toast.error(e?.response?.data?.message)
        }
    }

    const goBack = () => {
        if (currentIndex > 0) setCurrentIndex(currentIndex - 1)
    }

    if (isComplete) {
        return (
            <CloseablePageTemplage>
                <div className="w-full h-full flex items-center justify-center">
                    <ApplicationSucces />
                </div>
            </CloseablePageTemplage>
        )
    }

    if (isVerify && otpSent) {
        return (
            <OTPScreen
                id={id}
                phoneNumber={onboardingData.phoneNumber.number}
                removeSearchParam={removeSearchParam}
            />
        )
    }

    return (
        <CloseablePageTemplage>
            <TitleWrapper>
                <Text
                    textalign="center"
                    weight="medium"
                    type="h2"
                    color="black"
                    fontSize="2rem"
                >
                    {loanType === 'business' ? 'Business' : 'Personal'} Loan
                    Application
                </Text>
            </TitleWrapper>

            <div className="flex items-center mt-4 mb-8 w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5">
                <StepProgressIndicator
                    numberOfSteps={stepCount}
                    currentStep={currentIndex + 1}
                    title={stepTitles[loanType][currentIndex]}
                    type="titled"
                />
            </div>

            <OnboardingFlow
                currentIndex={currentIndex}
                onFinish={onFinish}
                onNext={onNext}
                goBack={goBack}
                getStepCount={setStepCount}
            >
                <PhoneNumberVerification
                    data={onboardingData}
                    setData={setOnboardingData}
                    sendOtp={sendOtp}
                    loading={loading}
                />

                <BvnOrBusinessNameVerification
                    data={onboardingData}
                    setData={setOnboardingData}
                    loading={loading}
                    isBusiness={loanType === 'business'}
                    verify={verify}
                />
                <UserBioDetails
                    data={onboardingData}
                    genericsData={genericsData}
                    setData={setOnboardingData}
                />
                {loanType === 'business' && (
                    <BusinessDetails
                        data={onboardingData}
                        genericsData={genericsData}
                        setData={setOnboardingData}
                    />
                )}

                <LoanRequestDetails
                    data={onboardingData}
                    genericsData={genericsData}
                    setData={setOnboardingData}
                    loading={loading}
                />
            </OnboardingFlow>
        </CloseablePageTemplage>
    )
}

const StepFooter = ({ goToNext, className, goBack, lastStep, loading }) => {
    return (
        <div
            className={`flex justify-between w-full sm:w-2/3 gap-4 rounded pt-8 pb-8 ${
                className || 'lg:w-2/4 xl:w-2/5'
            }`}
        >
            <Button
                onClick={goBack}
                width="fit-content"
                size="md"
                border="1px solid black"
            >
                <Text type="p" weight="medium">
                    Back
                </Text>
            </Button>
            <Button
                loading={loading}
                onClick={() => goToNext({ id: 1 })}
                width="fit-content"
                size="md"
                bgcolor={africredColors.primary.blue[600]}
                className={`hover:bg-[${africredColors.primary.blue[800]}] text-white`}
            >
                <Text type="p" weight="bold" color="#fff" hovercolor="#fff">
                    {lastStep ? 'Make Payment' : 'Continue'}
                </Text>
            </Button>
        </div>
    )
}
function PhoneNumberVerification({
    goToNext,
    data,
    setData,
    sendOtp,
    loading,
}) {
    const [isPhoneValid, setIsPhoneValid] = useState(false)

    return (
        <>
            <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
                <FormInputs>
                    <FormRow>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                Enter your phone
                            </Text>
                            <Input
                                size="md"
                                type="phone"
                                value={data?.phoneNumber.number}
                                onChange={(val, country) =>
                                    setData(prev => ({
                                        ...prev,
                                        phoneNumber: {
                                            code: country.countryCode,
                                            number: val,
                                        },
                                    }))
                                }
                                border="none"
                                setIsPhoneValid={setIsPhoneValid}
                            />
                            <Text
                                type="p"
                                color="#5d5d5d"
                                hovercolor="#5d5d5d"
                                textalign="center"
                                className="mt-2"
                            >
                                A code will be sent to the provided phone number
                            </Text>
                        </div>
                    </FormRow>
                </FormInputs>
            </FormSection>

            <StepFooter
                goBack={() => {}}
                goToNext={async () => {
                    if (isPhoneValid) {
                        await sendOtp(() => goToNext(data))
                    } else {
                        toast.error('Please check phone number')
                    }
                }}
                loading={loading}
                className={'w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5'}
            />
        </>
    )
}

function BvnOrBusinessNameVerification({
    goToNext,
    goBack,
    data,
    isBusiness,
    setData,
    verify,
    loading,
}) {
    return (
        <>
            <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
                <FormInputs>
                    {isBusiness ? (
                        <FormRow>
                            <div>
                                <Text
                                    textalign="left"
                                    type="p"
                                    weight="medium"
                                    margin="0 0 1rem 0"
                                >
                                    Enter your Business Name
                                </Text>
                                <Input
                                    type="text"
                                    size="md"
                                    padding="16px"
                                    placeholder="Enter CAC Business Name"
                                    autoComplete="off"
                                    value={data.businessName}
                                    onChange={e =>
                                        setData(prev => ({
                                            ...prev,
                                            businessName: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                        </FormRow>
                    ) : (
                        <FormRow>
                            <div>
                                <Text
                                    textalign="left"
                                    type="p"
                                    weight="medium"
                                    margin="0 0 1rem 0"
                                >
                                    Enter your BVN
                                </Text>
                                <Input
                                    type="text"
                                    size="md"
                                    padding="16px"
                                    placeholder="Enter BVN"
                                    autoComplete="off"
                                    value={data.bvn}
                                    onChange={e =>
                                        setData(prev => ({
                                            ...prev,
                                            bvn: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                        </FormRow>
                    )}
                </FormInputs>
            </FormSection>

            <StepFooter
                goBack={goBack}
                goToNext={async () => {
                    if (isBusiness && !data.businessName) {
                        toast.error('Please enter a valid Business Name')
                    } else if (!isBVNValid(data.bvn)) {
                        toast.error('Please enter a valid BVN number')
                    } else {
                        await verify(() => goToNext(data))
                    }
                }}
                className={'w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5'}
                loading={loading}
            />
        </>
    )
}

function UserBioDetails({ goToNext, goBack, data, genericsData, setData }) {
    const [showAlert, setShowAlert] = useState(true)

    const handleCloseAlert = () => {
        setShowAlert(false)
    }

    return (
        <>
            <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
                <FormInputs>
                    <FormRow>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                First Name
                            </Text>
                            <Input
                                icon={<Icon type="person" />}
                                type="text"
                                size="md"
                                placeholder="First Name"
                                autoComplete="off"
                                value={data.firstName}
                                onChange={e =>
                                    setData(prev => ({
                                        ...prev,
                                        firstName: e.target.value,
                                    }))
                                }
                            />
                        </div>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                Last Name
                            </Text>
                            <Input
                                icon={<Icon type="person" />}
                                type="text"
                                size="md"
                                placeholder="Last Name"
                                autoComplete="off"
                                value={data.lastName}
                                onChange={e =>
                                    setData(prev => ({
                                        ...prev,
                                        lastName: e.target.value,
                                    }))
                                }
                            />
                        </div>
                    </FormRow>

                    <FormRow>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                Email
                            </Text>
                            <Input
                                icon={<Icon type="email" />}
                                type="email"
                                size="md"
                                placeholder="Email"
                                autoComplete="off"
                                value={data.email}
                                onChange={e =>
                                    setData(prev => ({
                                        ...prev,
                                        email: e.target.value,
                                    }))
                                }
                            />
                        </div>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                Gender
                            </Text>
                            <AntdSelect
                                options={formatSelectOptionForAntd(
                                    genericsData?.genderEnum || [],
                                )}
                                placeholder="Select gender"
                                value={data.gender}
                                setValue={val => {
                                    setData(prev => ({
                                        ...prev,
                                        gender: val,
                                    }))
                                }}
                            />
                        </div>
                    </FormRow>

                    <FormRow>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                Date of Birth
                            </Text>
                            <div className="w-full flex flex-col md:flex-row gap-2">
                                <AntdSelect
                                    options={formatSelectOptionForAntd(
                                        dateDropdownEntries.days,
                                    )}
                                    placeholder="Day"
                                    value={data.dob.day}
                                    setValue={val => {
                                        setData(prev => ({
                                            ...prev,
                                            dob: {
                                                ...prev.dob,
                                                day: val,
                                            },
                                        }))
                                    }}
                                    sortable={false}
                                />
                                <AntdSelect
                                    // showSearch
                                    options={formatSelectOptionForAntd(
                                        dateDropdownEntries.months,
                                    )}
                                    placeholder="Month"
                                    value={data.dob.month}
                                    setValue={val => {
                                        setData(prev => ({
                                            ...prev,
                                            dob: {
                                                ...prev.dob,
                                                month: val,
                                            },
                                        }))
                                    }}
                                    sortable={false}
                                />
                                <AntdSelect
                                    // showSearch
                                    options={formatSelectOptionForAntd(
                                        dateDropdownEntries.years,
                                    )}
                                    placeholder="Year"
                                    value={data.dob.year}
                                    setValue={val => {
                                        setData(prev => ({
                                            ...prev,
                                            dob: {
                                                ...prev.dob,
                                                year: val,
                                            },
                                        }))
                                    }}
                                    sortable={false}
                                />
                            </div>
                        </div>
                    </FormRow>
                </FormInputs>
            </FormSection>

            <StepFooter
                goBack={goBack}
                goToNext={() => {
                    if (
                        data.firstName &&
                        data.lastName &&
                        data.email &&
                        data.gender &&
                        data.dob.day &&
                        data.dob.month &&
                        data.dob.year
                    ) {
                        goToNext(data)
                    } else {
                        toast.error('Please provide all details...')
                    }
                }}
                className={'w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5'}
            />
        </>
    )
}

function BusinessDetails({ goToNext, goBack, data, setData, genericsData }) {
    return (
        <>
            <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
                <FormInputs>
                    <FormRow>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                Business Name
                            </Text>
                            <Input
                                type="text"
                                size="md"
                                padding="16px"
                                placeholder="Enter business name"
                                autoComplete="off"
                                value={data.businessInfo.businessName}
                                onChange={e =>
                                    setData(prev => ({
                                        ...prev,
                                        businessInfo: {
                                            ...prev.businessInfo,
                                            businessName: e.target.value,
                                        },
                                    }))
                                }
                            />
                        </div>
                    </FormRow>
                    <FormRow>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                Business URL or Website (optional)
                            </Text>
                            <Input
                                type="text"
                                size="md"
                                padding="16px"
                                placeholder="Enter business URL"
                                autoComplete="off"
                                value={data.businessInfo.businessUrl}
                                onChange={e =>
                                    setData(prev => ({
                                        ...prev,
                                        businessInfo: {
                                            ...prev.businessInfo,
                                            businessUrl: e.target.value,
                                        },
                                    }))
                                }
                            />
                        </div>
                    </FormRow>

                    <FormRow>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                What&apos;s Your Role
                            </Text>
                            <AntdSelect
                                options={formatSelectOptionForAntd(
                                    genericsData?.applicantRoleEnum || [],
                                )}
                                value={data.businessInfo.applicantRole}
                                setValue={val =>
                                    setData(prev => ({
                                        ...prev,
                                        businessInfo: {
                                            ...prev.businessInfo,
                                            applicantRole: val,
                                        },
                                    }))
                                }
                            />
                        </div>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                Business Size
                            </Text>
                            <AntdSelect
                                showSearch
                                options={formatSelectOptionForAntd(
                                    genericsData?.businessSizeEnum || [],
                                )}
                                value={data.businessInfo.businessSize}
                                setValue={val =>
                                    setData(prev => ({
                                        ...prev,
                                        businessInfo: {
                                            ...prev.businessInfo,
                                            businessSize: val,
                                        },
                                    }))
                                }
                                sortable={false}
                            />
                        </div>
                    </FormRow>

                    <FormRow>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                How Old is Your Business
                            </Text>
                            <AntdSelect
                                showSearch
                                options={formatSelectOptionForAntd(
                                    genericsData?.yearOfBusinessEnum || [],
                                )}
                                value={data.businessInfo.yearOfBusiness}
                                setValue={val =>
                                    setData(prev => ({
                                        ...prev,
                                        businessInfo: {
                                            ...prev.businessInfo,
                                            yearOfBusiness: val,
                                        },
                                    }))
                                }
                            />
                        </div>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                Nature of Business/Industry
                            </Text>
                            <AntdSelect
                                showSearch
                                options={formatSelectOptionForAntd(
                                    genericsData?.businessSectorEnum || [],
                                )}
                                value={data.businessInfo.businessSector}
                                setValue={val =>
                                    setData(prev => ({
                                        ...prev,
                                        businessInfo: {
                                            ...prev.businessInfo,
                                            businessSector: val,
                                        },
                                    }))
                                }
                            />
                        </div>
                    </FormRow>
                </FormInputs>
            </FormSection>

            <StepFooter
                goBack={goBack}
                goToNext={() => {
                    if (
                        data.businessInfo.businessName &&
                        data.businessInfo.applicantRole &&
                        data.businessInfo.businessSize &&
                        data.businessInfo.yearOfBusiness &&
                        data.businessInfo.businessSector
                    ) {
                        goToNext(data)
                    } else {
                        toast.error('Please provide all details...')
                    }

                    goToNext(data)
                }}
                className={'w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5'}
            />
        </>
    )
}

function LoanRequestDetails({
    onFinish,
    goBack,
    data,
    setData,
    genericsData,
    loading,
}) {
    return (
        <>
            <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
                <FormInputs>
                    <FormRow>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                How much do you need?
                            </Text>
                            <Input
                                type="text"
                                size="md"
                                padding="16px"
                                placeholder="Enter amount"
                                autoComplete="off"
                                value={data.loanAmount}
                                onChange={e =>
                                    setData(prev => ({
                                        ...prev,
                                        loanAmount: e.target.value,
                                    }))
                                }
                            />
                        </div>
                    </FormRow>
                    <FormRow>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                What do you need if for?
                            </Text>
                            <AntdSelect
                                options={formatSelectOptionForAntd(
                                    genericsData?.reasonForLoanRequestEnum ||
                                        [],
                                )}
                                value={data.reasonForLoanRequest}
                                setValue={val => {
                                    setData(prev => ({
                                        ...prev,
                                        reasonForLoanRequest: val,
                                    }))
                                }}
                            />
                        </div>
                    </FormRow>

                    <FormRow>
                        <div>
                            <Text
                                textalign="left"
                                type="p"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                When do you want the funding?
                            </Text>
                            <AntdSelect
                                options={formatSelectOptionForAntd(
                                    genericsData?.creditTimeEnum || [],
                                )}
                                value={data.creditedTime}
                                setValue={val => {
                                    setData(prev => ({
                                        ...prev,
                                        creditedTime: val,
                                    }))
                                }}
                            />
                        </div>
                    </FormRow>
                </FormInputs>
            </FormSection>

            <StepFooter
                goBack={goBack}
                goToNext={() => {
                    if (
                        data.loanAmount &&
                        data.reasonForLoanRequest &&
                        data.creditedTime
                    ) {
                        onFinish(data)
                    } else {
                        toast.error('Please provide all details...')
                    }
                }}
                loading={loading}
                className={'w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5'}
            />
        </>
    )
}

export const OTPScreen = ({ id, phoneNumber, removeSearchParam }) => {
    const [otpSuccess, setOTPSuccess] = useState(false)

    return (
        <OTPComponent
            id={id}
            setOTPSuccess={setOTPSuccess}
            phoneNumber={phoneNumber}
            verifyURL="/business-loan/verify-phone-number-otp"
            resendURL="/business-loan/send-phone-number-otp"
            usePublicAxios={true}
            useCase="loan"
            buttonStyles={{
                bgcolor: africredColors.primary.blue[600],
                className: `hover:bg-[${africredColors.primary.blue[800]}] text-white`,
            }}
            onSuccess={() => {
                removeSearchParam('verify')
            }}
        />
    )
}

function ApplicationSucces() {
    const navigate = useNavigate()

    return (
        <>
            <FormSection className="flex flex-col items-between w-full sm:w-4/5 lg:w-4/6 gap-4 rounded">
                <div className="w-full flex flex-col items-center">
                    <Text
                        margin="1rem 0 0 0"
                        textalign="center"
                        type="h2"
                        weight="medium"
                        color="black"
                    >
                        Application submitted Successfully
                    </Text>

                    <Text
                        width="90%"
                        textalign="center"
                        type="p"
                        margin="1rem 0 1.5rem 0"
                        color="#5d5d5d"
                        hovercolor="#5d5d5d"
                    >
                        Congratulations on taking the first step towards
                        realizing your business goals! By submitting your loan
                        form, you're opening doors to new opportunities and
                        growth. Our team will reach out to you shortly!
                    </Text>

                    <div className="flex flex-wrap items-center justify-center w-full gap-4">
                        {[
                            access,
                            fcmb,
                            // future_finance,
                            gtb,
                            lendwise,
                            polaris,
                            power,
                            // prodigy,
                            stilt,
                        ].map((item, idx) => (
                            <img
                                key={idx}
                                src={item}
                                alt={`partner-${idx}`}
                                className="w-14 h-8"
                            />
                        ))}
                    </div>

                    <Button
                        onClick={() => {
                            navigate('/')
                        }}
                        width="fit-content"
                        size="md"
                        bgcolor={africredColors.primary.blue[600]}
                        className={`hover:bg-[${africredColors.primary.blue[800]}] mt-10`}
                    >
                        <Text
                            type="p"
                            weight="bold"
                            color="#fff"
                            hovercolor="#fff"
                        >
                            Close
                        </Text>
                    </Button>
                </div>
            </FormSection>
        </>
    )
}
