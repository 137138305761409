import styled from "styled-components";
import { Loader } from ".";

const Wrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
export default function LoaderContainer () {
  return <Wrapper>
    <Loader type="circle" />
  </Wrapper>
}