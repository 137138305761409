import React from "react";
import styled from "styled-components";
import { Modal } from "../../";
import { HorizontalLine, Icon, Text } from "../../../atoms";
import { screen } from "../../../../theme";
import profileImage from "../../../../assets/pngs/profile-avatar.png";

const Wrapper = styled.div`
  background-color: white;
  height: 100%;
  width: 576px;
  padding: 14px;
  position: relative;
  overflow-y: auto;

  @media only screen and (${screen.md}) {
    padding: 14px 32px;
  }
`;
const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  right: 36px;
  top: 36px;
`;
const InfoWrapper = styled.div`
  padding: 16px 24px;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid #d1d1d1;
`;
const ProfileImageWrapper = styled.img`
  width: 76px;
  height: 76px;
  padding: 0.2rem;
  border-radius: 50%;
`;

export const RequestDetailsModal = ({ open, setOpen, data }) => {
  const { account, issuedBy, amount } = data;

  return (
    <Modal isOpen={open} positionX="end">
      <Wrapper>
        <div display="flex w-full">
          <Text
            className="w-5/6 mt-3"
            type="h3"
            textWrap="wrap"
            weight="medium"
          >
            Details
          </Text>

          <div className="flex flex-col gap-6 mt-6">
            <InfoWrapper>
              <div className="flex gap-4 items-center">
                <ProfileImageWrapper
                  src={issuedBy.avatar || profileImage}
                  alt="profile"
                />
                <div className="flex flex-col gap-2">
                  <Text type="h4">{issuedBy.name}</Text>
                  <div className="flex gap-2">
                    <Text type="p">{`${account.phoneNumber.code}${account.phoneNumber.number}`}</Text>
                    <Text type="p">{account.email}</Text>
                  </div>
                  <Text type="p">Transaction amount: {amount}</Text>
                </div>
              </div>
            </InfoWrapper>

            <HorizontalLine />

            <div className="flex flex-col gap-4">
              <Text type="p" weight="bold">
                Personal details
              </Text>
              <div className="grid grid-cols-2 gap-4">
                <InfoWrapper>
                  <Text type="h5">Gender</Text>
                  <Text type="p">{account.gender}</Text>
                </InfoWrapper>
                <InfoWrapper>
                  <Text type="h5">Marital status</Text>
                  <Text type="p">Single</Text>
                </InfoWrapper>
                <InfoWrapper>
                  <Text type="h5">City</Text>
                  <Text type="p">{account.address.city}</Text>
                </InfoWrapper>
                <InfoWrapper>
                  <Text type="h5">Country</Text>
                  <Text type="p">{account.address.country}</Text>
                </InfoWrapper>
              </div>
            </div>

            <HorizontalLine />

            <div className="flex flex-col gap-4">
              <Text type="p" weight="bold">
                Application details
              </Text>
              <div className="grid grid-cols-2 gap-4">
                <InfoWrapper>
                  <Text type="h5">City of residence</Text>
                  <Text type="p">
                    {data.transactionType === "Admission"
                      ? data.admission.cityOfResidence
                      : account.address.city}
                  </Text>
                </InfoWrapper>
                <InfoWrapper>
                  <Text type="h5">Payment Status</Text>
                  <Text type="p">{data.paymentStatus}</Text>
                </InfoWrapper>
                <InfoWrapper>
                  <Text type="h5">Amount</Text>
                  <Text type="p">{amount}</Text>
                </InfoWrapper>
                <InfoWrapper>
                  <Text type="h5">Transaction type</Text>
                  <Text type="p">{data.transactionType}</Text>
                </InfoWrapper>
                <InfoWrapper>
                  <Text type="h5">Purpose</Text>
                  <Text type="p">{data.purpose}</Text>
                </InfoWrapper>
                <InfoWrapper>
                  <Text type="h5">Proof of ID</Text>
                  <Text type="p">International Passport</Text>
                </InfoWrapper>
              </div>
            </div>

            <HorizontalLine />
          </div>
        </div>
      </Wrapper>
      <CloseButton onClick={() => setOpen(false)}>
        <Icon type="close" />
      </CloseButton>
    </Modal>
  );
};
