// import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useAuth from "./useAuth";
import { axiosPrivate } from "../api/axios";
import useRefreshToken from "./useRefreshToken";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.accessToken}`;
        }
        return config;
      },
      (error) => {
        if (error?.message === "Network Error") {
          toast.error("Please check your internet connection", {
            toastId: "network-error-toast",
          });
          return Promise.reject(new Error(error.message));
        }
        return Promise.reject(error);
      }
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;

        if (error?.response?.status === 456) {
          navigate("/dashboard", { replace: true });
          return Promise.reject(error);
        }

        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth, navigate, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
