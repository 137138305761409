import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import {
    AccessDenied,
    AdmissionForm,
    DashboardPage,
    ForgotPassword,
    Home,
    KYCSettings,
    LoanApplicationForm,
    Loans,
    Login,
    LoginAndSecuritySettings,
    Notifications,
    OnboardStudent,
    PageNotFound,
    PaymentSuccessURL,
    PreferenceSettings,
    ProfileSettingsPage,
    ProofOfFunds,
    ProofOfFundsApplicationForm,
    Remittance,
    ResetPassword,
    Signup,
    Transactions,
    Universities,
    SmeLoanForm,
    SmeHome,
    UniversityDetails,
    WidthdrawalRequest,
    Earnings,
    AgentLogin,
    StudentLogin,
} from './components/pages'
import { DashboardTemplate } from './components/templates/DashboardTemplate'
import { AnimatePresence } from 'framer-motion'
import { Support } from './components/pages/Support'
import RequireAuth from './components/utils/RequireAuth'
import { UpdateProfileContact } from './components/pages/UpdateProfileContact'
import { ErrorBoundary } from './components/organisms'

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <RequireAuth path={'/student-login'}>
                <DashboardTemplate />
            </RequireAuth>
        ),
        children: [
            {
                path: '/dashboard',
                element: <DashboardPage />,
            },
            {
                path: '/agent-dashboard',
                element: <DashboardPage />,
            },
            {
                path: '/loans',
                element: <Loans />,
            },
            {
                path: '/earnings',
                element: <Earnings />,
            },
            {
                path: '/transactions',
                element: <Transactions />,
            },
            {
                path: '/proof-of-funds',
                element: <ProofOfFunds />,
            },
            {
                path: '/settings/profile',
                element: <ProfileSettingsPage />,
            },
            {
                path: '/settings/kyc',
                element: <KYCSettings />,
            },
            {
                path: '/settings/login-security',
                element: <LoginAndSecuritySettings />,
            },
            {
                path: '/settings/preference',
                element: <PreferenceSettings />,
            },
        ],
        errorElement: <ErrorBoundary />,
    },
    {
        path: '/onboard-student',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth path={'/student-login'}>
                <OnboardStudent />
            </RequireAuth>
        ),
    },
    {
        path: '/',
        index: true,
        errorElement: <ErrorBoundary />,
        element: <Home />,
    },
    {
        path: '/apply-for-admission',
        index: true,
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth path={'/student-login'}>
                <AdmissionForm />
            </RequireAuth>
        ),
    },
    {
        path: '/universities',
        errorElement: <ErrorBoundary />,
        element: <Universities />,
    },
    {
        path: '/universities/:id',
        errorElement: <ErrorBoundary />,
        element: <UniversityDetails />,
    },
    {
        path: '/update-contact/:type',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth path={'/student-login'}>
                <UpdateProfileContact />
            </RequireAuth>
        ),
    },
    {
        path: '/remittance',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth path={'/student-login'}>
                <Remittance />
            </RequireAuth>
        ),
    },
    {
        path: '/apply-for-loan',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth path={'/student-login'}>
                <LoanApplicationForm />
            </RequireAuth>
        ),
    },
    {
        path: '/sme-loans',
        errorElement: <ErrorBoundary />,
        element: <SmeHome />,
    },
    {
        path: '/sme-loans/apply',
        errorElement: <ErrorBoundary />,
        element: <SmeLoanForm />,
    },
    {
        path: '/payment-success',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth path={'/student-login'}>
                <PaymentSuccessURL />
            </RequireAuth>
        ),
    },
    {
        path: '/apply-for-proof-of-funds',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth path={'/student-login'}>
                <ProofOfFundsApplicationForm />
            </RequireAuth>
        ),
    },
    {
        path: '/login',
        errorElement: <ErrorBoundary />,
        element: <Login />,
    },
    {
        path: '/signup',
        errorElement: <ErrorBoundary />,
        element: <Signup />,
    },
    {
        path: '/forgot-password',
        errorElement: <ErrorBoundary />,
        element: <ForgotPassword />,
    },
    {
        path: '/reset-password',
        errorElement: <ErrorBoundary />,
        element: <ResetPassword />,
    },
    {
        path: '/notifications',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth path={'/student-login'}>
                <Notifications />
            </RequireAuth>
        ),
    },
    {
        path: '/support',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth path={'/student-login'}>
                <Support />
            </RequireAuth>
        ),
    },
    {
        path: '/unauthorized',
        errorElement: <ErrorBoundary />,
        element: <AccessDenied />,
    },
    {
        path: '*',
        errorElement: <ErrorBoundary />,
        element: <PageNotFound />,
    },

    {
        path: '/widthdrawal-request',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth path={'/agent-login'}>
                <WidthdrawalRequest />
            </RequireAuth>
        ),
    },
    {
        path: '/widthdrawal-request',
        errorElement: <ErrorBoundary />,
        element: (
            <RequireAuth path={'/agent-login'}>
                <WidthdrawalRequest />
            </RequireAuth>
        ),
    },
    {
        path: '/agent-login',
        errorElement: <ErrorBoundary />,
        element: <AgentLogin />,
    },
    {
        path: '/student-login',
        errorElement: <ErrorBoundary />,
        element: <StudentLogin />,
    },
])

const App = () => {
    return (
        <AnimatePresence mode="wait">
            <RouterProvider router={router} />
        </AnimatePresence>
    )
}

export default App
