import React, { useState } from 'react'
import { css, styled } from 'styled-components'
import { Icon, Text } from '../../atoms'
import { fetchFlag } from '../InputWithAddonSelect'
import { africredColors } from '../../../theme/colors'
import { screen } from '../../../theme'
import { formatNumber } from '../../../utils'
import { currencyFlag } from '../../../constants'

const styles = css`
    width: ${({ width }) => width || '100%'};
    background-color: ${({ bgcolor }) =>
        bgcolor || africredColors.neutrals.night['80']};
    border-radius: ${({ borderRadius }) => borderRadius || '5rem'};
    gap: 1rem;
    border: ${({ border }) => border};
    padding: ${({ padding, size }) => {
        if (padding) return padding
        return size === 'lg' || size === 'md'
            ? '16px 48px'
            : size === 'sm'
            ? '12px 40px'
            : '8px 32px'
    }};
    height: ${({ height, size }) => {
        if (height) return height
        return size === 'lg'
            ? '64px'
            : size === 'md'
            ? '56px'
            : size === 'sm'
            ? '48px'
            : '40px'
    }};

    &:focus {
        outline: 0.5px solid ${africredColors.neutrals.night[800]};
    }
`
const PriceSummaryWrapper = styled.div`
    display: flex;
    background-color: ${africredColors.neutrals.night['80']};
    padding: 18px 16px;
    align-self: stretch;
    border-radius: 32px;
    border: 1px solid #d1d1d1;
    flex-direction: column;
    gap: 12px;

    @media only screen and (${screen.sm}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        /* min-height: 80px; */
    }
`

const Div = styled.div`
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
`

export const CountryDropdown = ({
    currencyToPay,
    setData,
    currencyToPayIcon,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleDropDown = () => {
        setIsOpen(!isOpen)
    }
    return (
        <PriceSummaryWrapper onClick={() => toggleDropDown()}>
          {/* <div className="flex flex-col gap-2 items-start sm:items-end relative"> */}
              <div
                  className="flex flex-1 gap-2 items-center justify-between relative"
              >
                <div className='flex gap-2 items-center justify-start'>
                  <img src={currencyToPayIcon} alt="flag" />
                  <Text
                      type="p"
                      weight="medium"
                      color={africredColors.neutrals.night[600]}
                  >
                      {currencyToPay}
                  </Text>
                </div>
                  {isOpen ? (
                      <Icon type="up-caret-light" />
                  ) : (
                      <Icon type="down-caret-light" />
                  )}
                  {isOpen && (
                      <Div
                          className="absolute w-full top-full z-50 bg-white flex flex-col p-3 h-fit rounded right-0"
                          style={{ bottom: '5px', right: '2px' }}
                      >
                          {currencyFlag.map((item, idx) => {
                              return (
                                  <div
                                      className="flex flex-1 gap-4 cursor-pointer p-2"
                                      key={idx}
                                      onClick={e => {
                                          setData(item)
                                      }}
                                  >
                                      <img src={item.icon} alt="flag" />
                                      <Text
                                          type="p"
                                          weight="medium"
                                          color={
                                              africredColors.neutrals
                                                  .night[600]
                                          }
                                      >
                                          {item.value}
                                      </Text>
                                  </div>
                              )
                          })}
                      </Div>
                  )}
              </div>
          {/* </div> */}
        </PriceSummaryWrapper>
    )
}
