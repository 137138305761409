import React from "react";
import useAuth from "../../../hooks/useAuth";
import { ProfileSettings } from "../ProfileSettings";
import { AgentProfileSettings } from "../AgentProfileSettings";

export const ProfileSettingsPage = () => {
  const auth = useAuth();

  return (
    <div>
      {auth.accountType !== "Agent" ? (
        <ProfileSettings />
      ) : (
        <AgentProfileSettings />
      )}
    </div>
  );
};
