import React, { useEffect, useState } from 'react'
import { Text } from '../../atoms/Text'
import styled from 'styled-components'
import { africredColors } from '../../../theme/colors'
import { Table } from '../../molecules'
import { Link, useSearchParams } from 'react-router-dom'
import profileImage from '../../../assets/pngs/dp.png'
import {
    camelCaseToSeparateWords,
    formatNumber,
    pageAnimationConfig,
} from '../../../utils'
import { motion } from 'framer-motion'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { screen } from '../../../theme'
import { TableFilter } from '../../organisms'
import { format } from 'date-fns'
import useAuth from '../../../hooks/useAuth'
import { NoRecordFound } from '../../molecules/NoRecordFound'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { RequestDetailsModal } from '../../molecules/Modal/RequestDetailsModal'
import { getLabelBgColor, getLabelColor } from '../Loans'
import { useQuery } from '@tanstack/react-query'
import LoaderContainer from '../../atoms/Loader/LoaderContainer'
import { toast } from 'react-toastify'

const Wrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
const ContentWrapper = styled.div`
    width: 100%;
    max-height: calc(84vh - 94px);
    border-radius: 32px;
    background: ${africredColors.neutrals.white[10]};
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media only screen and (${screen.md}) {
        padding: 24px;
    }
`
const TableWrapper = styled.div`
    width: 100%;
    overflow: auto;
    max-height: calc(75vh - 94px);
`
export const ClickAbleLabel = styled(Link)`
    background-color: ${({ backgroundcolor }) => backgroundcolor};
    color: ${({ color }) => color};
    display: flex;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 48px;
    min-width: 143px;
`
const Flex = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`

function renderHeader(params) {
    return (
        <Text type="p" weight="medium">
            {params.colDef.headerName}
        </Text>
    )
}

export const Transactions = () => {
    const auth = useAuth()
    const axiosPrivate = useAxiosPrivate()
    const [transactionDetails, setTransactionDetails] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [loadingFetch, setLoadingFetch] = useState(false)
    const [params] = useSearchParams()
    const searchedId = params.get('id')

    const [filterData, setFilterData] = useState({
        searchTerm: '',
        sort: '',
    })
    const {
        isPending,
        error,
        data: transactions,
    } = useQuery({
        queryKey: ['transactions'],
        queryFn: async () => {
            const response = await axiosPrivate(
                `/transaction/search?account[0]=${auth.id}`,
            )

            if (response.data) {
                return response.data.foundTransactions.map(item => {
                    return {
                        ...item,
                        id: item.id,
                        referenceNumber: item.referenceNumber,
                        issuedBy: {
                            name: `${item?.account?.firstName} ${item?.account?.lastName}`,
                            avatar: item.account.profilePicture?.Location,
                        },
                        amount: `${formatNumber(item.amount)}`,
                        purpose: item.purpose,
                        transactionType: item.transactionType,
                        date: format(new Date(item.createdAt), 'do MMM, yyyy'),
                        status: item.paymentStatus,
                    }
                })
            }

            return response.data
        },
    })

    useEffect(() => {
        if (searchedId) {
            loadSearchedData(searchedId)
        }
    }, [searchedId])

    async function loadSearchedData(id) {
        setLoadingFetch(true)
        try {
            const response = await axiosPrivate(
                `/transaction/search?account[0]=${auth.id}&id[0]=${searchedId}`,
            )

            if (response.data && response.data.foundTransactions?.length) {
                const item = response.data.foundTransactions[0]
                setTransactionDetails({
                    ...item,
                    id: item.id,
                    referenceNumber: item.referenceNumber,
                    issuedBy: {
                        name: `${item?.account?.firstName} ${item?.account?.lastName}`,
                        avatar: item.account.profilePicture?.Location,
                    },
                    amount: `${formatNumber(item.amount)}`,
                    purpose: item.purpose,
                    transactionType: item.transactionType,
                    date: format(new Date(item.createdAt), 'do MMM, yyyy'),
                    status: item.paymentStatus,
                })
                setOpenModal(true)
            }
        } catch (err) {
            toast.error(err.response?.data?.message || 'Something went wrong')
        }
        setLoadingFetch(false)
    }

    if (isPending) return <LoaderContainer />

    if (loadingFetch) return <LoaderContainer />

    if (error) return 'An error has occurred: ' + error.message

    const columns = [
        {
            field: 'referenceNumber',
            headerName: 'Transaction Id',
            minWidth: 160,
            flex: 2,
            renderHeader,
        },
        {
            field: 'issuedBy',
            headerName: 'Issued by',
            minWidth: 200,
            flex: 2,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Flex>
                        <img
                            style={{
                                width: '35px',
                                height: '35px',
                                borderRadius: '50%',
                            }}
                            src={value.avatar || profileImage}
                            alt={`profile${value}`}
                        />
                        <Text type="p">
                            {value.name?.substring(0, 25)}{' '}
                            {value.name.length > 25 ? '...' : ''}
                        </Text>
                    </Flex>
                )
            },
        },
        {
            field: 'amount',
            headerName: 'Amount',
            minWidth: 140,
            flex: 1,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Text type="p">
                        {row.currency} {value}
                    </Text>
                )
            },
        },
        {
            field: 'purpose',
            headerName: 'Purpose',
            minWidth: 110,
            flex: 2,
            renderHeader,
        },
        {
            field: 'transactionType',
            headerName: 'Type',
            minWidth: 160,
            flex: 1.5,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <ClickAbleLabel
                        backgroundcolor={getLabelBgColor(value)}
                        color={getLabelColor(value)}
                    >
                        {camelCaseToSeparateWords(value)}
                    </ClickAbleLabel>
                )
            },
        },
        {
            field: 'date',
            headerName: 'Date',
            minWidth: 110,
            flex: 1.5,
            renderHeader,
        },
        {
            field: 'status',
            headerName: 'Status',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            renderHeader,
            renderCell: ({ value, row }) => {
                return (
                    <Text type="p" color={africredColors.primary.gold[700]}>
                        {value}
                    </Text>
                )
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            // width: 80,
            renderHeader,

            getActions: params => [
                ...[
                    <GridActionsCellItem
                        label="View Details"
                        showInMenu
                        onClick={async () => {
                            setTransactionDetails(params.row)
                            setOpenModal(true)
                        }}
                    />,
                ],
            ],
        },
    ]

    return (
        <Wrapper {...pageAnimationConfig}>
            <div>
                <Text type="h3" weight="medium">
                    Transactions
                </Text>
                <Text type="p" weight="normal">
                    Total transactions
                </Text>
            </div>
            {transactions?.length > 0 ? (
                <ContentWrapper>
                    <div className="flex justify-between gap-y-2 items-center flex-wrap lg:flex-nowrap">
                        <Text type="h5" weight="medium">
                            Latest transactions
                        </Text>
                        <TableFilter
                            filterData={filterData}
                            setFilterData={setFilterData}
                        />
                    </div>

                    <TableWrapper>
                        <Table
                            columns={columns}
                            rows={transactions
                                .sort(function (a, b) {
                                    if (filterData.sort.toLowerCase() === 'asc')
                                        return (
                                            new Date(b.date) - new Date(a.date)
                                        )
                                    return new Date(a.date) - new Date(b.date)
                                })
                                .filter(
                                    item =>
                                        item.amount.includes(
                                            filterData.searchTerm,
                                        ) ||
                                        item.referenceNumber
                                            .toLowerCase()
                                            .includes(
                                                filterData.searchTerm?.toLowerCase(),
                                            ) ||
                                        item.issuedBy.name
                                            .toLowerCase()
                                            .includes(
                                                filterData.searchTerm?.toLowerCase(),
                                            ) ||
                                        item.purpose
                                            .toLowerCase()
                                            .includes(
                                                filterData.searchTerm?.toLowerCase(),
                                            ),
                                )}
                            pageSize="8"
                        />
                    </TableWrapper>

                    {transactionDetails && (
                        <RequestDetailsModal
                            title="Details"
                            open={openModal}
                            setOpen={setOpenModal}
                            data={transactionDetails}
                        />
                    )}
                </ContentWrapper>
            ) : (
                <NoRecordFound />
            )}
        </Wrapper>
    )
}
