import React from "react";
import { africredColors } from "../../../theme/colors";
import styled from "styled-components";
import { Icon, Text } from "../../atoms";
import { screen } from "../../../theme";

const StepIndicatorCircle = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
  z-index: 1;
  align-items: center;

  svg {
    z-index: 2;
  }

  &:not(:last-child)::after {
    content: "";
    width: 45px;
    height: 2px;
    top: 15px;
    background: ${({ linebackground }) => linebackground};
    z-index: -1;
  }

  &::before {
    content: "";
    position: absolute;
    flex-grow: 2;
    min-width: 40px;
    height: 40px;
    top: -12px;
    left: -12px;
    background: red;
    border-radius: 50%;
    background: ${({ background }) => background};
  }

  @media only screen and (${screen.md}) {
    &:not(:last-child)::after {
      width: 100px;
    }
  }
`;

export const StepProgressIndicator = ({ numberOfSteps, currentStep, title, type }) => {
  if (type === "titled") {
    return <div className="w-full flex items-center justify-between">
      <div className="flex flex-col gap-2">
        <Text type="h5" weight="medium">
          Step {currentStep} of {numberOfSteps}
        </Text>
        <Text type="p" color="#5D5D5D" hovercolor="#5D5D5D">
          {title}
        </Text>
      </div>

      <div className="flex gap-2">
        {
          Array(numberOfSteps)
            .fill()
            .map((_, idx) => {
              return (
                <div key={idx} className={`rounded-full p-2 bg-[${africredColors.primary.blue[100]}]`}>
                  {
                    currentStep === idx + 1 ? <Icon
                      type="checked-circle"
                      stroke={africredColors.primary.blue[600]}
                    /> : <div className="rounded-full h-4 w-4 border-2 border-blue-200" />
                  }
                </div>
              );
            })
        }
      </div>
    </div>;
  }

  return Array(numberOfSteps)
    .fill()
    .map((_, idx) => {
      return (
        <StepIndicatorCircle
          key={idx}
          linebackground={
            currentStep > idx + 1
              ? africredColors.primary.green[600]
              : africredColors.neutrals.night[300]
          }
          background={
            currentStep >= idx + 1
              ? africredColors.primary.green[50]
              : africredColors.neutrals.night[100]
          }
        >
          <Icon
            type="checked-circle"
            stroke={
              currentStep >= idx + 1
                ? africredColors.primary.green[600]
                : africredColors.neutrals.night[300]
            }
          />
        </StepIndicatorCircle>
      );
    });
};
