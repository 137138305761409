import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Icon, Text } from '../../atoms'
import { africredColors } from '../../../theme/colors'
import { useDetectOutsideClick } from '../../../hooks/useDetectOutsideClick'
import { formatNumber } from '../../../utils'
const ngnFlag = require('../../../assets/pngs/ngn-flag.png')
const usaFlag = require('../../../assets/pngs/usa-flag.png')

const Wrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    padding: ${({ padding }) => padding || '18px 16px'};
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: ${({ borderradius }) => borderradius || '16px'};
    background-color: ${({ bgcolor }) =>
        bgcolor || africredColors.neutrals.night['100']};
    border: ${({ bordercolor }) => `1px solid ${bordercolor}`};
`
const Input = styled.input`
    border: none;
    background: transparent;
    display: flex;
    justify-content: flex-end;
    width: 72px;

    &:focus {
        border: none;
        outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type='number'] {
        -moz-appearance: textfield;
    }
`
const DropdownContent = styled.div`
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 9;
    border-radius: 16px;
    background-color: ${africredColors.neutrals.white['50']};
    width: 100%;
    padding: 16px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);

    > ul > li {
        width: 100%;
    }

    > ul > li {
        padding: 8px 0;
    }
`

export function fetchFlag(currency) {
    if (!currency) return null
    if (currency.toLowerCase() === 'ngn') {
        return ngnFlag
    }
    if (currency.toLowerCase() === 'usd') {
        return usaFlag
    }
    return null
}

const AddonSelectDropdown = ({ data, handleOptionClick, setMenuActive }) => {
    const ref = useRef(null)
    useDetectOutsideClick(ref, () => setMenuActive(false))

    return (
        <DropdownContent ref={ref}>
            <ul>
                {data.map((item, idx) => (
                    <li
                        key={idx}
                        tabIndex={0}
                        onKeyUp={e => {
                            if (e.key === 'Enter') {
                                handleOptionClick(idx)
                            }
                        }}
                        onClick={() => handleOptionClick(idx)}
                        className="flex gap-4 items-center"
                    >
                        <img src={data[idx]?.icon} alt="flag" />
                        <Text type="p">{data[idx]?.value}</Text>
                    </li>
                ))}
            </ul>
        </DropdownContent>
    )
}

export const InputWithAddonSelect = ({
    inputLabel,
    inputValue,
    setInputValue,
    extraText,
    background,
    bordercolor,
    inputID,
    inputType,
    padding,
    selectMenuData,
    placeholder,
    borderradius,
    dir,
    open=true,
    disableSelect,
    disableSelectInput,
    selectedMenuOption,
    setSelectedMenuOption,
    amountToRemitInCurrency,
    currencyToPay,
    isRemittance = false,
}) => {
    const [selectedDataIndex, setSelectedDataIndex] = useState(0)
    const [openDrowdown, setOpenDropdown] = useState(false)
    const inputRef = useRef(null)

    function getPrevSelectItem() {
        if (selectMenuData[selectedDataIndex - 1]) {
            setSelectedMenuOption(selectMenuData?.[selectedDataIndex - 1])
            return setSelectedDataIndex(prev => prev - 1)
        }
    }

    function getNextSelectItem() {
        if (selectMenuData[selectedDataIndex + 1]) {
            setSelectedMenuOption(selectMenuData?.[selectedDataIndex + 1])
            return setSelectedDataIndex(prev => prev + 1)
        }
    }

    function handleClick(e) {
        setOpenDropdown(prev => !prev)
    }

    function handleOptionClick(index) {
        setSelectedDataIndex(index)
        setSelectedMenuOption(selectMenuData?.[index])
        setOpenDropdown(false)
    }

    if (selectMenuData?.length <= 0) return null

    return (
        <>
            <Wrapper
                htmlFor={inputID}
                bordercolor={bordercolor}
                padding={padding}
                onClick={() => inputRef.current.focus()}
                bgcolor={background}
                borderradius={borderradius}
            >
                <div className="flex gap-2 min-w-[90px] mr-3">
                    <div className="flex items-center">
                        <button
                            onKeyUp={
                                disableSelect
                                    ? () => {}
                                    : e => {
                                          if (e.key === 'ArrowDown') {
                                              setOpenDropdown(true)
                                          }
                                          if (e.key === 'ArrowUp') {
                                              setOpenDropdown(false)
                                          }
                                      }
                            }
                            onClick={disableSelect ? () => {} : handleClick}
                            className="flex gap-2 items-center w-fit"
                        >
                            <img
                                src={selectMenuData[selectedDataIndex]?.icon}
                                alt="flag"
                            />
                            {(inputType === 'tel' ||
                                inputType === 'number') && (
                                <Text className="w-fit" type="p">
                                    {selectMenuData[selectedDataIndex]?.value}
                                </Text>
                            )}
                        </button>
                    </div>
                    {/* {!disableSelect && (
            <div className="flex flex-col items-center justify-between gap-1">
              <Icon
                type="up-caret-light"
                tabIndex={0}
                onClick={getPrevSelectItem}
                onKeyUp={(e) => {
                  if (e.key === "Enter") getPrevSelectItem();
                }}
              />
              <Icon
                type="down-caret-light"
                tabIndex={0}
                onKeyUp={(e) => {
                  if (e.key === "Enter") getNextSelectItem();
                }}
                onClick={getNextSelectItem}
              />
            </div>
          )} */}
                </div>

                <div className="flex flex-col items-end">
                    {inputLabel && (
                        <Text type="small" textalign="right">
                            {inputLabel}
                        </Text>
                    )}
                    <Input
                        id={inputID}
                        type={inputType}
                        onChange={setInputValue}
                        value={inputValue}
                        placeholder={placeholder}
                        ref={inputRef}
                        dir={dir || 'ltr'}
                        disabled={disableSelectInput ? true : false}
                    />
                </div>

                {open && openDrowdown && (
                    <AddonSelectDropdown
                        data={selectMenuData}
                        handleOptionClick={handleOptionClick}
                        setMenuActive={setOpenDropdown}
                    />
                )}
            </Wrapper>

            {extraText && (
                <Text margin="-8px 0 0 0" textalign="left" type="p">
                    Default currency: USD
                </Text>
            )}
            {/* {isRemittance &&(
        <div className="w-full flex justify-end">
           <Text style={{textAlign:'right'}}>{`${currencyToPay} ${formatNumber(amountToRemitInCurrency)}`}</Text>
        </div>
      )} */}
        </>
    )
}
