import React from "react";
import logo from "../../../assets/svgs/full_logo.svg";
import { Button, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import { Link, useRouteError } from "react-router-dom";

export const ErrorBoundary = () => {
  let error = useRouteError();
  console.error(error);
  return (
    <div className="flex h-[95vh] justify-center items-center">
      <div className="flex flex-col gap-4 items-center">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
        <Text
          className="w-5/6 mt-4"
          type="h3"
          textWrap="wrap"
          weight="medium"
          textalign="center"
        >
          Oops! something went wrong
        </Text>
        <Text className="w-5/6" type="p" textalign="center" textWrap="wrap">
          Uh-oh! It looks like we've hit a bump in the road, why not take a
          breather and click the try again button below to
        </Text>
        <div className="flex justify-center mt-4">
          <Button
            onClick={() => window.location.reload()}
            width="300px"
            size="md"
            border={`1px solid ${africredColors.neutrals.night[500]}`}
            color="white"
          >
            Try again
          </Button>
        </div>
      </div>
    </div>
  );
};
