import React, { useState } from "react";
import { Text } from "../../atoms/Text";
import styled from "styled-components";
import { africredColors } from "../../../theme/colors";
import { Button, Icon, Input } from "../../atoms";
import { Table } from "../../molecules";
import profileImage from "../../../assets/pngs/dp.png";
import { Link } from "react-router-dom";
import {
  camelCaseToSeparateWords,
  formatNumber,
  pageAnimationConfig,
} from "../../../utils";
import { motion } from "framer-motion";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { screen } from "../../../theme";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { RequestProgressIndicator, TableFilter } from "../../organisms";
import { RequestInfoModal } from "../../molecules/Modal/RequestInfoModal";
import { getLabelBgColor, getLabelColor } from "../Loans";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { ClickAbleLabel } from "../Transactions";
import { format } from "date-fns";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import { NoRecordFound } from "../../molecules/NoRecordFound";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../atoms/Loader";
import LoaderContainer from "../../atoms/Loader/LoaderContainer";

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const ContentWrapper = styled.div`
  width: 100%;
  max-height: calc(84vh - 94px);
  border-radius: 32px;
  background: ${africredColors.neutrals.white[10]};
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media only screen and (${screen.md}) {
    padding: 24px;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
  max-height: calc(75vh - 94px);
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

function renderHeader(params) {
  return (
    <Text type="p" weight="medium">
      {params.colDef.headerName}
    </Text>
  );
}

export const ProofOfFunds = () => {
  const auth = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { isMobile } = useScreenSize();
  const [openModal, setOpenModal] = useState(false);
  const [openCanelModal, setOpenCancelModal] = useState(false);
  const [progressData, setProgressData] = useState(null);
  const [cancellationReason, setCancellationReason] = useState("");
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    sort: "",
  });
  const {
    isPending,
    error,
    data: proofOfFunds,
    refetch,
  } = useQuery({
    queryKey: ["pofs"],
    queryFn: async () => {
      const response = await axiosPrivate(`/pof/search?account[0]=${auth.id}`);

      if (response.data) {
        return response.data.foundPofs.map((item) => ({
          id: item.id,
          referenceNumber: item.referenceNumber || "...",
          issuedBy: {
            name: `${item.firstName} ${item.lastName}`,
            avatar: item.account.profilePicture?.Location,
          },
          amount: item.amount,
          purpose: item.purpose,
          date: format(new Date(item.createdAt), "dd MMM, yyyy HH:MM"),
          status: item.status,
          approvalStages: {
            inReviewStage: item.inReviewStage,
            hasEoiStage: item.hasEoiStage,
            loggedInStage: item.loggedInStage,
            approvedStage: item.approvedStage,
            disburseStage: item.disburseStage,
          },
          currency: item.currency,
        }));
      }
      return response.data;
    },
  });

  if (isPending) return <LoaderContainer />;

  if (error) return "An error has occurred: " + error.message;

  const columns = [
    {
      field: "referenceNumber",
      headerName: "Reference ID",
      minWidth: 160,
      flex: 1.5,
      renderHeader,
    },
    {
      field: "issuedBy",
      headerName: "Issued by",
      minWidth: 200,
      flex: 1.5,
      renderHeader,
      renderCell: ({ value, row }) => {
        return (
          <Flex>
            <img
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              src={value.avatar || profileImage}
              alt={`profile${value}`}
            />
            <Text type="p">
              {value.name?.substring(0, 25)}{" "}
              {value.name.length > 25 ? "..." : ""}
            </Text>
          </Flex>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 120,
      renderHeader,
    },
    {
      field: "currency",
      headerName: "Currency",
      minWidth: 100,
      renderHeader,
    },
    {
      field: "purpose",
      headerName: "Purpose",
      minWidth: 160,
      flex: 2.5,
      renderHeader,
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 110,
      flex: 1.5,
      renderHeader,
    },
    {
      field: "status",
      headerName: "Status",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      minWidth: 200,
      renderHeader,
      renderCell: ({ value, row }) => {
        return (
          <ClickAbleLabel
            backgroundcolor={getLabelBgColor(value)}
            color={getLabelColor(value)}
          >
            {camelCaseToSeparateWords(value)}
          </ClickAbleLabel>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 80,
      renderHeader,

      getActions: (params) => [
        ...[
          <GridActionsCellItem
            label="Cancel"
            showInMenu
            onClick={() => {
              setProgressData((prev) => params.row);
              setOpenCancelModal(true);
            }}
          />,
          <GridActionsCellItem
            label="View Details"
            showInMenu
            onClick={async () => {
              await setProgressData((prev) => params.row);
              setOpenModal(true);
            }}
          />,
          // <GridActionsCellItem label="Support" showInMenu onClick={() => {}} />,
        ],
      ],
    },
  ];

  async function cancelRequest(id) {
    try {
      const response = await axiosPrivate.patch(
        `/status-tracker/cancel/${id}`,
        {
          reason: cancellationReason,
          transactionType: "Pof",
        }
      );
      if (response.data) {
        setCancellationReason("");
        setOpenCancelModal(false);
        toast.success("Request cancelled");
      }
    } catch (err) {
      setOpenCancelModal(false);
      toast.error("An error occured while cancelling the request");
    }
  }

  return (
    <Wrapper {...pageAnimationConfig}>
      <div className="flex justify-between">
        <div>
          <Text type="h3" weight="medium">
            Short Term Loans
          </Text>
          <Text type="p">My short term loans applications</Text>
        </div>

        <Link to="/apply-for-proof-of-funds">
          <Button
            size="sm"
            border={`1px solid ${africredColors.neutrals.night[950]}`}
          >
            {!isMobile && <Icon type="proof-of-funds-sm" />}

            <Text type="p" weight="medium">
              Apply Now
            </Text>
          </Button>
        </Link>
      </div>

      {proofOfFunds?.length > 0 ? (
        <ContentWrapper>
          <div className="flex justify-between items-center gap-y-2 items-center flex-wrap lg:flex-nowrap">
            <Text type="h5" weight="medium">
              All short term loans
            </Text>
            <TableFilter
              filterData={filterData}
              setFilterData={setFilterData}
            />
          </div>
          <TableWrapper>
            <Table
              columns={columns}
              rows={proofOfFunds
                .sort(function (a, b) {
                  if (filterData.sort.toLowerCase() === "asc")
                    return new Date(b.date) - new Date(a.date);
                  return new Date(a.date) - new Date(b.date);
                })
                .filter(
                  (item) =>
                    item.amount.includes(filterData.searchTerm) ||
                    item.referenceNumber
                      .toLowerCase()
                      .includes(filterData.searchTerm?.toLowerCase()) ||
                    item.issuedBy.name
                      .toLowerCase()
                      .includes(filterData.searchTerm?.toLowerCase()) ||
                    item.purpose
                      .toLowerCase()
                      .includes(filterData.searchTerm?.toLowerCase())
                )}
              pageSize="8"
            />
          </TableWrapper>
        </ContentWrapper>
      ) : (
        <NoRecordFound />
      )}

      {progressData && (
        <RequestInfoModal
          open={openModal}
          setOpen={setOpenModal}
          title={`You have applied for short term loan of ${
            progressData.currency
          } ${formatNumber(progressData.amount)}`}
          subtitle="Kindly track your short term loan process below."
        >
          <div className="mt-8 p-2">
            <RequestProgressIndicator refetch={refetch} data={progressData} />
          </div>
        </RequestInfoModal>
      )}
      <RequestInfoModal
        open={openCanelModal}
        setOpen={setOpenCancelModal}
        title="Cancel Request"
        subtitle="Lorem ipsum dolor sit amet consectetur. Neque porttitor amet sed est in amet. Mauris convallis sed sagittis ornare faucibus."
      >
        <div className="h-[78vh] flex flex-col justify-between mt-12">
          <div>
            <Text type="h5" className="mb-4" weight="500">
              Reason
            </Text>
            <Input
              type="textArea"
              borderRadius="1rem"
              value={cancellationReason}
              onChange={(e) => setCancellationReason(e.target.value)}
            />
          </div>

          <div>
            <Text type="h4" className="mb-4" weight="500">
              Are you sure?
            </Text>
            <Text type="p" className="mb-4" weight="500">
              Lorem ipsum dolor sit amet consectetur. Neque porttitor amet sed
              est in amet. Mauris convallis sed sagittis ornare faucibus.
            </Text>
            <Button
              onClick={() => cancelRequest(progressData.id)}
              width="100%"
              size="md"
              bgcolor={africredColors.primary.green[600]}
            >
              Yes, cancel
            </Button>
          </div>
        </div>
      </RequestInfoModal>
    </Wrapper>
  );
};
