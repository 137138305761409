import styled from "styled-components";
import { CloseablePageTemplage } from "../../templates";
import { brandTheme, screen } from "../../../theme";
import { AntdSelect, Button, Icon, Input, Spinner, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import {
  DragAndDropFiles,
  InputWithAddonSelect,
  OnboardingFlow,
  StepProgressIndicator,
} from "../../molecules";
import { useEffect, useState } from "react";
import access from "../../../assets/pngs/partners/access.png";
import fcmb from "../../../assets/pngs/partners/fcmb.png";
import future_finance from "../../../assets/pngs/partners/future_finance.png";
import gtb from "../../../assets/pngs/partners/gtb.png";
import lendwise from "../../../assets/pngs/partners/lendwise.png";
import polaris from "../../../assets/pngs/partners/polaris.png";
import power from "../../../assets/pngs/partners/power.png";
import prodigy from "../../../assets/pngs/partners/prodigy.png";
import stilt from "../../../assets/pngs/partners/stilt.png";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import {
  formatNumber,
  formatSelectOptionForAntd,
  getCountryCode,
  isBVNValid,
  separatePhoneNumberString,
} from "../../../utils";
import { PricingConversionBreakdown } from "../../molecules/PricingConversionBreakdown";
import { purposeOfLoan } from "../../../constants";
import { useNavigate } from "react-router-dom";

const FormSection = styled.section`
  background-color: ${brandTheme.brand.backgroundSkin4};
  border-radius: 16px;
  padding: 48px 24px;
  height: fit-content;

  @media only screen and (${screen.md}) {
    padding: 48px;
  }
`;
const TitleWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 32px;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 12px;

  @media (min-width: 840px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }
`;
const GridItem = styled.div`
  display: flex;
  padding: 16px;
  gap: 10px;
  border-radius: 16px;
  background: ${africredColors.primary.green[50]};
`;
const FormInputs = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;

  > div:first-child {
    padding-bottom: 18px;
    border-bottom: 1px solid ${africredColors.neutrals.night[100]};
  }
`;
const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > div {
    width: 100%;
  }

  @media only screen and (${screen.md}) {
    flex-direction: row;
  }
`;

export const LoanApplicationForm = ({ children }) => {
  const auth = useAuth();
  const navigate = useNavigate()
  const isStudent = auth?.accountType == "Student";
  const userCountry = auth?.address?.country;
  const userCountryCode = getCountryCode(userCountry)
  const userPhoneNumber = auth?.phoneNumber?.code.slice(1) + auth?.phoneNumber?.number;
  // const userPhoneNumber = {
  //   code: auth?.phoneNumber?.code,
  //   number: auth?.phoneNumber?.number
  // }
  // const userPhoneNumber = {
  //   countryCode: getCountryCode(userCountry),
  //   phoneNumber: userPhoneNumberVal
  // }

  
  const [onboardingData, setOnboardingData] = useState({
    loading: false,
    email: isStudent? auth.email: "",
    firstName: isStudent? auth.firstName:"",
    lastName: isStudent? auth.lastName: "",
    phoneNumber:isStudent? userPhoneNumber: "",
    countryCode:isStudent? userCountryCode: "",
    cityOfResidence: isStudent? auth?.address?.city: "",
    countryOfStudy: isStudent? auth?.address?.country: "",
    purpose: "",
    processingFeeInCurrency: "",
    processingFeeInCurrency:"",
    loanAmountInNaira: "",
    processingFee: "",
    loanAmount: "",
    currency: "USD",
    currencyToPay:"NGN",
    currencyToPayIcon: "https://afri-cred.s3.eu-west-2.amazonaws.com/flags/Richoco+Studio++Samples/NG+-+Nigeria.png",
    admissionStatus: "",
    schoolOfferLetter: null,
    proofOfIdentity: null,
    paymentStatus: "pending",
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  const [stepCount, setStepCount] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  

  const onNext = (stepData, numberOfSteps) => {
    setOnboardingData({ ...onboardingData, ...stepData });
    if (currentIndex < numberOfSteps) setCurrentIndex(currentIndex + 1);
  };

  const onFinish = async (data) => {
    if (data.loading) return

    setOnboardingData(prev => ({
        ...prev,
        loading: true,
    }))

    const payload = {
      ...data,
      schoolOfferLetter: data?.schoolOfferLetter?.id,
      proofOfIdentity: {
        typeOfProofOfIdentity: data?.proofOfIdentity?.typeOfProofOfIdentity,
        file: data?.proofOfIdentity?.id,
      },
      phoneNumber: separatePhoneNumberString(
        data?.phoneNumber,
        data.countryCode
      ),
    };

    if (isStudent) {
      payload.phoneNumber = auth?.phoneNumber;
    }

    try {
      const initPaymentRes = await axiosPrivate.post("/budpay/create-payment-link", {
        email: data.email,
        amount: data?.processingFeeInCurrency?.toString(),
        transactionType: "Loan",
        currency: data?.currencyToPay
      });
      if (initPaymentRes.data) {
        // TODO: visit the paystack payment website
        let dataClone = {...payload}
        
        if (
          !dataClone?.proofOfIdentity ||
          !dataClone?.proofOfIdentity?.typeOfProofOfIdentity ||
          !dataClone?.proofOfIdentity?.file
        ) {
            delete dataClone?.proofOfIdentity
        }
        if(!dataClone?.schoolOfferLetter){
          delete dataClone.schoolOfferLetter
        }

        const newPayload  = {
          email: dataClone?.email,
          firstName: dataClone?.firstName,
          lastName: dataClone?.lastName,
          phoneNumber: dataClone?.phoneNumber,
          cityOfResidence:dataClone?.cityOfResidence,
          countryOfStudy: dataClone?.countryOfStudy,
          admissionStatus:dataClone?.admissionStatus,
          loanAmount:dataClone?.amountInDollars,
          processingFee:Number(dataClone?.processingFeeInUSD),
          currency:dataClone?.currency,
          currencyToPay:dataClone?.currencyToPay,
          processingFeeInCurrency: dataClone?.processingFeeInCurrency,
          paymentStatus: dataClone?.paymentStatus,
          purpose: dataClone?.purpose,
        }

        if(dataClone.proofOfIdentity){
          newPayload.proofOfIdentity = dataClone?.proofOfIdentity
        }
        if(dataClone?.schoolOfferLetter){
          newPayload.schoolOfferLetter = dataClone?.schoolOfferLetter
        }
        // console.log("NEW PAYLOAD::::::: LOAD APPLICATION", newPayload)
        const response = await axiosPrivate.post("/loan/create", {
          ...newPayload,
          payStackReferenceNumber: initPaymentRes.data.data.reference,
        });

        if (response.data) {
          window.location = initPaymentRes.data.data.authorization_url;
        }
      }
    } catch (e) {
      // console.log("ERROR WHILE PROCESSING", e)
      toast.error(e?.response?.data?.message);
    }
    
    setOnboardingData(prev => ({
      ...prev,
      loading: false,
    }))
  };

  const goBack = () => {
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
    else navigate(-1)
  };

  return (
    <CloseablePageTemplage>
      <TitleWrapper>
        <Text textalign="center" weight="medium" type="h2" color="black">
          {auth.accountType === "Agent" ? "Student's" : null} Loan Application
        </Text>

        <Text textalign="center" type="p">
          We've designed our Loan Form with your convenience in mind.
        </Text>
      </TitleWrapper>

      <div className="flex items-center mt-4 mb-8">
        <StepProgressIndicator
          numberOfSteps={stepCount}
          currentStep={currentIndex + 1}
        />
      </div>

      <OnboardingFlow
        currentIndex={currentIndex}
        onFinish={onFinish}
        onNext={onNext}
        goBack={goBack}
        getStepCount={setStepCount}
      >
        {auth?.accountType !== "Student" && <StepOne data={onboardingData} setData={setOnboardingData} />}
        <StepTwo data={onboardingData} setData={setOnboardingData} />
        <StepThree data={onboardingData} setData={setOnboardingData} />
        {/* <StepFour data={onboardingData} setData={setOnboardingData} /> */}
        <StepFive data={onboardingData} setData={setOnboardingData} />
      </OnboardingFlow>
    </CloseablePageTemplage>
  );
};

const StepFooter = ({ goToNext, className, goBack, lastStep, loading }) => {
  return (
    <div
      className={`flex justify-between w-full sm:w-2/3 gap-4 rounded pt-8 pb-8 ${
        className || "lg:w-2/4 xl:w-2/5"
      }`}
    >
      <Button
        onClick={goBack}
        width="fit-content"
        size="md"
        bgcolor={africredColors.neutrals.white[10]}
      >
        <Text type="p" weight="medium">
          Back
        </Text>
      </Button>
      <Button
        onClick={() => goToNext({ id: 1 })}
        width="fit-content"
        size="md"
        bgcolor={
          lastStep
            ? brandTheme.brand.primary
            : africredColors.primary.green[600]
        }
      >
        {loading ? <Spinner />:
        <Text type="p" weight="bold" color="black">
          {lastStep ? "Submit & Pay" : "Continue"}
        </Text>
        }
      </Button>
    </div>
  );
};
function StepOne({ goToNext, data, setData, goBack }) {
  const auth = useAuth();
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  return (
    <>
      <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
        <FormInputs>
          {/* <FormRow>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                Enter BVN
              </Text>
              <Input
                type="text"
                size="md"
                padding="16px"
                placeholder="Enter number"
                autoComplete="off"
                value={data.bvn}
                onChange={(e) =>
                  setData((prev) => ({ ...prev, bvn: e.target.value }))
                }
              />
            </div>
          </FormRow> */}
          <FormRow>
              <div>
                  <Text
                      textalign="left"
                      type="h4"
                      weight="medium"
                      margin="0"
                  >
                      Student or client details
                  </Text>
              </div>
          </FormRow>

          <FormRow>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                First Name
              </Text>
              <Input
                icon={<Icon type="person" />}
                type="text"
                size="md"
                placeholder="First Name"
                autoComplete="off"
                value={data.firstName}
                onChange={(e) =>
                  setData((prev) => ({ ...prev, firstName: e.target.value }))
                }
              />
            </div>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                Last Name
              </Text>
              <Input
                icon={<Icon type="person" />}
                type="text"
                size="md"
                placeholder="Last Name"
                autoComplete="off"
                value={data.lastName}
                onChange={(e) =>
                  setData((prev) => ({ ...prev, lastName: e.target.value }))
                }
              />
            </div>
          </FormRow>

          <FormRow>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                Email
              </Text>
              <Input
                icon={<Icon type="email" />}
                type="email"
                size="md"
                placeholder="Email"
                autoComplete="off"
                value={data.email}
                onChange={(e) =>
                  setData((prev) => ({ ...prev, email: e.target.value }))
                }
              />
            </div>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                Phone Number
              </Text>
              <Input
                size="md"
                type="phone"
                value={data.phoneNumber}
                onChange={(val, country) =>{
                  
                  setData((prev) => ({
                    ...prev,
                    phoneNumber: val,
                    countryCode: country.countryCode,
                  }))
                }
                  
                }
                setIsPhoneValid={setIsPhoneValid}
              />
            </div>
          </FormRow>

          <FormRow>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                City of residence
              </Text>
              <Input
                type="text"
                size="md"
                icon={<Icon type="location" />}
                placeholder="City"
                autoComplete="off"
                value={data.cityOfResidence}
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    cityOfResidence: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                Country of Residence
              </Text>
              <AntdSelect
                showSearch
                options={formatSelectOptionForAntd(
                  auth.enumValues.countryEnum
                )}
                value={data.countryOfStudy}
                setValue={(val) => {
                  setData((prev) => ({
                    ...prev,
                    countryOfStudy: val,
                  }));
                }}
              />
            </div>
          </FormRow>
        </FormInputs>
      </FormSection>

      <StepFooter
        goBack={goBack}
        goToNext={() => {
          
           if (!isPhoneValid) {
            toast.error("Please enter a valid phone number!");
          } else if (
            data.email &&
            data.firstName &&
            data.lastName &&
            data.phoneNumber &&
            data.cityOfResidence &&
            data.countryOfStudy
          ) {
            goToNext(data);
          } else {
            toast.error("Please provide all details...");
          }
        }}
        className={"w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5"}
      />
    </>
  );
}
function StepTwo({ goToNext, data, goBack, setData }) {
  const auth = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const usdCurrency = {
    icon: "https://afri-cred.s3.eu-west-2.amazonaws.com/flags/Richoco+Studio++Samples/US+-+United+States.png",
    value: "USD",
  };
  const [selectedMenuOption, setSelectedMenuOption] = useState(usdCurrency);

  useEffect(() => {
    const calculateFee = async ({amount, transactionType, currency, setData, currencyToPay}) => {
      try {
        const response = await axiosPrivate.get(
          `/app-configuration/processing-fee/${amount || 0}/${currency}/${transactionType}/${currencyToPay}`
        );

        if (response.data) {
          setData((prev) => ({
            ...prev,
            loanAmountInNaira:0,
            amountInDollars: response?.data?.amountInDollars,
            // processingFeeInCurrency:response?.data?.processingFeeInCurrency,
            processingFeeInCurrency:response?.data?.processingFeeInCurrency,
            processingFeeInUSD:response?.data?.processingFeeInUSD,
            currency:response?.data?.currency,
            // currency:usdCurrency.value,
            currencyToPay:response?.data?.currencyToPay,
            // remittanceProcessingFee:response?.data?.remittanceProcessingFee,
          }));
        }
      } catch (e) {
        if (
          e?.response?.data?.statusCode !== 500 &&
          e?.response?.data?.statusCode !== 404
        ) {
          toast.error(e?.response?.data?.message);
        }
      }
    };
    calculateFee({amount: data.loanAmount, transactionType:'Loan', currency: selectedMenuOption.value, setData, currencyToPay:data?.currencyToPay})
  }, [axiosPrivate, data.loanAmount, selectedMenuOption.value, setData,data?.currencyToPay]);

  return (
    <>
      <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
        <div className="w-full">
          <Text textalign="left" type="h5" weight="medium">
            Loan amount
          </Text>
        </div>

        <InputWithAddonSelect
          inputType="number"
          background={africredColors.neutrals.night[80]}
          inputValue={data?.loanAmount}
          inputLabel="Loan Required"
          currency={selectedMenuOption.value}
          setInputValue={(e) => {
            if (e.target.value >= 0) {
              setData((prev) => ({
                ...prev,
                loanAmount: e.target.value,
              }));
            }
          }}
          placeholder="Amount"
          selectMenuData={auth.enumValues.transactionFlags}
          selectedMenuOption={selectedMenuOption}
          setSelectedMenuOption={setSelectedMenuOption}
        />
        {/* (data.loanAmount * 2) / 100 */}
        <PricingConversionBreakdown
          // currencyDetails={selectedMenuOption}
          // processingFeeInChoosenCurrency={formatNumber(data.processingFee)}
          // processingFeeInCurrency={formatNumber(data.processingFeeInCurrency)}
          percentageProcessingFeeText={"Loan Processing fee"}
          currencyDetails={usdCurrency}
          feeInCurrencyToPay={formatNumber(data?.processingFeeInCurrency || 0)}
          selectMenuData={auth.enumValues.transactionFlags}
          currencyToPay = {data?.currencyToPay}
          setData = {setData}
          currencyToPayIcon = {data?.currencyToPayIcon}
          processingFeeInChoosenCurrency = {data?.processingFeeInUSD}
        />

        <div className="w-full mt-2">
          <Text textalign="left" type="h5" weight="medium">
            Admission status
          </Text>
        </div>

        <AntdSelect
          options={formatSelectOptionForAntd(
            auth.enumValues.admissionStatusEnum
          )}
          value={data.admissionStatus}
          setValue={(val) => {
            setData((prev) => ({ ...prev, admissionStatus: val }));
          }}
        />

        <div className="w-full mt-2">
          <Text textalign="left" type="h5" weight="medium" margin="0 0 1rem 0">
            Purpose
          </Text>
        </div>

        <AntdSelect
          options={formatSelectOptionForAntd(
            auth?.enumValues?.purposeEnum
          )}
          value={data.purpose}
          setValue={(val) => {
            setData((prev) => ({ ...prev, purpose: val }));
          }}
        />
      </FormSection>

      <StepFooter
        goBack={goBack}
        goToNext={() => {
          if (
            data.loanAmount &&
            data.processingFeeInCurrency &&
            data.admissionStatus &&
            data.purpose
          ) {
            goToNext(data);
          } else {
            toast.error("Please provide all details...");
          }
        }}
        className={"w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5"}
      />
    </>
  );
}
function StepThree({ goToNext, data, goBack, setData }) {
  const auth = useAuth();

  return (
    <>
      <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
        <div className="w-full">
          <Text textalign="left" margin="0 0 1rem 0" type="h5" weight="medium">
            Proof of Identity (Optional)
          </Text>
          {/* proofOfIdentityEnum */}
          <AntdSelect
            options={formatSelectOptionForAntd(
              auth.enumValues.proofOfIdentityEnum
            )}
            value={data?.proofOfIdentity?.typeOfProofOfIdentity}
            setValue={(val) => {
              setData((prev) => ({
                ...prev,
                proofOfIdentity: {
                  ...prev.proofOfIdentity,
                  typeOfProofOfIdentity: val,
                },
              }));
            }}
          />
        </div>

        <DragAndDropFiles
          supportedFormats="JPEG, PNG & PDF"
          file={data.proofOfIdentity}
          setFile={(value) =>
            setData((prev) => ({
              ...prev,
              proofOfIdentity: prev.proofOfIdentity
                ? Object.assign(prev.proofOfIdentity, value)
                : value,
            }))
          }
        />



        <div className="w-full">
          <Text textalign="left" type="h5" weight="medium">
            School offer letter (Optional)
          </Text>
          <Text textalign="left" type="p" margin="1rem 0 0 0">
            Offer letter should contain school account details and other
            beneficiaries
          </Text>
        </div>

        <DragAndDropFiles
          supportedFormats="JPEG, PNG & PDF"
          file={data.schoolOfferLetter}
          setFile={(value) =>
            setData((prev) => ({
              ...prev,
              schoolOfferLetter: prev.schoolOfferLetter
                ? Object.assign(prev.schoolOfferLetter, value)
                : value,
            }))
          }
        />
      </FormSection>
      <StepFooter
        goBack={goBack}
        goToNext={() => {
          goToNext(data);
          // if (data.proofOfIdentity?.name) {
          //   goToNext(data);
          // } else {
          //   toast.error(
          //     "Please provide all information including required file..."
          //   );
          // }
        }}
        className={"w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5"}
      />
    </>
  );
}
function StepFour({ goToNext, data, goBack, setData }) {
  return (
    <>
      <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
        <div className="w-full">
          <Text textalign="left" type="h5" weight="medium">
            School offer letter (Optional)
          </Text>
          <Text textalign="left" type="p" margin="1rem 0 0 0">
            Offer letter should contain school account details and other
            beneficiaries
          </Text>
        </div>

        <DragAndDropFiles
          supportedFormats="JPEG, PNG & PDF"
          file={data.schoolOfferLetter}
          setFile={(value) =>
            setData((prev) => ({
              ...prev,
              schoolOfferLetter: prev.schoolOfferLetter
                ? Object.assign(prev.schoolOfferLetter, value)
                : value,
            }))
          }
        />
      </FormSection>
      <StepFooter
        goBack={goBack}
        goToNext={() => {
          if (data?.proofOfIdentity?.id && !data?.proofOfIdentity.typeOfProofOfIdentity) {
              toast.error("Please provide file type for proof of identity...");
          } else {
              goToNext(data);
          }
        }}
        className={"w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5"}
      />
    </>
  );
}
function StepFive({ data, onFinish, goBack, setData }) {
  return (
    <>
      <FormSection className="flex flex-col items-between w-full sm:w-4/5 lg:w-4/6 gap-4 rounded">
        <Grid>
          {[
            {
              title: "Fullname",
              subTitle: `${data.firstName} ${data.lastName}`,
            },
            { title: "Email", subTitle: data.email },
            {
              title: "Phone number",
              subTitle: `${data.phoneNumber}`,
            },
            { title: "City of residence", subTitle: data.cityOfResidence },
            { title: "Country of study", subTitle: data.countryOfStudy },
            {
              title: `${data.currency} ${data.loanAmount}`,
              subTitle: `${formatNumber(data?.processingFeeInCurrency || 0)} ${data?.currencyToPay} Processing fee`,
            },
            { title: "Admission status", subTitle: data.admissionStatus },
            {
              title: "Proof of ID",
              subTitle: data?.proofOfIdentity?.typeOfProofOfIdentity,
            },
            {
              title: "School offer letter",
              subTitle: data?.schoolOfferLetter?.name,
            },
          ].map((item) => (
            <GridItem key={item.title}>
              <Icon
                type="checked-circle"
                width={25}
                height={25}
                stroke={africredColors.primary.green[600]}
              />
              <div className="flex flex-col gap-2">
                <Text type="h5" weight="medium">
                  {item.title}
                </Text>
                <Text type="p" color={africredColors.neutrals.night[500]}>
                  {item.subTitle}
                </Text>
              </div>
            </GridItem>
          ))}
        </Grid>

        <div className="w-full">
          <Text
            margin="1rem 0 0 0"
            textalign="left"
            type="h4"
            weight="medium"
            color="black"
          >
            What next?
          </Text>

          <Text width="90%" textalign="left" type="p" margin="1rem 0 1.5rem 0">
            Kindly proceed to make
            payment if you are sure of the details provided and expect a
            provisional Issue of receipt of local currency payment.
          </Text>

          <div className="flex flex-wrap w-full gap-4">
            {[
              access,
              fcmb,
              future_finance,
              gtb,
              lendwise,
              polaris,
              power,
              prodigy,
              stilt,
            ].map((item, idx) => (
              <img key={idx} src={item} alt={`partner-${idx}`} />
            ))}
          </div>
        </div>
      </FormSection>

      <StepFooter
        lastStep
        loading={data.loading}
        goBack={goBack}
        goToNext={() => onFinish(data)}
        className={"w-full sm:w-4/5 lg:w-4/6"}
      />
    </>
  );
}
