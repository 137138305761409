import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { AntdSelect, Button, Container, Icon, Input, Text } from '../../atoms'
import { Link, NavLink } from 'react-router-dom'
import { Pagination } from '../../molecules'
import { useQuery } from '@tanstack/react-query'
import axiosPublic from '../../../api/axios'
import { Skeleton } from 'antd'
import { formatSelectOptionForAntd } from '../../../utils'
import { AppNav } from '../../molecules/AppNav'
import schoolLogo from '../../../assets/pngs/school-logo.png'
import { africredColors } from '../../../theme/colors'
import { screen } from '../../../theme'

const Wrapper = styled.div`
    background-color: #fff;
`
const WhiteArea = styled.div`
    background-color: #fff;
`
const TransactionTypeSelector = styled.div`
    padding: 8px;
    border-radius: 16px;
    margin: 0 auto;
    display: flex;
    background: ${africredColors.neutrals.night[50]};
    border-bottom: none !important;
    padding-bottom: 8px !important;
    width: fit-content;
    align-items: center;
    overflow: scroll;
    @media only screen and (${screen.sm}) {
        overflow: scroll;
    }
`
const Options = styled.div`
    display: flex;
    > label {
        display: block;
        padding: 12px 16px;
        border-radius: 8px;
        min-width: 133px;
        text-align: center;
        cursor: pointer;
    }

    > input[type='radio'] {
        display: none;
    }

    input[type='radio']:checked + label {
        background: ${africredColors.neutrals.white[10]};
    }
`

const OPTIONS = {
    Universities: 'Universities',
    Courses: 'Courses',
}

export const Universities = () => {
    const [searchTerm, setSearchTerm] = useState('')
    const [country, setCountry] = useState('')
    const [isSideNavOpen, setIsSideNavOpen] = useState(false)
    const [page, setPage] = useState(1)

    const [selectedOption, setSelectedOption] = useState(OPTIONS.Universities)

    const { isPending: countryLoading, data: countryList } = useQuery({
        queryKey: ['university-countries'],
        queryFn: async () => {
            const response = await axiosPublic(
                `/university-country/search?limit=999`,
            )

            if (response.data) {
                return response.data
            }
        },
    })

    const countryId = useMemo(() => {
        const one = countryList?.foundCountries.find(
            one => one.name === country,
        )
        return one?.id
    }, [country])

    const {
        isPending,
        error,
        data: universities,
    } = useQuery({
        queryKey: [
            `universities${page}`,
            page,
            searchTerm,
            country,
            selectedOption,
        ],
        queryFn: async () => {
            const baseUrl =
                selectedOption === OPTIONS.Universities
                    ? `search`
                    : `university-courses`
            const response = await axiosPublic(
                `/university/${baseUrl}?page=${page}&${
                    searchTerm ? 'searchTerm=' + searchTerm : ''
                }&${countryId ? 'country[0]=' + countryId : ''}`,
            )

            if (response.data) {
                if (selectedOption === OPTIONS.Universities) {
                    return response.data
                }
                // filter courses array based on searchTeam, 
                // then duplicate university for each course
                const data = []
                response.data.foundUniversities?.forEach(one => {
                    const courses = one.courses?.filter((course = '') =>
                        searchTerm
                            ? course
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                            : true,
                    ) || []

                    courses.forEach(course => {
                        const uni = { ...one, course }
                        data.push(uni)
                    })
                })

                return { foundUniversities: data }
            }
        },
    })

    return (
        <Wrapper>
            <AppNav />

            <Container>
                <div className="flex justify-between flex-wrap pt-4 md:pt-10 mb-10 gap-4">
                    <div className="flex flex-col items-center gap-2 w-full">
                        <Text type="h3" weight="bold" fontSize="2.5rem">
                            {selectedOption === OPTIONS.Universities
                                ? 'University List'
                                : 'Our Plethora of Courses'}
                        </Text>
                        <Text
                            type="p"
                            color="#5D5D5D"
                            hovercolor="#5D5D5D"
                            className="max-w-[600px]"
                            textalign="center"
                        >
                            Turn your study abroad aspirations into a tangible
                            reality with AfriCred. Our international student
                            loans eliminate the need for a cosigner or
                            collateral, making your dream achievable.
                        </Text>
                    </div>
                </div>
            </Container>

            <WhiteArea>
                <Container>
                    <div className="pb-4">
                        <TransactionTypeSelector style={{ marginBottom: 20 }}>
                            {['Universities', 'Courses'].map((item, index) => (
                                <Options key={index}>
                                    <input
                                        type="radio"
                                        name="option"
                                        id={item}
                                        value={item}
                                        checked={selectedOption === item}
                                        onClick={() => {
                                            setSelectedOption(item)
                                        }}
                                    />
                                    <label
                                        className={`sm:text-sm md:text-md lg:text-base`}
                                        htmlFor={item}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            paddingTop: '16px',
                                            paddingBotton: '16px',
                                        }}
                                    >
                                        {/* <img
                                                src={item.icon}
                                                alt="flag"
                                                style={{ marginRight: 10 }}
                                            /> */}
                                        {item}
                                    </label>
                                </Options>
                            ))}
                        </TransactionTypeSelector>
                        <div className="flex gap-4 md:gap-6">
                            <div className="flex flex-col md:flex-row gap-2 w-full justify-center">
                                <div className="w-full lg:max-w-[400px]">
                                    <Input
                                        type="search"
                                        size="sm"
                                        icon={
                                            <Icon
                                                type="search"
                                                className="mr-2"
                                            />
                                        }
                                        paddingleft="3rem"
                                        bgcolor="transparent"
                                        placeholder={
                                            selectedOption ===
                                            OPTIONS.Universities
                                                ? 'College or university name'
                                                : 'Course title e.g Phd in Physics'
                                        }
                                        className="border-[1px] border-[#B0B0B0]"
                                        value={searchTerm}
                                        onChange={e => {
                                            setSearchTerm(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className="w-full lg:max-w-[300px]">
                                    <AntdSelect
                                        size="sm"
                                        bgColor="transparent"
                                        className="border-[1px] border-[#B0B0B0]"
                                        showSearch
                                        options={
                                            countryList?.foundCountries
                                                ?.length > 0
                                                ? formatSelectOptionForAntd([
                                                      '',
                                                      ...new Set(
                                                          countryList?.foundCountries?.map(
                                                              item => item.name,
                                                          ),
                                                      ),
                                                  ])
                                                : []
                                        }
                                        placeholder="Country"
                                        value={
                                            country.length > 20
                                                ? `${country.slice(0, 20)}...`
                                                : country
                                        }
                                        setValue={val => {
                                            setCountry(val)
                                        }}
                                        loading={countryLoading}
                                    />
                                </div>
                            </div>
                        </div>

                        {isPending ? (
                            <div className="h-[20vh] flex items-center justify-center">
                                <Skeleton active />
                            </div>
                        ) : error ? (
                            <div>
                                {'An error has occurred: ' + error.message}
                            </div>
                        ) : universities.foundUniversities.length > 0 ? (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-6 gap-4">
                                    {universities.foundUniversities.map(
                                        (item, idx) => (
                                            <School
                                                key={idx}
                                                name={item.schoolName}
                                                course={item.course}
                                                state={item.state}
                                                country={item.country}
                                                website={item.website}
                                                id={item.id}
                                                universitiesCount={
                                                    universities.count
                                                }
                                                icon={item.icon}
                                            />
                                        ),
                                    )}
                                </div>
                                <div className="flex justify-center mt-4">
                                    <Pagination
                                        currentPage={page}
                                        itemCount={universities.count}
                                        pageSize={
                                            universities.foundUniversities
                                                .length
                                        }
                                        setPage={setPage}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="h-[20vh] flex items-center justify-center">
                                <Text type="p">No university found...</Text>
                            </div>
                        )}
                    </div>
                </Container>
            </WhiteArea>
        </Wrapper>
    )
}

const School = ({
    name = '',
    state = '',
    country = '',
    website = '#',
    course = '',
    icon,
    id,
    universitiesCount,
}) => {
    return (
        <SchoolWrapper
            to={`/universities/${id}`}
            state={{
                universitiesCount,
            }}
            className="flex w-full gap-6"
        >
            <div className="min-w-[32px] min-h-[44.3px] w-[32px] h-[44.3px]">
                <img
                    src={icon?.Location || schoolLogo}
                    alt="school logo"
                    className="w-full"
                />
            </div>
            <div className="flex flex-col gap-2">
                <Text
                    title={course || name}
                    type="small"
                    weight="medium"
                    color="#151515"
                    hovercolor="#151515"
                    className="line-clamp-2"
                    fontSize="1rem"
                >
                    {course || name}
                </Text>
                <div className="flex gap-2 items-center">
                    <Text
                        type="h3"
                        color="#5D5D5D"
                        hovercolor="#5D5D5D"
                        className="break-words"
                    >
                        {state}, {country?.name}
                    </Text>
                </div>
            </div>
        </SchoolWrapper>
    )
}

const SchoolWrapper = styled(NavLink)`
    padding: 24px;
    border-radius: 16px;
    border: solid 1px #d1d1d1;
    overflow: hidden;
`
