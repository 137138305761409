import React, { useEffect, useMemo } from "react";

export const OnboardingFlow = ({
  children,
  onFinish,
  currentIndex,
  onNext,
  getStepCount,
  goBack,
}) => {
  const allChildren = useMemo(() => {
    return React.Children.toArray(children) || [];
  }, [children]);
  const goToNext = (stepData) => {
    onNext(stepData, allChildren.length - 1);
  };

  const currentChild = allChildren[currentIndex];

  useEffect(() => {
    getStepCount?.(allChildren.length);
  }, [getStepCount, allChildren]);

  if (React.isValidElement(currentChild)) {
    return React.cloneElement(currentChild, { goToNext, onFinish, goBack });
  }

  return currentChild;
};
