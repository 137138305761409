export const africredColors = {
  primary: {
    gold: {
      50: "#ffffe7",
      100: "#feffc1",
      200: "#fffd86",
      300: "#fff441",
      400: "#ffe60d",
      500: "#ffd700",
      600: "#d19e00",
      700: "#a67102",
      800: "#89580a",
      900: "#74480f",
      950: "#442604",
    },
    green: {
      50: "#edfff7",
      100: "#d5ffee",
      200: "#aeffdd",
      300: "#70ffc4",
      400: "#2bfda4",
      500: "#00e785",
      600: "#00c06a",
      700: "#009656",
      800: "#067547",
      900: "#07603c",
      950: "#003720",
    },
    orange: {
      50: "#fff9ed",
      100: "#fff1d4",
      200: "#ffdfa8",
      300: "#ffc770",
      400: "#ffa337",
      500: "#ff8710",
      600: "#f06b06",
      700: "#c75007",
      800: "#9e3f0e",
      900: "#7f360f",
      950: "#451905",
    },
    blue: {
      50: "#eef3ff",
      100: "#e0e8ff",
      200: "#c7d4fe",
      300: "#a5b8fc",
      400: "#8090f9",
      500: "#626bf2",
      600: "#413fe5",
      700: "#3b37cb",
      800: "#312fa4",
      900: "#2e2f81",
      950: "#1c1b4b",
    },
  },
  secondary: {
    purple: {
      50: "#faf6fe",
      100: "#f4eafd",
      200: "#ebd8fc",
      300: "#dbbaf8",
      400: "#c58ef2",
      500: "#af62ea",
      600: "#9a42db",
      700: "#8530c0",
      800: "#7a30ab",
      900: "#5c257e",
      950: "#3e0f5c",
    },
    red: {
      50: "#fff1f3",
      100: "#ffe0e4",
      200: "#ffc6cd",
      300: "#ff9eaa",
      400: "#ff677a",
      500: "#fc2641",
      600: "#ea1832",
      700: "#c51027",
      800: "#a31123",
      900: "#871523",
      950: "#4a050e",
    },
    darkPurple: {
      50: "#ededff",
      100: "#dddfff",
      200: "#c2c3ff",
      300: "#a09eff",
      400: "#8577ff",
      500: "#7557fd",
      600: "#6838f3",
      700: "#592cd6",
      800: "#4926ad",
      900: "#3d2788",
      950: "#120b26",
    },
  },
  neutrals: {
    white: {
      10: "#ffffff",
      50: "#fefefe",
      100: "#efefef",
      200: "#dcdcdc",
      300: "#bdbdbd",
      400: "#989898",
      500: "#7c7c7c",
      600: "#656565",
      700: "#525252",
      800: "#464646",
      900: "#3d3d3d",
      950: "#292929",
    },
    night: {
      50: "#f6f6f6",
      80: "#eee",
      100: "#e7e7e7",
      200: "#d1d1d1",
      300: "#b0b0b0",
      400: "#888888",
      500: "#6d6d6d",
      600: "#5d5d5d",
      700: "#4f4f4f",
      800: "#454545",
      900: "#3d3d3d",
      950: "#151515",
    },
  },
};
