import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { Text } from "../../atoms";
import { screen } from "../../../theme";

const styles = css`
  display: flex;
  border-radius: 16px;
  border: 1px solid #b0b0b0;
  height: fit-content;
  padding: 16px 16px 24px 16px;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
`;
const CustomRadio = styled.label`
  ${styles};

  input[type="radio"] {
    display: block;
    margin-top: 5px;
  }

  @media only screen and (${screen.md}) {
    min-height: ${({ height }) => height};
  }
`;
const CustomCheckbox = styled.label`
  ${styles};
  height: auto;

  input[type="checkbox"] {
    display: block;
    margin-top: 5px;
  }
`;

export const CustomRadioInput = ({
  value,
  onChange,
  name,
  description,
  height = "140px",
  checked,
}) => {
  const ref = useRef(null);

  return (
    <CustomRadio height={height}>
      <input
        checked={checked}
        type="radio"
        value={value}
        ref={ref}
        onChange={onChange}
        name={name}
      />
      <div>
        <Text type="h5" className="mb-2">
          {value}
        </Text>
        <Text type="p">{description}</Text>
      </div>
    </CustomRadio>
  );
};

export const CustomCheckboxInput = ({
  value,
  onChange,
  name,
  description,
  height = "140px",
  checked,
}) => {
  const ref = useRef(null);

  return (
    <CustomCheckbox height={height}>
      <input
        checked={checked}
        type="checkbox"
        value={value}
        ref={ref}
        onChange={onChange}
        name={name}
      />
      <div>
        <Text type="h5" className="mb-2">
          {value}
        </Text>
        <Text type="p">{description}</Text>
      </div>
    </CustomCheckbox>
  );
};
