const currentYear = new Date().getFullYear()
const startYear = currentYear - 10
const yearsRange = 70 // 10 years behind the current year

export const dateDropdownEntries = {
    days: Array.from({ length: 31 }, (_, index) => (index + 1).toString()),
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    years: Array.from({ length: yearsRange }, (_, index) =>
        (startYear - index).toString(),
    ),
}

export const stepTitles = {
    business: [
        'Basic Details',
        'Basic Details',
        'Business Details',
        'Loan Fund Details',
    ],
    personal: ['Basic Details', 'Basic Details', 'Loan Fund Details'],
}

export const loanTypes = {
    business: 'Business Loan',
    personal: 'Personal Loan',
}
