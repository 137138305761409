import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

export const Table = ({
  columns,
  rows,
  pageSize = 5,
  hideHeader,
  removeBorder,
  hidePagination,
}) => {
  return (

    <>
      {(rows?.length && rows.length ==0) || !rows.length? (<div style={{textAlign: "center", marginTop: 50 }}>No matching Result Found</div>):
    <Box
      sx={{
        height: "100%",
        width: "100%",
        "& .MuiDataGrid-columnHeaders": hideHeader ? { display: "none" } : {},
        "& .MuiTablePagination-toolbar": hidePagination
          ? { display: "none" }
          : {},
        "&, [class^=MuiDataGrid]": removeBorder ? { border: "none" } : {},
        '& div[class^="MuiDataGrid-root"]': {
          zIndex: 0,
        },
      }}
    >
      <DataGrid
        rowHeight={70}
        sx={{ border: "none" }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize,
            },
          },
        }}
        pageSizeOptions={[5, 10, 15]}
        checkboxSelection={false}
        disableRowSelectionOnClick={true}
        disableColumnMenu={false}
      />
    </Box>}
    </>
  );
};
