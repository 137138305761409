import React from "react";
import styled from "styled-components";
import { Text } from "../../atoms";
const ngnFlag = require("../../../assets/pngs/ngn-flag.png");
const usaFlag = require("../../../assets/pngs/usa-flag.png");

const Wrapper = styled.label`
  display: flex;
  height: 80px;
  padding: 18px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  background: ${({ background }) => background};
  border: ${({ bordercolor }) => `1px solid ${bordercolor}`};
`;
const Input = styled.input`
  border: none;
  width: 100%;
  background: transparent;
`;

function fetchFlag(currency) {
  if (!currency) return null;
  if (currency.toLowerCase() === "ngn") {
    return ngnFlag;
  }
  if (currency.toLowerCase() === "usd") {
    return usaFlag;
  }
  return null;
}

export const AmountWithCurrencyInput = ({
  inputLabel,
  inputValue,
  setInputValue,
  currency = "USD",
  setCurrency,
  extraText,
  background,
  bordercolor,
  inputID,
}) => {
  return (
    <>
      <Wrapper
        htmlFor={inputID}
        background={background}
        bordercolor={bordercolor}
      >
        <div className="flex gap-4 items-center">
          {currency && <img src={fetchFlag(currency)} alt="flag" />}
          <Text type="p">{currency}</Text>
        </div>
        <div>
          <Text type="small" textalign="right">
            {inputLabel}
          </Text>
          <Input
            id={inputID}
            dir="rtl"
            type="amount"
            onChange={setInputValue}
            value={inputValue}
          />
        </div>
      </Wrapper>
      {extraText && (
        <Text margin="-8px 0 0 0" textalign="left" type="p">
          Default currency: NGN
        </Text>
      )}
    </>
  );
};
