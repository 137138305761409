import { brandTheme, screen } from "../../../theme";
import { Text } from "../../atoms";
import logo from "../../../assets/svgs/full_logo.svg";
import styled from "styled-components";
import { pageAnimationConfig } from "../../../utils";
import { motion } from "framer-motion";

const Wrapper = styled(motion.main)`
  background-color: ${brandTheme.brand.backgroundSkin};
`;
const ContentSection = styled.section`
  background-color: ${brandTheme.brand.backgroundSkin4};
  border-radius: 16px;
  padding: 48px 16px;

  @media only screen and (${screen.md}) {
    padding: 48px;
  }
`;
const TitleWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
`;

export const FullPageNotificationTemplate = ({
  title,
  icon,
  subtitle,
  children,
}) => {
  return (
    <Wrapper
      {...pageAnimationConfig}
      className="h-screen flex flex-col gap-y-8 items-center justify-center p-4"
    >
      <img src={logo} alt="logo" />

      <ContentSection className="flex flex-col items-center w-full sm:w-2/3 lg:w-2/4 gap-4 rounded">
        {icon && <div>{icon}</div>}
        <TitleWrapper>
          <Text textalign="center" type="h2">
            {title}
          </Text>
          {subtitle && (
            <Text textalign="center" type="p">
              {subtitle}
            </Text>
          )}
        </TitleWrapper>

        {children}
      </ContentSection>
    </Wrapper>
  );
};
