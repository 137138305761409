import React from 'react'
import { Icon, Text } from '../../atoms'
import styled from 'styled-components'
import { africredColors } from '../../../theme/colors'

const PaginationSection = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const PaginationNavigationButton = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid ${africredColors.neutrals.night[300]};
    padding: 12px;
    cursor: pointer;
`

export const Pagination = ({ currentPage, pageSize, itemCount, setPage }) => {
    const upperBound = currentPage * pageSize
    const lowerBound = upperBound - pageSize + 1
    return (
        <PaginationSection className="gap-2">
            <Text type="p" color="#5D5D5D" hovercolor="#5D5D5D">
                {lowerBound} - {upperBound} of {itemCount}
            </Text>
            <div className="flex gap-6">
                <PaginationNavigationButton
                    onClick={() => {
                        if (lowerBound > 1) {
                            setPage(prev => prev - 1)
                        }
                    }}
                >
                    <Icon type="back" />
                </PaginationNavigationButton>
                <PaginationNavigationButton
                    onClick={() => {
                        if (upperBound < itemCount) {
                            setPage(prev => prev + 1)
                        }
                    }}
                >
                    <Icon type="next" />
                </PaginationNavigationButton>
            </div>
        </PaginationSection>
    )
}
