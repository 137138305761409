import React, { useState } from "react";
import { AuthTemplate } from "../../templates/AuthTemplate";
import { Button, PasswordInput } from "../../atoms";
import { brandTheme } from "../../../theme";
import { ResetPasswordConfirmation } from "./ResetPasswordConfirmation";
import { useSearchParams } from "react-router-dom";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

export const ResetPassword = () => {
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  let [searchParams] = useSearchParams();

  async function resetPassword() {
    try {
      const response = await axios.post(
        "/account/reset-password",
        {
          password,
          confirmPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${searchParams.get("token")}`,
          },
        }
      );

      if (response.data) setResetSuccessful(true);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }

  if (!searchParams.get("token")) {
    return <p>You need a token to access this page</p>;
  }

  return (
    <>
      {!resetSuccessful ? (
        <AuthTemplate
          title="Reset password"
          subtitle="Combine special characters and symbols to create a strongpassword"
        >
          <PasswordInput
            size="md"
            placeholder="Password"
            autoComplete="off"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <PasswordInput
            size="md"
            placeholder="Password"
            autoComplete="off"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          <Button
            onClick={resetPassword}
            width="100%"
            size="md"
            bgcolor={brandTheme.brand.secondary}
            style={{ color: "white" }}
          >
            Reset password
          </Button>
        </AuthTemplate>
      ) : (
        <ResetPasswordConfirmation />
      )}
    </>
  );
};
