import React, { useState } from "react";
import { Text } from "../Text";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import styled from "styled-components";

const ViewMessage = styled(Text)`
  cursor: pointer;
`;

export const ReadMore = ({ text, id, status }) => {
  const axiosPrivate = useAxiosPrivate();
  const [isReadMore, setIsReadMore] = useState(
    status === "unread" ? true : false
  );
  const toggleReadMore = async (id) => {
    if (id) {
      try {
        await axiosPrivate.patch(`/notification/update/${id}`, {
          read: true,
        });
      } catch (err) {
        toast.error("Couldn't mark notification as read. An Error occured!");
      }
    }
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 0) : text}
      <ViewMessage
        color="#222"
        weight="bold"
        type="p"
        onClick={() => toggleReadMore(id)}
      >
        {isReadMore && "View message"}
      </ViewMessage>
    </p>
  );
};
