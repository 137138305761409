import React from "react";
import { Text } from "../../atoms";
import styled from "styled-components";
import { brandTheme, screen } from "../../../theme";

const Wrapper = styled.div`
  background-color: ${brandTheme.brand.backgroundSkin};
  min-height: 90vh;
  // padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;
  padding-top: 24px;
  width: 100%;

  @media only screen and (${screen.md}) {
    padding-bottom: 0;
  }
`;
const FormSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 85vh;
  width: 100%;
`;
const FormSection = styled.section`
  background-color: ${brandTheme.brand.backgroundSkin4};
  border-radius: 16px;
  padding: 16px;
  height: fit-content;

  @media only screen and (${screen.md}) {
    padding: 34px;
  }
`;
const TitleWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  @media only screen and (${screen.md}) {
    width: 70%;
  }
`;

export const StudentOnboardTemplate = ({ children, className }) => {
  return (
    <Wrapper>
      <FormSectionWrapper>
        <FormSection
          className={`flex flex-col justify-center items-center gap-4 rounded ${
            className || "w-full md:w-2/5"
          }`}
        >
          <TitleWrapper>
            <Text textalign="center" type="h2">
              Let’s get to know you more
            </Text>
            <p className="w-5/6 text-center">
              Our KYC process is a quick way for us to know how best to serve
              you!
            </p>
          </TitleWrapper>

          <div className="w-full">{children}</div>
        </FormSection>
      </FormSectionWrapper>
    </Wrapper>
  );
};
