import { useContext } from 'react'
import AuthContext from '../store/contexts/AuthProvider'
import useAxiosPrivate from './useAxiosPrivate'
import useAuth from './useAuth'
import { useNavigate } from 'react-router-dom'

const useLogout = () => {
    const auth = useAuth()
    const navigate = useNavigate()
    const { setAuth } = useContext(AuthContext)
    const axiosPrivate = useAxiosPrivate()
    const isStudent = auth?.accountType === 'Student'

    const logout = async () => {
        //TODO: Uncomment the code below when the have a /logout endpoint
        try {
            const response = await axiosPrivate.get('/account/logout')

            if (response.status === 200) {
                if (isStudent) {
                    navigate('/student-login')
                } else {
                    navigate('/agent-login')
                }
                setAuth({})
            }
        } catch (err) {
            console.error(err)
        }
    }

    return logout
}

export default useLogout
