import React, { useState } from "react";
import { Button, Icon, Input, Spinner } from "../../atoms";
import { brandTheme } from "../../../theme";
import { AuthTemplate } from "../../templates/AuthTemplate";
import { ResetPasswordLinkConfirmation } from "./ResetPasswordLinkConfirmation";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

export const ForgotPassword = () => {
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  async function sendResetLink() {
    setLoading(true)
    try {
      const response = await axios.post("/account/send-reset-link", {
        email,
      });

      if (response.data) setResetLinkSent(true);
    } catch (err) {
      toast.error(err.response.data.message);
    }
    setLoading(false)
  }

  return (
    <>
      {!resetLinkSent ? (
        <AuthTemplate
          title="Forgot password"
          subtitle="Please enter your email to receive a link to reset password"
        >
          <Input
            icon={<Icon type="email" />}
            type="email"
            size="md"
            placeholder="email"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            onClick={sendResetLink}
            width="100%"
            size="md"
            style={{color:"#fff"}}
            bgcolor={brandTheme.brand.secondary}
          >
            {loading ? <Spinner /> : 'Continue'}
          </Button>
        </AuthTemplate>
      ) : (
        <ResetPasswordLinkConfirmation />
      )}
    </>
  );
};
