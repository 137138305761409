import parsePhoneNumber from "libphonenumber-js";
import { countryIsoCodeMap } from "./constants";

export const pageAnimationConfig = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 1.4 },
};

export const toSentenceCase = (str) => {
  if (!str) return "";
  return str
    .toLowerCase()
    .replace(/\.\s+([a-z])[^.]|^(\s*[a-z])[^.]/g, (s) =>
      s.replace(/([a-z])/, (s) => s.toUpperCase())
    );
};

export function camelCaseToSeparateWords(str) {
  if (!str) return "";
  return str.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
}

// export function separatePhoneNumberString(phoneNumber) {
//   const firstDigit = phoneNumber.split("")[0] !== "+" ? "+" : "";
//   if (!phoneNumber || phoneNumber.length < 11) {
//     return {
//       code: null,
//       number: null,
//     };
//   }

//   return {
//     code: firstDigit + phoneNumber.slice(0, -10),
//     number: phoneNumber.slice(phoneNumber?.length - 10),
//   };
// }

export function separatePhoneNumberString(phoneNumber, countryCode = "NG") {
  const parsedPhoneNumber = getParsedPhoneNumber(phoneNumber, countryCode);

  if (!phoneNumber || !parsedPhoneNumber) {
    return {
      code: null,
      number: null,
    };
  }

  return {
    code: `+${parsedPhoneNumber.countryCallingCode}`,
    number: parsedPhoneNumber.nationalNumber,
  };
}

export function getParsedPhoneNumber(number, countryCode = "NG") {
  const phoneNumber = parsePhoneNumber(number, countryCode.toUpperCase());
  if (phoneNumber) {
    return phoneNumber;
  }
  return null;
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function formatNumber(price) {

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };
  return new Intl.NumberFormat().format(price);
}

export function isBVNValid(bvn) {
  var regex = new RegExp("^[0-9]+$");
  if (!bvn || bvn.length !== 11) return false;
  if (regex.test(bvn)) {
    return true;
  }
  return false;
}

export function formatSelectOptionForAntd(arrayOfStrings) {
  return (
    arrayOfStrings?.map((item) => ({
      label: item,
      value: item,
    })) || []
  );
}

export function limitString(text) {
  if (!text) return "";
  if (text.length > 17) return `${text.substring(0, 17)}...`;
  return text;
}


export function getReponsiveWidth(width) {
  if (window.screen.width) {
    const percentValue = (width * 100) / 1440;
    return `${percentValue}vw`
  }
}


export function filterBySearchTerm(searchTerm, fields){
  return fields.some((field)=> field.toLowerCase().includes(searchTerm.toLowerCase()) )
}


export function getCountryCode(country){
  for(let i= 0; i<countryIsoCodeMap.length; i++){
    if(countryIsoCodeMap[i].name?.toLowerCase() === country.toLowerCase()){
      return countryIsoCodeMap[i].code.toLowerCase();
    }
  }
}

export function matchesQuery(query){
  if(Array.isArray(query)){
    query.some((item)=>window.matchMedia(item))
  }else{
    return window.matchMedia(query)
  }
}

// export function filterByDateRange(rows, dateRange, dateFields){

// }

// export function filterBySingleDateRange(rows, dateRange, ){
//   const dateToCheck = new Date(date);
//   const startDate = new Date(dateRange[0]);
//   const endDate = new Date(dateRange[1]);
//   rows.filter((row)=>{
//     const dateField = 
//   })
//     return dateToCheck >= startDate && dateToCheck <= endDate;
// }