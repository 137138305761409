import React, { useContext, useState } from "react";
import { Button, PasswordInput, Spinner } from "../../atoms";
import { AuthTemplate } from "../../templates/AuthTemplate";
import { brandTheme } from "../../../theme";
// import { AccountCreationSuccess } from "./AccountCreationSuccess";
import axios from "../../../api/axios";
import AuthContext from "../../../store/contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const CreatePassword = ({ email, accountType }) => {
  // const [passwordCreated, setPasswordCreated] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { setAuth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  // create state to hold form data.
  const handleSubmit = async () => {
    // make request to register endpoint
    try {
      setLoading(true)
      const response = await axios.post("/account/register", {
        password,
        confirmPassword,
        email,
        accountType,
      });

      if (response.data) {
        setAuth(response.data);
        // setPasswordCreated(true);
        navigate("/onboard-student", { replace: true });
      }
    } catch (err) {
      toast(err.response.data.message);
    }
    setLoading(false)
  };

  return (
    <>
      {/* {!passwordCreated ?  */}
      <AuthTemplate
        title="Create password"
        subtitle="Combine special characters and symbols to create a strong password"
      >
        <PasswordInput
          size="md"
          placeholder="Enter password"
          autoComplete="off"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <PasswordInput
          size="md"
          placeholder="Confirm password"
          autoComplete="off"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          onClick={handleSubmit}
          width="100%"
          size="md"
          bgcolor={brandTheme.brand.secondary}
          style={{ color: "white" }}
        >
            {loading? <Spinner />: 'Create password'}
        </Button>
      </AuthTemplate>
      {/* : (
       <AccountCreationSuccess />
      )} */}
    </>
  );
};
