import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  formatSelectOptionForAntd,
  pageAnimationConfig,
  separatePhoneNumberString,
} from "../../../utils";
import { AntdSelect, Button, Icon, Input, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import { screen } from "../../../theme";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const FormInputs = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
const FormRow = styled.div`
  display: flex;
  gap: 16px;
`;

const Wrapper = styled(motion.div)``;
const WhiteArea = styled.div`
  display: block;
  padding: 48px 16px;
  gap: px;
  border-radius: 32px;
  background: ${africredColors.neutrals.white[10]};
  margin-top: 28px;
  height: fit-content;
  display: flex;
  justify-content: center;

  @media only screen and (${screen.xl}) {
    display: flex;
    padding: 48px 64px;
  }
`;
const LeftSide = styled.div`
  border-right: 0;

  // @media only screen and (${screen.xl}) {
  //   border-right: 1px solid ${africredColors.neutrals.night[200]};
  // }
`;

export const KYCSettings = () => {
  const axiosPrivate = useAxiosPrivate();
  const [settings, setSettings] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const auth = useAuth();
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [countryCode, setCountryCode] = useState("NG");

  async function handleUpdate() {
    if (settings && isPhoneValid) {
      try {
        const response = await axiosPrivate.patch("/account/kyc", {
          ...settings,
          phoneNumber: separatePhoneNumberString(phoneNumber, countryCode),
        });
        if (response.data) {
          toast.success("Update successful");
        }
      } catch (err) {
        toast.error(err.response.data.message);
      }
    } else {
      toast.error("Please provide valid phone number");
    }
  }

  useEffect(() => {
    async function fetchSettings() {
      try {
        const response = await axiosPrivate.get("/account/get-kyc-details");
        if (response.data) {
          setSettings(response.data);
          setPhoneNumber(
            `${response.data?.phoneNumber?.code}${response.data?.phoneNumber?.number}`
          );
        }
      } catch (err) {
        toast.error(err.response.data.message);
      }
    }

    fetchSettings();
  }, [axiosPrivate]);

  return (
    <Wrapper {...pageAnimationConfig}>
      <div className="flex justify-between items-center">
        <Text type="h3" weight="medium">
          KYC details
        </Text>
        <Button
          onClick={handleUpdate}
          size="md"
          border={`1px solid ${africredColors.neutrals.night[400]}`}
        >
          Update changes
        </Button>
      </div>
      {settings && (
        <WhiteArea>
          <LeftSide className="w-full md:w-3/4 xl:w-1/2 xl:pr-16">
            <Text type="h4" weight="medium">
              Update KYC details
            </Text>
            <div className="flex items-center gap-4 mt-4 mb-4">
              <Icon
                type="checked-circle"
                width={25}
                height={25}
                stroke={africredColors.primary.green[600]}
              />
              <Text type="p">KYC completed</Text>
            </div>
            <FormInputs>
              <FormRow>
                <Input
                  icon={<Icon type="person" />}
                  type="text"
                  size="md"
                  placeholder="First Name"
                  autoComplete="off"
                  value={settings.firstName}
                  onChange={(e) => {
                    setSettings((prev) => ({
                      ...prev,
                      firstName: e.target.value,
                    }));
                  }}
                />
                <Input
                  icon={<Icon type="person" />}
                  type="text"
                  size="md"
                  placeholder="Last Name"
                  autoComplete="off"
                  value={settings.lastName}
                  onChange={(e) => {
                    setSettings((prev) => ({
                      ...prev,
                      lastName: e.target.value,
                    }));
                  }}
                />
              </FormRow>

              <Input
                size="md"
                type="phone"
                value={phoneNumber}
                onChange={(val, country) => {
                  setPhoneNumber(val);
                  setCountryCode(country.countryCode);
                }}
                setIsPhoneValid={setIsPhoneValid}
              />

              <FormRow>
                <AntdSelect
                  options={formatSelectOptionForAntd([
                    "Male",
                    "Female",
                    "Not Specified",
                  ])}
                  value={settings.gender}
                  defaultValue={settings.gender}
                  setValue={(val) =>
                    setSettings((prev) => ({ ...prev, gender: val }))
                  }
                />
              </FormRow>

              <FormRow>
                <Input
                  type="text"
                  size="md"
                  padding="12px"
                  placeholder="City"
                  autoComplete="off"
                  value={settings.address.city}
                  onChange={(e) => {
                    setSettings((prev) => ({
                      ...prev,
                      address: { ...prev.address, city: e.target.value },
                    }));
                  }}
                />
                <Input
                  type="text"
                  size="md"
                  padding="12px"
                  placeholder="Postal/Zip Codes"
                  autoComplete="off"
                  value={settings.address.zip}
                  onChange={(e) => {
                    setSettings((prev) => ({
                      ...prev,
                      address: { ...prev.address, zip: e.target.value },
                    }));
                  }}
                />
              </FormRow>

              <Input
                type="text"
                size="md"
                padding="12px"
                placeholder="State"
                autoComplete="off"
                value={settings.address.state}
                onChange={(e) => {
                  setSettings((prev) => ({
                    ...prev,
                    address: { ...prev.address, state: e.target.value },
                  }));
                }}
              />

              <AntdSelect
                showSearch
                options={formatSelectOptionForAntd(
                  auth?.enumValues?.countryEnum
                )}
                value={settings.address?.country}
                defaultValue={settings.address?.country}
                setValue={(val) =>
                  setSettings((prev) => ({
                    ...prev,
                    address: { ...prev.address, country: val },
                  }))
                }
                size="md"
              />
            </FormInputs>
          </LeftSide>
        </WhiteArea>
      )}
    </Wrapper>
  );
};
