import { motion } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";

const SwitchWrapper = styled.div`
  width: 45px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 50px;
  padding: 10px 8px;
  cursor: pointer;

  &[data-isOn="true"] {
    justify-content: flex-end;
  }
`;

const SwichHandler = styled(motion.div)`
  width: 18px;
  height: 18px;
  background-color: black;
  border-radius: 50%;
`;

export const Switch = () => {
  const [isOn, setIsOn] = useState(false);

  const toggleSwitch = () => setIsOn(!isOn);

  return (
    <SwitchWrapper data-isOn={isOn} onClick={toggleSwitch}>
      <SwichHandler layout transition={spring} />
    </SwitchWrapper>
  );
};

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};
