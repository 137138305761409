import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Icon, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import { Accordion } from "../../molecules";
import { camelCaseToSeparateWords } from "../../../utils";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const TimeWrapper = styled.div`
  padding: 0 0 32px 24px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &:not(:last-child) {
    border-left: 2px solid
      ${({ completed }) =>
        completed
          ? africredColors.primary.green[600]
          : africredColors.neutrals.night[300]};
  }

  > .time-icon {
    position: absolute;
    top: 0;
    left: -13px;
    border-radius: 50%;
    padding: 5px;
  }

  > .title {
    margin-top: -2px;
  }
`;
const IconWrapper = styled.div`
  background-color: ${({ bgcolor }) => bgcolor || "white"};
`;
export const AttachDocumentButton = styled.button`
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 13px;
  border-radius: 8px;
  background: #eee;
`;
export const AttachedDocumentPreview = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
`;
export const AttachmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const LoadingBarWrapper = styled.div`
  width: 90%;
  height: 3px;
  background: ${africredColors.neutrals.night[100]};
`;
export const LoadingBar = styled.div`
  width: 50%;
  height: inherit;
  background: ${africredColors.primary.green[600]};
`;
const DocumentUploadInput = styled.input`
  width: 0;
  visibility: hidden;
  height: 0;
`;

export const RequestProgressIndicator = ({ data, requestType, refetch }) => {
  const inputImageRef = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const uploadURL =
    requestType?.toLowerCase() === "loan"
      ? "/loan/upload-document"
      : "/pof/upload-document";
  const [stageDocuments, setStageDocuments] = useState(data.approvalStages);

  function convertStagesDataToArray(data) {
    const keysArr = Object.keys(data);

    const newArr = keysArr.map((item) => ({
      title: item,
      ...data[item],
    }));

    return newArr;
  }

  const progress = convertStagesDataToArray(data.approvalStages)?.map(
    (item) => ({
      title: camelCaseToSeparateWords(
        item?.title.substring(0, item?.title.length - 5)
      ),
      icon: (
        <Icon
          type="checked-circle"
          stroke={
            item.completed
              ? africredColors.primary.green[600]
              : africredColors.neutrals.night[300]
          }
        />
      ),
      content: (
        <Accordion
          showIcon
          header={
            <Text
              type="p"
              color={africredColors.neutrals.night[300]}
              hovercolor={africredColors.neutrals.night[700]}
            >
              "AfriCred is currently reviewing you application. Further document
              upload is optional"
            </Text>
          }
          body={
            <AttachmentWrapper className="mt-6">
              <AttachDocumentButton
                onClick={() => inputImageRef?.current?.click?.()}
              >
                <Icon type="plus" /> Attach a document
              </AttachDocumentButton>
              <DocumentUploadInput
                ref={inputImageRef}
                type="file"
                accept="application/pdf,image/jpeg,image/png"
                onChange={(e) => {
                  const response = handlePhotoInput(e, item.title);
                  toast.promise(response, {
                    pending: "Uploading image",
                    success: "Upload successful 👌",
                    error: "Upload failed 🤯",
                  });
                }}
              />

              {stageDocuments[item.title]?.documents?.map((item) => {
                // {item?.documents?.map((item) => {
                const filenameArr = item.Path.split("/");
                const filename = filenameArr[filenameArr.length - 1];
                return (
                  <AttachedDocumentPreview>
                    <div className="w-full flex justify-between">
                      <div className="flex flex-col gap-1">
                        <Text type="p" weight="medium">
                          {filename}
                        </Text>
                        <Text>{item.Size}KB</Text>
                      </div>

                      <div className="flex gap-2">
                        {/* <Icon type="half-loading" /> */}
                        <a
                          href={item.Location}
                          download={filename}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Icon type="download-doc" />
                        </a>

                        <Icon type="bin" stroke="#FF677B" />
                      </div>
                    </div>
                    {/* <div className="flex items-center w-full gap-4">
                      <LoadingBarWrapper>
                        <LoadingBar />
                      </LoadingBarWrapper>
                      <Text>50%</Text>
                    </div> */}
                  </AttachedDocumentPreview>
                );
              })}
            </AttachmentWrapper>
          }
        />
      ),
      completed: item.completed,
    })
  );

  async function handlePhotoInput(e, stage) {
    const imageFormData = new FormData();
    imageFormData.append("file", e.target.files[0]);

    const response = await axiosPrivate.post(
      "/file/upload-document",
      imageFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );

    if (response.data) {
      setStageDocuments((prev) => ({
        ...prev,
        [stage]: {
          ...prev?.[stage],
          documents: [...prev?.[stage].documents, response.data],
        },
      }));
      const stageUpdateResponse = await axiosPrivate.patch(
        `${uploadURL}/${data.id}`,
        {
          stageName: stage,
          documentId: response.data.id,
        }
      );

      if (stageUpdateResponse.data) {
        refetch();
        toast.success("Document upload successfull");
      }
    }
  }

  return (
    <>
      {progress.map((item, idx) => (
        <TimeWrapper completed={item.completed} key={idx}>
          <Text type="h5" className="title">
            {item.title}
          </Text>
          {item.content}
          <IconWrapper
            bgcolor={
              item?.completed
                ? africredColors.primary.green[50]
                : africredColors.neutrals.night[80]
            }
            className="time-icon"
          >
            {item.icon}
          </IconWrapper>
        </TimeWrapper>
      ))}
    </>
  );
};
