import { useState } from 'react'
import { africredColors } from '../../../theme/colors'
import { brandTheme, screen } from '../../../theme'
import styled from 'styled-components'
import { AuthTemplate } from '../../templates/AuthTemplate'
import { Button, Text } from '../../atoms'
import { separatePhoneNumberString } from '../../../utils'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import axiosPublic from '../../../api/axios'
import { useTimeCountdown } from '../../../hooks/useTimeCountdown'
import { toast } from 'react-toastify'

const OTPInputWrapper = styled.div`
    display: flex;
    gap: 16px;
    width: 70%;
    justify-content: center;
`
const OTPInput = styled.input`
    display: flex;
    width: 64px;
    height: 56px;
    padding: 18px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: #eee;
    text-align: center;

    &:focus {
        outline: 1px solid ${africredColors.secondary.red[600]};
        background: #fff1f3;
    }

    @media only screen and (${screen.md}) {
        width: 78px;
    }
`
const ResendButton = styled.button`
    border: 0;
    background: transparent;
    text-decoration: underline;
    padding-bottom: 4px;
    font-weight: bold;
`

export const OTPComponent = ({
    id,
    setOTPSuccess,
    useCase = 'signup',
    phoneNumber,
    countryCode,
    email,
    buttonStyles,
    onSuccess,
    verifyURL,
    resendURL,
    usePublicAxios,
}) => {
    const [otpID, setOTPId] = useState(id)
    const [otp, setOtp] = useState(['', '', '', ''])
    const [loading, setLoading] = useState(false)
    const axiosPrivate = useAxiosPrivate()
    const { counter, setCounter } = useTimeCountdown()

    async function verifyEmailOTP() {
        return await axiosPrivate.patch(
            verifyURL || `/account/verify-and-update-email`,
            {
                email: email,
                otp: { code: otp.join(''), id: otpID },
            },
        )
    }

    async function verifyEmailOnSignup() {
        return await axiosPublic.post(verifyURL || `/account/verify-otp`, {
            code: otp.join(''),
            id: otpID,
        })
    }

    async function verifyPhoneOTP() {
        const axiosApi = usePublicAxios ? axiosPublic : axiosPrivate

        return await axiosApi.patch(
            verifyURL || `/account/verify-phone-number-otp`,
            {
                phoneNumber: separatePhoneNumberString(phoneNumber, countryCode),
                otp: {
                    id: otpID,
                    code: otp.join(''),
                },
            },
        )
    }

    async function confirmOTP() {
        try {
            setLoading(true)
            const response =
                useCase === 'signup'
                    ? await verifyEmailOnSignup()
                    : useCase === 'changeEmail'
                    ? await verifyEmailOTP()
                    : await verifyPhoneOTP()

            setLoading(false)
            if (response.data) {
                setOTPSuccess(true)
                onSuccess && onSuccess()
            }
        } catch (err) {
            setLoading(false)
            toast.error(err.response.data.message)
            setOtp(['', '', '', ''])
        }
    }

    function isInputValid(value) {
        return isNaN(value) ? false : true
    }

    function handleInputChange(e, idx) {
        if (isInputValid(e.target.value)) {
            setOtp(prev => {
                if (e.target.value.length === 4) return e.target.value.split('')
                return prev.map((item, index) => {
                    const inputValArr = e.target.value.split('')
                    if (index === idx) {
                        if (inputValArr.length > 1)
                            return inputValArr[inputValArr.length - 1]

                        return e.target.value
                    } else {
                        return item
                    }
                })
            })

            if (e.target.nextSibling) {
                e.target.nextSibling.focus()
            }
        }
    }

    async function resendOTP() {
        try {
            const response = await axiosPublic.post(
                resendURL || `/account/send-otp/${email}`,
            )

            if (response.data) {
                setOTPId(response.data.id)
                setCounter(59)
                toast.success('OTP sent...')
            }
        } catch (e) {
            toast.error('An error occured...')
        }
    }

    async function resendPhoneOTP() {
        try {
            const axiosApi = usePublicAxios ? axiosPublic : axiosPrivate

            const response = await axiosApi.post(
                resendURL || `/account/send-phone-number-otp`,
                {
                    phoneNumber,
                },
            )

            if (response.data) {
                setOTPId(response.data.otp.id)
                setCounter(59)
                toast.success('Phone OTP sent')
            }
        } catch (err) {
            toast.error(err.response.data.message)
        }
    }

    return (
        <AuthTemplate
            title="Enter the 4-digit code"
            subtitle={`We sent a message to ${
                email || phoneNumber || 'your email'
            }  with a code to verify your account`}
        >
            <OTPInputWrapper>
                {otp.map((val, idx) => (
                    <OTPInput
                        key={idx}
                        autoComplete="off"
                        value={val}
                        onChange={e => handleInputChange(e, idx)}
                        onFocus={e => e.target.select()}
                    />
                ))}
            </OTPInputWrapper>

            {counter > 0 ? (
                <Text type="p" margin="2rem 0">
                    Resend code in: {counter}s
                </Text>
            ) : (
                <ResendButton
                    onClick={phoneNumber ? resendPhoneOTP : resendOTP}
                >
                    Resend OTP
                </ResendButton>
            )}

            <Button
                loading={loading}
                onClick={confirmOTP}
                width="100%"
                size="md"
                bgcolor={brandTheme.brand.secondary}
                style={{ color: "white" }}
                {...buttonStyles}
            >
                Verify code
            </Button>
        </AuthTemplate>
    )
}
