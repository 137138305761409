import React, { useState } from "react";
import { Icon } from "../../atoms/Icon";
import styled from "styled-components";

const Wrapper = styled.div``;

export const Accordion = ({ header, showIcon, body, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <Wrapper>
      <div
        className="flex justify-between items-start"
        onClick={() => setOpen((prev) => !prev)}
      >
        <div className="cursor-pointer">{header}</div>
        {showIcon && (
          <div>
            <Icon type="caret" />
          </div>
        )}
      </div>
      {open && <div>{body}</div>}
    </Wrapper>
  );
};
