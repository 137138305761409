import React from "react";
import styled from "styled-components";
import { Icon, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import useAuth from "../../../hooks/useAuth";

const TimeWrapper = styled.div`
  padding: 0 0 32px 24px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &:not(:last-child) {
    border-left: 2px solid
      ${({ borderLeft }) => borderLeft || africredColors.neutrals.night[80]};
  }

  > .time-icon {
    position: absolute;
    top: 0;
    left: -13px;
    border-radius: 50%;
    padding: 5px;
  }

  > .title {
    margin-top: -2px;
  }
`;
const IconWrapper = styled.div`
  background-color: ${({ bgcolor }) => bgcolor || "white"};
  margin-left: -5px;
`;

export const TimelineIndicator = ({ data }) => {
  const auth = useAuth();
  return (
    <>
      {data.map((item, idx) => (
        <TimeWrapper
          key={idx}
          borderLeft={
            auth.agentLevel === item.title
              ? africredColors.primary.green[100]
              : africredColors.neutrals.night[80]
          }
        >
          <Text type="h5" className="title">
            {item.title}
          </Text>

          {item.content}
          <IconWrapper
            bgcolor={
              auth.agentLevel === item.title
                ? africredColors.primary.green[100]
                : africredColors.neutrals.night[80]
            }
            className="time-icon"
          >
            {item.title === "Silver" ? (
              <Icon type="silver-agent" />
            ) : item.title === "Gold" ? (
              <Icon type="gold-agent" />
            ) : (
              <Icon type="diamond-agent" />
            )}
          </IconWrapper>
        </TimeWrapper>
      ))}
    </>
  );
};
