import { inputSizes } from "../theme/typeScale";

const { lg, md, sm, xs } = inputSizes;

export function resolveSize(size) {
  if (size === "lg") return lg;
  if (size === "md") return md;
  if (size === "sm") return sm;
  return xs;
}
