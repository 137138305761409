import React, { useState } from 'react';
import styled from 'styled-components';
import { DatePicker } from 'antd'
import { screen } from '../../../theme';
import { Button, Icon, Input, SelectInput, Text } from '../../atoms';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { africredColors } from '../../../theme/colors';
import { motion } from 'framer-motion';
import { filterBySearchTerm, formatNumber, getReponsiveWidth } from '../../../utils';
import { Table } from '../../molecules';
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { axiosPrivate } from '../../../api/axios';
import useAuth from '../../../hooks/useAuth';


const Wrapper = styled(motion.div)`
    display: flex;
    width: 100%;
    gap: 20px;
    flex-direction: column;
    @media only screen and (${screen.lg}) {
    flex-direction: row;
  }
`

const EarningsBalanceWrapper = styled.div`
  padding:8px;
  display:flex;
  width:100%;
  gap:20px;
  background-color:${africredColors.neutrals.night['200']};
  border-radius:16px;

  > div{
    display:flex;
    flex-direction:column;
    width:50%;
    padding:16px;
    border-radius:8px;
  }
`

const Header = styled.div`
  display:flex;
  justify-content:space-between;
  margin-bottom: ${getReponsiveWidth(24)}
`

const EarningsTableWrapper = styled(motion.div)`
    display: flex;
    width:100%;
    padding:${getReponsiveWidth(20)};
    flex-direction: column;
    background-color:${africredColors.neutrals.white[10]};
    border-radius:36px;
    height:fit-content;
    @media only screen and (${screen.lg}) {
        width: ${getReponsiveWidth(764)};
    }
    @media only screen and (min-width: 991px) and (max-width: 1150px) {
        width: 66.67%;
    }
`
const WidrawalRequestWrapper = styled(motion.div)`
    display: flex;
    width: 100%;
    gap: 20px;
    padding:20px;
    flex-direction: column;
    background-color:${africredColors.neutrals.white[10]};
    border-radius:36px;
    @media only screen and (${screen.lg}) {
        width: ${getReponsiveWidth(371)};
    }
    @media only screen and (min-width: 991px) and (max-width: 1150px) {
        width: 33.33%;
    }
`
const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
  height:fit-content;
`;

function renderHeader(params) {
    return (
        <Text type="p" weight="bold">
            {params.colDef.headerName}
        </Text>
    );
}
const renderCell = ({ value, row }) => {
    return <Text type="p" color={africredColors.neutrals.night[500]}>
        {value}
    </Text>
}
const columns = [
    {
        field: "earningsType",
        headerName: "Type",
        minWidth: 160,
        flex: 2,
        renderHeader,
        renderCell: ({ value, row }) => {
            return <div className={`flex gap-5 items-center`}>
                <div className={`flex items-center justify-center p-3 bg-[${africredColors.neutrals.white["100"]}] rounded-2xl h-fit`}>
                    {row.type === "widthdrawal" ?
                        <Icon type="arrow-up-45deg" width={15} height={15} /> :
                        <Icon type="arrow-down-45deg" width={15} height={15} />
                    }
                </div>
                <Text type="p" weight="bold">
                    {value}
                </Text>
            </div>
        }
    },
    {
        field: "referenceNumber",
        headerName: "Ref",
        minWidth: 160,
        flex: 2,
        renderHeader,
        renderCell
    },
    {
        field: "amount",
        headerName: "Amount",
        minWidth: 160,
        flex: 2,
        renderHeader,
        renderCell: ({ value, row }) => {
            return <Text type="p" color={africredColors.neutrals.night[500]}>
                ${formatNumber(value)}
            </Text>
        }
    },
    {
        field: "updatedAt",
        headerName: "Date",
        minWidth: 160,
        flex: 2,
        renderHeader,
        renderCell: ({ value, row }) => {
            return <Text type="p" color={africredColors.neutrals.night[500]}>
                {format(new Date(value), "do MMM, yyyy hh:mm:ss aaa")}
            </Text>
        }
    },
]


// const rows = [
//     {
//         id: 1,
//         referenceNumber: "TRX12345",
//         earningsType: "widthdrawal",
//         amount: 34.99,
//         updatedAt: "2024-05-20T12:19:26.089Z"

//     },

//     {
//         id: 2,
//         referenceNumber: "TRX12345",
//         earningsType: "widthdrawal",
//         amount: 34.99,
//         updatedAt: "2024-05-07T12:19:26.089Z"

//     },
//     {
//         id: 3,
//         earningsType: "Short Term Loan",
//         referenceNumber: "jibberish0",
//         amount: 34.99,
//         updatedAt: "2024-05-07T12:19:26.089Z"

//     },
//     {
//         id: 4,
//         earningsType: "Remittance",
//         referenceNumber: "jibberish0",
//         amount: 34.99,
//         updatedAt: "2024-05-07T12:19:26.089Z"

//     },
//     {
//         id: 5,
//         earningsType: "widthdrawal",
//         referenceNumber: "jibberish0",
//         amount: 34.99,
//         updatedAt: "2024-05-07T12:19:26.089Z"

//     }
// ]

// const widthwalRequest = [
//     {
//         amount: 55,
//         date: "2024-05-07T12:19:26.089Z",
//         status: "pending"
//     },
//     {
//         amount: 55,
//         date: "2024-05-19T12:19:26.089Z",
//         status: "pending"
//     },
//     {
//         amount: 55,
//         date: "2024-05-07T12:19:26.089Z",
//         status: "pending"
//     },
//     {
//         amount: 55,
//         date: "2024-05-07T12:19:26.089Z",
//         status: "pending"
//     },
//     {
//         amount: 55,
//         date: "2024-05-07T12:19:26.089Z",
//         status: "pending"
//     },
//     {
//         amount: 55,
//         date: "2024-05-07T12:19:26.089Z",
//         status: "pending"
//     },
//     {
//         amount: 55,
//         date: "2024-05-07T12:19:26.089Z",
//         status: "pending"
//     },
//     {
//         amount: 55,
//         date: "2024-05-07T12:19:26.089Z",
//         status: "pending"
//     }
// ]

const earningsTypeOptions =['All', 'Remittance', 'Widthdrawal']

export const Earnings = () => {
    const { isMobile } = useScreenSize();
    const auth = useAuth();
    const navigate = useNavigate();
    // const [earningsType, setEarningType] = useState();

    const [filterData, setFilterData] = useState({
        earningsType: earningsTypeOptions[0],
        dateRange: null,
        search: ""
    });
    
    const {
        data: earningsData,
        isLoading:earningsDataIsLoading
    } = useQuery({
        queryKey: ['earning-data'],
        queryFn: async () => {
            const response = await axiosPrivate(`/earnings/search?account[0]=${auth.id}`)
            if(response?.data){
                return response?.data?.foundEarnings
            }
            
        },
    })

    const {
        data: payoutHistory,
    } = useQuery({
        queryKey: ['payout-history'],
        queryFn: async () => {
            const response = await axiosPrivate('/payout/search?account[0]=${auth.id}')
            if(response?.data){
                return response.data
            }
            
        },
    })

    return (
        <div>
            <Header className='flex flex-col sm:flex-row'>
                <Text type="h3" weight="bold"> Earnings</Text>
                <Button
                    size="sm"
                    border={`1px solid ${africredColors.neutrals.night[950]}`}
                    onClick={() => navigate('/widthdrawal-request')}
                >
                    {!isMobile && <Icon type="add-plain" />}

                    <Text type="p" weight="medium">
                        Widthdrawal Request
                    </Text>
                </Button>
            </Header>
            <Wrapper>
                <EarningsTableWrapper>
                    <EarningsFilter filterData={filterData} setFilterData={setFilterData} selectOptions={earningsTypeOptions} />
                    <TableWrapper>
                        {earningsDataIsLoading? <EarningsLoading /> :<Table columns={columns} rows={filterEarningsData(earningsData, filterData)} pageSize={7} />}
                    </TableWrapper>
                </EarningsTableWrapper>
                <WidrawalRequestWrapper>
                    <EarningsBalanceWrapper>
                        <div className='bg-white'>
                            <Text type="h4" weight="Medium" > ${auth?.currentEarnings > 1000? `${auth?.currentEarnings/1000}K`:auth?.currentEarnings}</Text>
                            <Text type="p" color={`${africredColors.neutrals.night['500']}`} > current Balance</Text>
                        </div>
                        <div>
                            <Text type="h4" weight="Medium" > ${auth?.totalEarnings > 1000? `${auth?.totalEarnings/1000}K`:auth?.totalEarnings}</Text>
                            <Text type="p" color={`${africredColors.neutrals.night['500']}`} > Total Earnings</Text>
                        </div>

                    </EarningsBalanceWrapper>
                    <Button
                        size="sm"
                        border={`1px solid ${africredColors.neutrals.night[950]}`}
                        onClick={() => navigate('/widthdrawal-request')}
                    >
                        {!isMobile && <Icon type="add-plain" />}

                        <Text type="p" weight="medium">
                            Withdrawal Request
                        </Text>
                    </Button>
                    <Text type="p" weight="medium">
                        Withdrawal Request
                    </Text>

                    {payoutHistory?.foundPayoutRequests?.slice(0, 5).map((item) => (<WidthdrawalCard amount={item.amountToWithdrawInCurrency} date={item.date} status={item.status} />))}
                </WidrawalRequestWrapper>
            </Wrapper>
        </div>
    )
}



const RightFilter = styled.div`
    display:flex;
    gap:${getReponsiveWidth(22)};
    .ant-picker-suffix{
        display:none;
    }
`
const { RangePicker } = DatePicker;
function EarningsFilter({ filterData, setFilterData, selectOptions }) {
    
    return (
        <div className='flex space-between'>
            <Input
                type="search"
                width={`${getReponsiveWidth(253)}`}
                size="sm"
                value={filterData?.searchTerm}
                icon={<Icon type="search" />}
                paddingleft="3rem"
                bgcolor="transparent"
                placeholder="search"
                className="border"
                style={{ flex: 1 }}
                onChange={(e) =>
                    setFilterData((prev) => ({ ...prev, searchTerm: e.target.value }))
                }
                border={`1px solid ${africredColors.neutrals.night[300]}`}
            />

            <RightFilter>
                <RangePicker
                    style={{
                        borderRadius: '30px',
                        outline: 'none',
                        width: `${getReponsiveWidth(254)}`,
                        border: `1px solid ${africredColors.neutrals.night[300]}`,
                    }}
                    value={filterData.dateRange}
                    onChange={(val, strArr) => {
                        setFilterData(prev => ({ ...prev, dateRange: val }))
                    }}
                />
                <SelectInput
                    width ="fit-content"
                    options={selectOptions}
                    value={filterData?.earningsType || "Type"}
                    setValue={(val) => setFilterData((prev) => ({ ...prev, earningsType: val }))}
                    size="sm"
                    bgcolor="transparent"
                    border={`1px solid ${africredColors.neutrals.night[300]}`}
                />
            </RightFilter>

        </div>
    )
}

function filterEarningsData(data, filterData){
    let clonedData = Array.isArray(data)? [...data]: [];
    if(filterData?.searchTerm){
        clonedData = clonedData.filter((row)=>{
            return filterBySearchTerm(filterData?.searchTerm, [row.earningsType, row.referenceNumber]);
        })
    }
    if(filterData?.earningsType !== "All"){
        clonedData = clonedData.filter((row)=>filterBySearchTerm(filterData?.earningsType, [row.earningsType]))
    }
    if(filterData?.dateRange && Array.isArray(filterData?.dateRange)){
        clonedData = clonedData.filter((row, index)=>{
            const dateToCheck = new Date(row['updatedAt']);
            const startDate = new Date(filterData?.dateRange[0]);
            const endDate = new Date(filterData?.dateRange[1]);
            return dateToCheck >= startDate && dateToCheck <= endDate;
            // return dateToCheckvalueOf() >= startDate.valueOf() && dateToCheck.valueOf() <= endDate.valueOf();
        })
        
    }

    return clonedData;
}


function WidthdrawalCard({ date, amount, status }) {
    const { isMobile } = useScreenSize();
    return (
        <div className='flex p-4 border rounded-2xl w-full justify-between items-center'>
            <div className='flex'>
                <div className={`flex items-center justify-center p-3 bg-[${status==="Pending"? africredColors.neutrals.white["100"]:africredColors.primary.green["200"]}] ${status==="Pending"? "": "text-white"} rounded-2xl h-fit`}>
                    <Icon type="arrow-up-45deg" width={15} height={15} />
                </div>
                <div className='flex flex-col ml-4'>
                    <Text type="p" weight="bold">{`$${amount}`}</Text>
                    <Text type="p" color={`${africredColors.neutrals.night["500"]}`}>Widthdrawal Request</Text>
                    <Text type="p" color={`${africredColors.neutrals.night["500"]}`}>{format(new Date(date), "do, MMM yyyy")}</Text>
                </div>
            </div>

            <div className={`flex gap-[5.3px] bg-[${africredColors.primary.gold["300"]}] py-[6px] px-2 items-center justify-center rounded-2xl h-fit w-fit self-end`}>
                {!isMobile && <Icon type="pending-loading" />}
                <Text type="p">
                    {status}
                </Text>
            </div>

        </div>
    )
}




function getWithdrawalStatusColor(status){

}

function EarningsLoading(){
    return (
        <div role="status" class="max-w-sm animate-pulse">
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-500 w-48 mb-4"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-500 max-w-[360px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-500 mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-500 max-w-[330px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-500 max-w-[300px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-500 max-w-[360px]"></div>
            <span class="sr-only">Loading...</span>
        </div>
    )
}