import { motion } from 'framer-motion';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { screen } from '../../../theme';
import { africredColors } from '../../../theme/colors';
import { Icon, SelectInput, Text } from '../../atoms';
import { Table } from '../../molecules';
import { agentDashboardData, latestTransactionColumn, latestTransactionRow, rewardHistoryRow } from './constants';
import { useQuery } from '@tanstack/react-query';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
    plugins,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom/dist';
import useAuth from '../../../hooks/useAuth';
import { format } from 'date-fns';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const ArrowStatus = styled(Icon)`
    > g {
        stroke: ${africredColors.primary.green["500"]}
    }
`

const chartOptions =  {
    responsive: true,
    scales:{
        y:{
            border: {
                display: false
            },
            grid:{
                display:false,
                drawOnChartArea:false,
                drawTicks:false,
                
            },
            
        },
        x:{
            border: {
                display: false
            },
        }
    },
    plugins: {
        legend: {
            position: 'top',
            display: false,
        },
        

        title: {
            display: false,
            text: 'Chart.js Bar Chart',
        },
        tooltip:{
            backgroundColor:'#fff',
            titleColor:`${africredColors.neutrals.night["950"]}`,
            bodyColor:africredColors.neutrals.night["950"],
            titleAlign:"center",
            bodyAlign: "center",
            yAlign: "bottom",
            xAlign:"center",
            caretSize:10,
            usePointStyle:true,
            callbacks:{
                title:function(context){
                   return `$${(context[0]?.raw)/1000}K`
                },
                label: function(context) {
                    
                    let dataIndex = context.dataIndex;
                    return ' 34 Applications '
                    
                },
                
            }
        },
    },
};

const labels = ['Mon', 'Tue', 'Wed', 'Thur', 'Fir', 'Sat', 'Sun'];


const earningsChartData = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: [14000, 9000, 25000, 15000, 20000, 7000, 18000],
            backgroundColor: africredColors.primary.blue[200],
            hoverBackgroundColor: "#413fe5",
            barPercentage:0.5,
        },
    ],
};




export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: [14000, 9000, 25000, 15000, 20000, 7000, 18000],
            backgroundColor: africredColors.primary.blue[200],
            hoverBackgroundColor: "#413fe5",
            barPercentage:0.5,
        },
    ],

};

function getReponsiveWidth(width) {
    if (window.screen.width) {
        const percentValue = (width * 100) / 1440;
        return `${percentValue}vw`
    }
}

const Wrapper = styled(motion.div)`
    display: flex;
    width: 100%;
    gap: 20px;
    flex-direction: column;
    @media only screen and (${screen.lg}) {
    flex-direction: row;
  }
`
const Overview = styled(motion.div)`
    display: flex;
    width:100%;
    padding:20px;
    flex-direction: column;
    background-color:${africredColors.neutrals.white[10]};
    border-radius:36px;
    @media only screen and (${screen.lg}) {
        width: ${getReponsiveWidth(666)};
    }
    @media only screen and (min-width: 991px) and (max-width: 1150px) {
        width: 50%;
    }
`
const Earnings = styled(motion.div)`
    display: flex;
    width: 100%;
    gap: 20px;
    padding:20px;
    flex-direction: column;
    background-color:${africredColors.neutrals.white[10]};
    border-radius:36px;
    @media only screen and (${screen.lg}) {
        width: ${getReponsiveWidth(460)};
    }
    @media only screen and (min-width: 991px) and (max-width: 1150px) {
        width: 50%;
    }
`

const EarningsBalanceWrapper = styled.div`
  padding:8px;
  display:flex;
  width:100%;
  gap:20px;
  background-color:${africredColors.neutrals.night['200']};
  border-radius:16px;

  > div{
    display:flex;
    flex-direction:column;
    width:50%;
    padding:16px;
    border-radius:8px;
  }
`

const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
  max-height: calc(75vh - 94px);
`;

const highlightStyles = css`
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
`

const Highlight = styled.div`
    ${highlightStyles};
    flex-direction: column;

    @media only screen and (${screen.lg}) {
        border-radius: 32px;
        display: flex;
        flex-direction: row;
    }
`


const StatIcon = styled.div`
  borderRadius:50%;
  padding:12px;

`
const TransactionStatsItem = styled.div`
  display:flex;
  gap:16px;
  height:58px;
`

const RewardHistory = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    border-bottom: 1px solid ${africredColors.neutrals.night[200]};
    padding-bottom: 16px;
`
// 'Last 30 Days', 'Last 3 months','Last Year'
const overviewOptions = ['Last 6 months']
const statusOptions = ['Status']
const transactionOptions = ["All", "Loan", 'Pof', 'Remittance', 'Withdrawal', "Admission"]
// [
//     "InReview",
//     "Sent To Partner",
//     "Has EOI",
//     "Logged In",
//     "Rejected",
//     "Disbursed"
// ]
const statusMap = {
    "In Review": "InReview",
    "Logged In": "Logged In",
    "Rejected" : "Rejected",
    "Disbursed" : "Disbursed"
}

export const AgentDashboard = () => {
    const auth = useAuth();
    // const analysisStatus = auth?.enumValues?.adminAnalysisStatusEnum;
    const analysisStatus = Object.keys(statusMap);
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const dashboardData = agentDashboardData;
    const [filterData, setFilterData] = useState();
    const [overviewPeriod, setOverviewPeriod] = useState(overviewOptions[0]);
    const [earningsGraphPeriod, setEarningsGraphPeriod] = useState(overviewOptions[0]);
    const [transactionOption, setTransactionOption] = useState(transactionOptions[0])
    const [status, setStatus] = useState("");
    const [transactionStats, setTransactionStats] = useState({});
    const {
        isPending,
        error,
        isLoading:dashboardDataIsLoading,
        data: checkData,
    } = useQuery({
        queryKey: ['agent-dashboard-data'],
        queryFn: async () => {
            let checkData = null;
            const response = await axiosPrivate('/transaction/agent-dashboard')
            if(response?.data){
                checkData = response?.data
                checkData.transactionStats = getTransactionStatFromResponse(checkData?.transactionStats, checkData?.admissionStats);
            }
            return checkData;
        },
    })

    const { isPending: statLoading, data: stats } = useQuery({
        queryKey: ["fetch-stats", transactionOption, overviewPeriod],
        queryFn: async () => {
          const response = await axiosPrivate(
            `/transaction/stats?transactionType=${
                transactionOption === "Short term loans" ? "Pof" : transactionOption
            }&range=${overviewPeriod}`
          );
          return response.data;
        },
    });

    const { isPending: agentStatLoading, data: agentStat } = useQuery({
        queryKey: ["agent-stats", transactionOption, status],
        queryFn: async () => {
          const response = await axiosPrivate(
            `/transaction/agent-stats?transactionType=${
                transactionOption === "Short term loans" ? "Pof" : transactionOption
            }&status=${statusMap[status]}`
          );
          return response.data;
        },
        enabled:transactionOption !== "All"
    });
    

    const earningsBalance = [
        {
            text: 'Current Balance',
            amount: auth?.currentEarnings,
        },
        {
            text: 'Total Earnings',
            amount: auth?.totalEarnings,
        }
    ]

    const highlightData = [
        {
            icon: <Icon type="card-sm" />,
            amount: dashboardData.transactionStats?.totalRemittances,
            text: 'Remittance',
            color: africredColors.primary.blue[400]
        },
        {
            icon: <Icon type="wallet-sm" />,
            amount: dashboardData.transactionStats?.totalLoans,
            text: 'Loans',
            color: africredColors.secondary.purple[400]
        },
        {
            icon: <Icon type="proof-of-funds-sm" />,
            amount: dashboardData.transactionStats?.totalTransactions,
            text: 'Short Term Loans',
            color: africredColors.primary.gold[400]
        },


    ]
    return (
        <Wrapper>
            <Overview>
                <div className='flex flex-col sm:flex-row sm:justify-between mb-6'>
                    <Text type="h4" > Overview</Text>
                    <SelectInput
                        width="fit-content"
                        options={overviewOptions} value={overviewPeriod} size="sm"
                        setValue={(val) => setOverviewPeriod(val)}
                        bgcolor="transparent"
                        border={`1px solid ${africredColors.neutrals.night[300]}`}
                        dropdownStyle={{height:'fit-content', maxHeight: "50vh", top:"55px"}}
                    />
                </div>
                <Highlight >
                    {checkData?.transactionStats.map(item => (
                        <TransactionStatsItem>
                            <div className={`rounded-full p-3 bg-[${item.color}] flex items-center justify-center w-12 h-12`}>{item.icon}</div>
                            <div>
                                <Text type="h5" weight="medium">{item.amount}</Text>
                                <Text type="p" color={africredColors.neutrals.night[600]}>{item.text}</Text>
                            </div>
                        </TransactionStatsItem>
                    ))}
                </Highlight>

                <div className='flex justify-between items-center mt-6'>
                    <Text type="h4" > Activity</Text>
                    <div className='flex flex-col sm:flex-row items-end gap-4'>
                        
                        <SelectInput
                            width="fit-content"
                            options={transactionOptions} value={transactionOption} size="sm"
                            setValue={(val) => setTransactionOption(val)}
                            bgcolor="transparent"
                            border={`1px solid ${africredColors.neutrals.night[300]}`}
                            dropdownStyle={{height:'fit-content', maxHeight: "50vh", top:"55px"}}
                        />
                        <SelectInput
                            width="fit-content"
                            options={analysisStatus} value={status || "Status"} size="sm"
                            setValue={(val) => setStatus(val)}
                            bgcolor="transparent"
                            border={`1px solid ${africredColors.neutrals.night[300]}`}
                            dropdownStyle={{height:'fit-content', maxHeight: "50vh", top:"55px"}}
                        />
                    </div>
                </div>
                {/* ACTIVITY CHART */}
                {dashboardDataIsLoading ? <GraphLoading /> :<Bar options={getTransactionChartOption(checkData,overviewPeriod)} data={getTransactionDataPoints(transactionOption=== "All"? checkData: agentStat, overviewPeriod, transactionOption)} />}

                <div className='flex justify-between items-baseline mt-6'>
                    <Text type="h5" weight="bold" > Latest Transactions</Text>
                    <Text type="small" weight="Medium" color={africredColors.neutrals.night[600]} onClick={() => navigate('/transactions')} style={{cursor:'pointer'}}> view all</Text>
                </div>

                <TableWrapper>
                    <Table
                        columns={latestTransactionColumn}
                        rows={Array.isArray(checkData?.latestTransactions)? checkData?.latestTransactions :[]}
                        hidePagination={true}
                    />
                </TableWrapper>
            </Overview>
            <Earnings>
                <div className='flex justify-between'>
                    <Text type="h4" > Earnings</Text>
                    <SelectInput
                        width="fit-content"
                        options={overviewOptions} value={earningsGraphPeriod} size="sm"
                        bgcolor="transparent"
                        border={`1px solid ${africredColors.neutrals.night[300]}`}
                        setValue={(val) => setEarningsGraphPeriod(val)}
                        dropdownStyle={{height:'fit-content', maxHeight: "50vh", top:"55px"}}
                    />
                </div>
                <EarningsBalanceWrapper>
                    <div className='bg-white'>
                        <Text type="h4" weight="Medium" >${auth?.currentEarnings > 1000? `${auth?.currentEarnings/1000}K`:auth?.currentEarnings}</Text>
                        <Text type="p" color={`${africredColors.neutrals.night['500']}`} > current Balance</Text>
                    </div>
                    <div>
                        <Text type="h4" weight="Medium" >  ${auth?.totalEarnings > 1000? `${auth?.totalEarnings/1000}K`:auth?.totalEarnings}</Text>
                        <Text type="p" color={`${africredColors.neutrals.night['500']}`} > Total Earnings</Text>
                    </div>

                </EarningsBalanceWrapper>
               { dashboardDataIsLoading? <GraphLoading /> :<Bar options={getEarningsChartOption(checkData, earningsGraphPeriod)} data={getEarningsDataPoints(checkData, earningsGraphPeriod, transactionOption)} />}
                <Text type="h5" weight="Medium" >Reward History</Text>
                {Array.isArray(checkData?.latestEarnings) && <Text type="p" color={`${africredColors.neutrals.night["400"]}`}>March 2024</Text>}
                {/* {format(new Date(checkData?.latestEarning[0]?.date), "MMM yyyy")} */}
                {/* <TableWrapper>
                    <Table
                        columns={rewardHistoryColumn}
                        rows={rewardHistoryRow}
                    />
                </TableWrapper> */}
                {dashboardDataIsLoading?
                    <RewardHistoryLoading /> :
                    Array.isArray(checkData?.latestEarnings) && checkData?.latestEarnings.map((item) => (
                    <RewardHistory>
                        <div className='flex gap-4'>
                            <div className={`p-3 bg-[${africredColors.neutrals.white["100"]}] flex items-center justify-center rounded-md`}>
                               { 
                                item.earningsType.toLowerCase() == "widthdrawal"? 
                                <Icon type="arrow-up-45deg" width={15} height={15} /> : 
                                <Icon type="arrow-down-45deg" width={15} height={15} />}
                            </div>
                            <div>
                                <Text type="p" weight="bold" >{item.earningsType}</Text>
                                <Text type="p" color={`${africredColors.neutrals.night["300"]}`}>{format(new Date(item.date), "iii, dd MMM yyyy")}</Text>
                            </div>
                        </div>

                        <Text type="p" color={`${africredColors.neutrals.night['500']}`}>{`${item.earningsType.toLowerCase() == "widthdrawal"? "-": "+"} $${item.amount}`}</Text>

                    </RewardHistory>
                ))}
            </Earnings>
        </Wrapper>
    )
}

export default AgentDashboard;


const getTransactionStatFromResponse = (rawTransStats, admissions)=>{
    let transactionStats = [];
    for(const [key,val] of Object.entries(rawTransStats)){
        if(key==='totalLoans'){
            transactionStats[1] = {icon: <Icon type="wallet-sm" />,
            amount: rawTransStats['totalLoans'],
            text: 'Total Loans',
            color: africredColors.secondary.purple[200]}
        }
        if(key==='totalRemittances'){
            transactionStats[0] = {
                icon: <Icon type="card-sm" />,
                amount: rawTransStats['totalRemittances'],
                text: 'Total Payments',
                color: africredColors.primary.blue[200]
            }
        }
    }
    transactionStats.push({
        icon: <Icon type="school" className="svg-school-icon" />,
        amount: admissions,
        text: 'Total Admissions',
        color: africredColors.primary.gold[200]
    })
    return transactionStats;
}



function getTransactionDataPoints(dashboardData, selectedFilter, transactionType){
    let dataPoints;
    let labels;
    if(transactionType == "All"){
        dataPoints = selectedFilter == overviewOptions[0]? 
                        dashboardData?.graphPoints?.totalAmounts :
                        dashboardData?.weeklyTransactionGraphPoints?.totalAmounts;
        labels  = selectedFilter == overviewOptions[0]? 
                    dashboardData?.graphPoints?.monthNames:
                    dashboardData?.weeklyTransactionGraphPoints?.dayOfTheWeek;
    }else{
        dataPoints = dashboardData?.graphPoints?.totalAmounts;
        labels = dashboardData?.graphPoints?.monthNames
    }
    return{
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: dataPoints,
                backgroundColor: africredColors.primary.blue[200],
                hoverBackgroundColor: "#413fe5",
                barPercentage:0.5,
            },
        ],
    
    };
}

function getEarningsDataPoints(dashboardData, selectedFilter, transactionType){
    let dataPoints;
    let labels;
    
         dataPoints = selectedFilter == overviewOptions[0]? 
                        dashboardData?.monthlyEarningsGraphPoints?.
                        earningsTotalAmounts :
                        dashboardData?.weeklyEarningsGraphPoints?.totalAmounts;
         labels  = selectedFilter == overviewOptions[0]? 
                    dashboardData?.monthlyEarningsGraphPoints?.earningsMonthNames:
                    dashboardData?.weeklyEarningsGraphPoints?.dayOfTheWeek;
    
    return{
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: dataPoints,
                backgroundColor: africredColors.primary.blue[200],
                hoverBackgroundColor: "#413fe5",
                barPercentage:0.5,
            },
        ],
    
    };
}

function getTransactionChartOption(dashboardData, selectedFilter){
    let counts = selectedFilter == overviewOptions[0]? 
        dashboardData?.graphPoints?.totalCounts : dashboardData?.weeklyTransactionGraphPoints?.
                    weekCount;
    let transactionOption = {...chartOptions}
    
    transactionOption.plugins.tooltip.callbacks.label = function(context){
        const localCount = counts;
        let dataIndex = context.dataIndex;
        // if(Array.isArray(localCount) && localCount?.length > 0){
        //     console.log("DATA INDEX", dataIndex)
        //     return `${localCount[dataIndex]} Applications`
        // }
        // console.log("DATA INDEX222:::::::::::::", dataIndex)
        // return ' '
        return `${localCount[dataIndex]} Applications`
    }
    return transactionOption;
}

function getEarningsChartOption(dashboardData, selectedFilter){
    let counts = selectedFilter == overviewOptions[0]? 
                    [] :
                    dashboardData?.weeklyEarningsGraphPoints?.weekCount;
    let earningsOption = {...chartOptions}
    earningsOption.plugins.tooltip.callbacks.label = function(context){
        let dataIndex = context.dataIndex;
        if(Array.isArray(counts) && counts?.length > 0){
            return `${counts[dataIndex]} Applications`
        }
        return ' '
    }
    return earningsOption;
}


function RewardHistoryLoading (){
    return ( 
        <div role="status" class="max-w-md p-4 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6">
            <div class="flex items-center justify-between">
                <div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
                <div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
                <div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
                <div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
                <div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <span class="sr-only">Loading...</span>
        </div>
    )
}

function GraphLoading (){
    return(
        
        <div role="status" class="max-w-sm p-4 rounded shadow animate-pulse md:p-6">
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-32 mb-2.5"></div>
            <div class="w-48 h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-400"></div>
            <div class="flex items-baseline mt-4">
                <div class="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-400"></div>
                <div class="w-full h-56 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-400"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-72 ms-6 dark:bg-gray-400"></div>
                <div class="w-full h-64 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-400"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-80 ms-6 dark:bg-gray-400"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-72 ms-6 dark:bg-gray-400"></div>
                <div class="w-full bg-gray-200 rounded-t-lg h-80 ms-6 dark:bg-gray-400"></div>
            </div>
            <span class="sr-only">Loading...</span>
        </div>

    )
}

function TableLoading(){

    return(
        <div role="status" class="max-w-sm animate-pulse">
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
            <span class="sr-only">Loading...</span>
        </div>
    )
}