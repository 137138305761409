import styled, { css } from 'styled-components'
import { AuthTemplate, CloseablePageTemplage } from '../../templates'
import { brandTheme, screen } from '../../../theme'
import { Button, Icon, Input, SelectInput, Text } from '../../atoms'
import profileImage from '../../../assets/pngs/dp.png'
import { useState } from 'react'
import { africredColors } from '../../../theme/colors'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { toast } from 'react-toastify'
import useAuth from '../../../hooks/useAuth'
import { Link } from 'react-router-dom'

const sectionStyles = css`
    background-color: ${brandTheme.brand.backgroundSkin4};
    border-radius: 16px;
    padding: 48px 24px;

    @media only screen and (${screen.md}) {
        padding: 48px;
    }
`
const FormSection = styled.section`
    ${sectionStyles};
    background: linear-gradient(180deg, #fff 0%, rgba(238, 238, 238, 0) 100%);
`
const HelpVideos = styled.section`
    ${sectionStyles};
`
const VideosList = styled.div`
    margin-top: 32px;
    > div {
        margin-bottom: 1rem;
        padding: 1rem 0;
        border-bottom: 1px solid ${africredColors.neutrals.night[300]};
    }
`
const TitleWrapper = styled.div`
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
`
const ProfileArea = styled.div`
    display: flex;
    gap: 32px;
    width: 100%;
    margin-bottom: 16px;
`
const ProfileAvatarWrapper = styled.img`
    width: 64px;
    height: 64px;
    border-radius: 50%;
`
const Wrapper = styled.div``

export const Support = ({ children }) => {
    const [supportCategory, setSupportCategory] = useState('')
    const [message, setMessage] = useState('')
    const [messageSent, setMessageSent] = useState(false)
    const [error, setError] = useState('')
    const axiosPrivate = useAxiosPrivate()
    const auth = useAuth()

    const supportVideos = [
        {
            title: 'AfriCred Financing',
            url: 'https://www.loom.com/share/b0a6deb446ae47898e4937ab6a94b062?sid=f0005773-2546-46f1-879f-e14af692081f',
        },
        {
            title: 'Kick start your Journey',
            url: 'https://www.loom.com/share/52f22c3debc24b239c15aa23aa25e153?sid=2e00ad12-84cf-4efc-9292-f008217c3617',
        },
        {
            title: 'Learn about Africred',
            url: 'https://www.loom.com/share/52373a12cf604ad387e7d0ec6c5dc723?sid=35d7f6e9-99f8-4f10-b57f-cab7b1248e13',
        },
    ]

    const handleSubmit = async () => {
        if (!message && !supportCategory) {
            setError('You need to tells us more about why you need support')
            return null
        } else {
            setError('')
        }

        try {
            const response = await axiosPrivate.post('/contact/create', {
                supportCategory,
                message,
            })

            // Set the auth data here e.g., setAuth({ user, pwd, roles, accessToken });
            if (response.data) {
                setMessageSent(true)
            }
        } catch (err) {
            toast.error(
                err.response?.data.message ||
                    'There was an error sending your message',
            )
        }
    }

    return (
        <CloseablePageTemplage>
            {!messageSent ? (
                <Wrapper className="w-full flex flex-col lg:flex-row justify-center pt-8 gap-4">
                    <FormSection className="flex flex-col items-center w-full lg:w-2/4 xl:w-2/5 gap-4 rounded">
                        <TitleWrapper>
                            <Text textalign="center" weight="medium" type="h2">
                                Quick Support
                            </Text>

                            {error ? (
                                <Text textalign="center" type="p" color="red">
                                    {error}
                                </Text>
                            ) : (
                                <Text textalign="center" type="p">
                                    We can’t wait to hear from you!
                                </Text>
                            )}
                        </TitleWrapper>

                        <ProfileArea>
                            <ProfileAvatarWrapper
                                src={
                                    auth.profilePicture?.Location ||
                                    profileImage
                                }
                                alt="profile-image"
                            />
                            <div>
                                <Text type="h4">{`${auth.firstName} ${auth.lastName}`}</Text>
                                <Text type="p" margin="0.2rem 0 0 0">
                                    {`${auth.email}`}
                                </Text>
                            </div>
                        </ProfileArea>
                        <div className="w-full">
                            <Text textalign="left" type="p" weight="medium">
                                Support Category
                            </Text>
                        </div>

                        <div className="w-full">
                            <SelectInput
                                options={
                                    auth?.enumValues?.supportCategoryEnum || []
                                }
                                value={supportCategory || 'Select'}
                                setValue={setSupportCategory}
                            />
                        </div>

                        <div className="w-full">
                            <Text textalign="left" type="p" weight="medium">
                                Description
                            </Text>
                        </div>
                        <Input
                            type="textArea"
                            iconposition="right"
                            size="md"
                            placeholder="Type anything"
                            autoComplete="off"
                            paddingleft="16px !important"
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                        />
                        <Button
                            onClick={handleSubmit}
                            width="100%"
                            size="md"
                            bgcolor={brandTheme.brand.primary}
                        >
                            <Text type="p" weight="medium">
                                Submit
                            </Text>
                        </Button>
                    </FormSection>
                    <HelpVideos className="flex flex-col w-full lg:w-2/4 xl:w-2/5 gap-4 rounded">
                        <div>
                            <Text weight="medium" type="h3">
                                DIY Videos
                            </Text>

                            <Text className="mt-3" type="p">
                                Watch our YouTube videos for easy guides on how
                                to process loans and short term loans
                                seamlessly.
                            </Text>
                        </div>

                        <VideosList className="">
                            {supportVideos.map(item => (
                                <div className="flex gap-4" key={item.title}>
                                    <a
                                        target="_blank"
                                        href={item.url}
                                        rel="noreferrer"
                                    >
                                        <Icon type="play-video" />
                                    </a>
                                    <Text type="h5">{item.title}</Text>
                                </div>
                            ))}
                        </VideosList>
                        <a
                            href="https://www.youtube.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                width="fit-content"
                                size="md"
                                border={`1px solid ${africredColors.neutrals.night[300]}`}
                                padding="1rem 1.5rem"
                            >
                                <Text type="p" weight="medium">
                                    View more
                                </Text>
                                <Icon type="arrow-45deg" />
                            </Button>
                        </a>
                    </HelpVideos>
                </Wrapper>
            ) : (
                <AuthTemplate
                    title="Submitted successfully"
                    subtitle="A hassle-free education loan processing and global education fees remittance with the best rates available from the global markets."
                >
                    <Link to="/dashboard">
                        <Button
                            width="100%"
                            size="md"
                            bgcolor={brandTheme.brand.primary}
                        >
                            Proceed to dashboard
                        </Button>
                    </Link>
                </AuthTemplate>
            )}
        </CloseablePageTemplage>
    )
}
