import React from "react";
import styled from "styled-components";
import { africredColors } from "../../../theme/colors";

const Line = styled.div`
  border-bottom: 1px solid
    ${({ color }) => color || africredColors.neutrals.night[200]};
  width: 100%;
`;

export const HorizontalLine = ({ color }) => {
  return <Line color={color} />;
};
