import React from "react";
import useAuth from "../../../hooks/useAuth";
import { Dashboard } from "../Dashboard";
import { AgentDashboard } from "../AgentDashboard";
import { AgentDashboard as AgentDashboardNew } from "../AgentDashboardNew"

export const DashboardPage = () => {
  const auth = useAuth();

  return (
    <div>
      {auth.accountType !== "Agent" ? <Dashboard /> : <AgentDashboardNew />}
    </div>
  );
};
