import styled, { css } from "styled-components";
import { africredColors } from "../../../theme/colors";
import { Icon } from "../../atoms/Icon";
import { Text } from "../../atoms/Text";
import { Link } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { motion } from "framer-motion";
import { formatNumber, limitString, pageAnimationConfig } from "../../../utils";
import { brandTheme, screen } from "../../../theme";
import { useState } from "react";
import { SelectInput } from "../../atoms";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useQuery } from "@tanstack/react-query";
import { format, formatDistance } from "date-fns";
import { NoRecordFound } from "../../molecules/NoRecordFound";
import { Loader } from "../../atoms/Loader";
import { toast } from "react-toastify";
import { Skeleton } from "antd";
import LoaderContainer from "../../atoms/Loader/LoaderContainer";

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 30px 0;
  padding-bottom: 30px;

  @media only screen and (${screen.xl}) {
    display: grid;
    column-gap: 24px;
    row-gap: 16px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto repeat(2, 1fr);
    padding-bottom: 0;
  }
`;

const highlightStyles = css`
  display: flex;
  height: 112px;
  padding: 36px 52px;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: ${brandTheme.brand.secondary};
  overflow-x: auto;
  overflow-y: hidden;
  grid-row: 1/2;
  grid-column: 1/6;

  > div {
    padding: 0px 24px;
    display: flex;
    gap: 28px;
    align-items: center;
  }

  p {
    text-wrap: nowrap;
  }
`;

const Highlight = styled.section`
  ${highlightStyles};
  display: none;

  @media only screen and (${screen.lg}) {
    height: 178px;
    border-radius: 32px;
    padding: 36px 35px;
    display: flex;
  }
`;

const MobileHighlightWrapper = styled.div`
  ${highlightStyles};

  > div {
    padding: 0px 16px;
    display: flex;
    gap: 16px;
    align-items: center;
  }

  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 112px;
  border-radius: 16px;
  padding: 36px 1rem;

  @media only screen and (${screen.lg}) {
    display: none;
  }
`;

const QuickActionArea = styled.section`
  height: auto;
  padding: 20px 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 32px;
  background: ${africredColors.neutrals.white[10]};
  overflow-y: auto;
  grid-row: 2/4;
  grid-column: 1/3;

  @media only screen and (${screen.md}) {
    padding: 20px;
  }
`;

const ActivityChart = styled.section`
  display: flex;
  min-height: 280px;
  padding: 23px 12px;
  flex-direction: column;
  align-items: space-between;
  gap: 24px;
  flex-shrink: 0;
  border-radius: 32px;
  background: ${africredColors.neutrals.white[10]};
  grid-row: 2/3;
  grid-column: 3/6;

  @media only screen and (${screen.md}) {
    padding: 20px;
  }
`;

const Notifications = styled.section`
  grid-row: 3/4;
  grid-column: 3/6;
  height: auto;
  padding: 23px 12px;
  flex-shrink: 0;
  border-radius: 32px;
  background: ${africredColors.neutrals.white[10]};

  > div:not(:last-child) {
    border-bottom: 1px solid ${africredColors.neutrals.white[10]};
  }

  @media only screen and (${screen.md}) {
    padding: 20px;
  }
`;

const labelCSS = css`
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  text-wrap: nowrap;
`;

const PlainLabel = styled.button`
  ${labelCSS};
  display: flex;
  justify-content: center;
  background-color: ${africredColors.neutrals.night[50]};
  border: 1px solid ${africredColors.neutrals.night[200]};
  font-size: 0.8rem;
  padding: 8px 16px;
`;

const LabelledCategories = styled(Link)`
  ${labelCSS};
  width: fit-content;

  @media only screen and (${screen.md}) {
    width: auto;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  gap: 12px;

  @media only screen and (${screen.md}) {
    flex-wrap: nowrap;
    gap: 16px;
  }
`;

const smallTableCSS = css`
  table {
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    text-align: left;
    padding: 8px;
  }
  tr:hover {
    background-color: ${africredColors.neutrals.night[50]};
    cursor: pointer;
  }
`;
const OngoingOperations = styled.div`
  border: 1px solid ${africredColors.neutrals.night[300]};
  padding: 12px;
  border-radius: 16px;
  margin-top: 16px;
  margin-bottom: 24px;
  overflow-x: auto;
  text-align: center;

  ${smallTableCSS};
`;
const LatestTransactions = styled.div`
  overflow-x: auto;
  ${smallTableCSS};
`;

const NotificationLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${africredColors.neutrals.night[50]};
`;

const ChartWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const HightlightNavButton = styled.button`
  cursor: pointer;
  border-radius: 1rem;

  &:last-child {
    border: 1px solid #5d5bff;
    padding: 12px;
    background: #5d5bff;
  }
`;

const MobileHighlight = ({ highlightData }) => {
  const [currentDataIndex, setCurrentDataIndex] = useState(0);

  return (
    <MobileHighlightWrapper>
      <div>
        <div>{highlightData[currentDataIndex].icon}</div>
        <div>
          <Text type="h3" color={"white"}>{highlightData[currentDataIndex].amount}</Text>
          <Text type="p" color={"white"}>{highlightData[currentDataIndex].text}</Text>
        </div>
      </div>

      <div className="flex gap-2">
        <HightlightNavButton
          onClick={() => {
            setCurrentDataIndex((prev) => {
              if (highlightData[prev - 1]) {
                return prev - 1;
              }
              return prev;
            });
          }}
        >
          <Icon type="back" stroke="white" />
        </HightlightNavButton>
        <HightlightNavButton
          onClick={() => {
            setCurrentDataIndex((prev) => {
              if (highlightData[prev + 1]) {
                return prev + 1;
              }
              return prev;
            });
          }}
        >
          <Icon type="next" stroke="white" />
        </HightlightNavButton>
      </div>
    </MobileHighlightWrapper>
  );
};

export const Dashboard = () => {
  const [summaryFilterOption, setSummaryFilterOption] = useState("Payments");
  const [statsFilter, setStatsFilter] = useState("Payments");
  const axiosPrivate = useAxiosPrivate();
  const {
    isPending,
    error,
    data: dashboardData,
    refetch,
  } = useQuery({
    queryKey: ["dashboard-data"],
    queryFn: async () => {
      const response = await axiosPrivate("/transaction/student-dashboard");
      return response.data;
    },
  });

  const { isPending: statLoading, data: stats } = useQuery({
    queryKey: ["fetch-stats", statsFilter],
    queryFn: async () => {
      const response = await axiosPrivate(
        `/transaction/stats?transactionType=${
          statsFilter === "Short term loans" ? "Pof" : 
          statsFilter === "Payments" ? "Remittance" : statsFilter
        }&range=Last 6 months`
      );
      return response.data;
    },
  });

  const chartStep = dashboardData?.graphPoints?.totalAmounts?.reduce(
    (partialSum, a) => partialSum + a,
    0
  );

  if (isPending) return <LoaderContainer />;

  if (error) return "An error has occurred: " + error.message;

  const drafts =
    dashboardData?.drafts?.[
      summaryFilterOption === "Payments"
        ? "remittancesDrafts"
        : summaryFilterOption === "Loan"
        ? "loanDrafts"
        : "pofDrafts"
    ];

  const highlightData = [
    {
      icon: <Icon type="transaction" stroke="white"/>,
      amount: dashboardData.transactionStats.totalTransactions,
      text: "Total transactions",
    },
    {
      icon: <Icon type="card" stroke="white"/>,
      amount: dashboardData.transactionStats.totalRemittances,
      text: "Total Payments",
    },
    {
      icon: <Icon type="wallet" stroke="white"/>,
      amount: dashboardData.transactionStats.totalPofs,
      text: "Total Loans",
    },
    {
      icon: <Icon type="proof-of-funds" stroke="white"/>,
      amount: dashboardData.admissionStats,
      text: "Total Admissions",
    },
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: Math.round(chartStep / 3),
        },
      },
    },
    tension: 0.4,
  };

  const data = {
    labels: stats?.graphPoints?.monthNames,
    datasets: [
      {
        label: "Dataset 1",
        data: stats?.graphPoints?.totalAmounts,
        borderColor: brandTheme.brand.secondary,
        backgroundcolor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const deleteDraft = async (transactionType, id) => {
    const confirmDelete = window.confirm(
      "This action is not reversible. Are you sure you want to proceed?"
    );
    if (confirmDelete) {
      try {
        const response = await axiosPrivate.delete(
          `/${transactionType}/remove-draft/${id}`
        );

        if (response.data) {
          toast.success("Draft deleted");
          refetch();
        }
      } catch (err) {
        toast.error(err.response?.data.message);
      }
    }
  };

  return (
    <Wrapper {...pageAnimationConfig}>
      <Highlight>
        {highlightData.map((item) => (
          <div>
            <div>{item.icon}</div>
            <div>
              <Text type="h3" style={{color:"#fff"}}>{item.amount}</Text>
              <Text type="p" style={{color:"#fff"}}>{item.text}</Text>
            </div>
          </div>
        ))}
      </Highlight>

      <MobileHighlight highlightData={highlightData} />

      <QuickActionArea>
        <div className="flex w-full items-center justify-between">
          <div>
            <Text type="h4">Quick Action</Text>
          </div>
        </div>

        <LabelContainer className="pt-6 pb-8">
          <LabelledCategories
            to="/remittance"
            backgroundcolor={africredColors.primary.gold[100]}
          >
            <Icon type="card-sm" />
            <Text type="p">Payments</Text>
          </LabelledCategories>
          <LabelledCategories
            to="/loans"
            backgroundcolor={africredColors.primary.blue[50]}
          >
            <Icon type="wallet-sm" />
            <Text type="p">Loans</Text>
          </LabelledCategories>
          <LabelledCategories
            to="/universities"
            backgroundcolor={africredColors.primary.green[50]}
          >
            <Icon type="school" className="svg-school-icon" />
            <Text type="p">Admissions</Text>
          </LabelledCategories>
        </LabelContainer>

        <div className="flex w-full items-center justify-between">
          <Text type="h4">Drafts</Text>
          <SelectInput
            width="fit-content"
            options={["Payments", "Loan"]}
            value={summaryFilterOption}
            setValue={(item) => setSummaryFilterOption(item)}
            size="sm"
            bgcolor="transparent"
            border="0"
          />
        </div>

        <OngoingOperations>
          <table>
            <tbody>
              {drafts.length > 0
                ? drafts?.map((item, idx) => (
                    <tr key={idx}>
                      <td>
                        <Text textWrap="nowrap" type="p">
                          {limitString(item?.purpose)}
                        </Text>
                      </td>
                      <td>
                        <Text type="p">{item.currency}</Text>
                      </td>
                      <td>
                        <Text type="p">{formatNumber(item?.amount)}</Text>
                      </td>
                      <td>
                        <Text type="p" textWrap="nowrap">
                          {format(new Date(item.createdAt), "dd MMM, yyyy")}
                        </Text>
                      </td>
                      <td>
                        <PlainLabel
                          onClick={() =>
                            deleteDraft(item.transactionType, item.id)
                          }
                          backgroundcolor={africredColors.neutrals.night[50]}
                        >
                          Delete
                        </PlainLabel>
                      </td>
                    </tr>
                  ))
                : "No data.."}
            </tbody>
          </table>
        </OngoingOperations>

        <Text type="h4">Latest transactions</Text>
        <LatestTransactions className="pt-6">
          <table>
            <thead>
              <tr>
                <th>
                  <Text type="p">Type</Text>
                </th>
                <th>
                  <Text type="p">Currency</Text>
                </th>
                <th>
                  <Text type="p">Amount</Text>
                </th>
                <th>
                  <Text type="p">Date</Text>
                </th>
                <th>
                  <Text type="p">Status</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {dashboardData.latestTransactions
                ?.slice(0, 3)
                ?.map((item, idx) => (
                  <tr key={idx}>
                    <td>
                      <Text type="p">{limitString(item.transactionType)}</Text>
                    </td>
                    <td>
                      <Text type="p">{item.currency}</Text>
                    </td>
                    <td>
                      <Text type="p">
                        $
                        {formatNumber(item.loanOrPofAmount) ||
                          formatNumber(item.amount)}
                      </Text>
                    </td>
                    <td>
                      <Text type="p" whitespace="nowrap">
                        {format(new Date(item.createdAt), "dd MMM, yyyy")}
                      </Text>
                    </td>
                    <td>
                      <Text type="p" color="green">
                        {item.paymentStatus}
                      </Text>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </LatestTransactions>
      </QuickActionArea>

      <ActivityChart>
        <div className="flex w-full items-center justify-between">
          <div>
            <Text type="h4">Activity</Text>
          </div>
          <div className="flex items-center gap-4">
            <Text type="p">Last 6 months</Text>
            <SelectInput
              width="fit-content"
              options={["Payments", "Loan", "Admission"]}
              value={statsFilter}
              setValue={(item) => setStatsFilter(item)}
              size="sm"
              bgcolor="transparent"
              border="0"
            />
          </div>
        </div>
        <ChartWrapper>
          {statLoading ? (
            <Skeleton active />
          ) : (
            <Line options={options} data={data} />
          )}
        </ChartWrapper>
      </ActivityChart>

      <Notifications>
        <Text type="h4">Notifications</Text>

        <>
          {dashboardData.lastestNotifications.length > 0 ? (
            <>
              {dashboardData.lastestNotifications?.slice(0, 2).map((item) => (
                <div key={item} className="flex justify-between pt-6 pb-3">
                  <div className="flex items-center gap-6">
                    <NotificationLogoWrapper>
                      <Icon type="logo" />
                    </NotificationLogoWrapper>
                    <div className="flex flex-col gap-2 w-4/5">
                      <Text type="p">{item.message}</Text>
                      <div className="flex gap-4">
                        <Text type="small">
                          {formatDistance(
                            new Date(item.createdAt),
                            new Date(),
                            {
                              addSuffix: true,
                            }
                          )}
                        </Text>
                        <Text type="small">
                          {format(new Date(item.createdAt), "dd MMM, yyyy")}
                        </Text>
                      </div>
                    </div>
                  </div>
                  <Link to={`/notifications?id=${item.id}`}>
                    <Icon type="arrow-45deg" />
                  </Link>
                </div>
              ))}
            </>
          ) : (
            <NoRecordFound
              message="No notification yet..."
              className="h-[100px]"
            />
          )}
        </>
      </Notifications>
    </Wrapper>
  );
};
